function ItemCardApplicantFile({
  icon,
  name,
  data
}) {
  return (
    <>
      {icon}
      <span className='text-sm font-bold text-gray-900 dark:text-white truncate'>{name}</span><span className='text-[12px] text-gray-500 px-2 py-2 font-medium whitespace-nowrap'>{data}</span>
    </>
  )
}

export default ItemCardApplicantFile
