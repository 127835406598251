import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  sourceList: [],
  activesourceList: [],
  success: false,
  error: null,
}

const sourceSlice = createSlice ({
  name: 'source',
  initialState,
  reducers: {
    getAllsourceSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.sourceList = action.payload['hydra:member'];  
      state.success = true;
    },
    getActivesourceSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.activesourceList = action.payload['hydra:member'];
    },
    getactivesourceFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getAllsourceFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    addsourceSuccess(state, action)
    {
      state.success = true;
      state.error = false;
      state.sourceList = [...state.sourceList, action.payload]
    },
    addsourceailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    editsourceSuccess(state, action)
    {
      state.success = true;
      state.sourceList = state.sourceList.map(i => i.id === action.payload.id ? action.payload : i)
    },
    editsourceFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    }
  }
})

export const {
  getAllsourceSuccess,
  getAllsourceFailure,
  addsourceSuccess,
  addsourceailure,
  editsourceSuccess,
  editsourceFailure,
  getActivesourceSuccess,
  getactivesourceFailure
  } = sourceSlice.actions;

export default  sourceSlice.reducer