import React, { useState } from 'react';
import SignInLeftSection from '../components/sign-in/SignInLeftSection';
import { useDispatch } from 'react-redux';
import { ResetSagaActions } from '../redux/auth/authSagaActions';
import { BiInfoCircle } from 'react-icons/bi';
import Alert from '../components/Alerts';
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
  const [emailError, setEmailError] = useState('');
  const [SuccessMessage, setSuccessMessage] = useState('');
  const [ErrorMessage, setErrorMessage] = useState('');  
  const [credentials, setCredentials] = useState({
    email: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  function reinitialisePassword(e) {
    e.preventDefault();
    if ((credentials.email.length === 0))
    {
      setEmailError('Champ mail est obligatoire!')
    }   
    else if(!credentials.email.endsWith('@cosavostra.com'))
    {
      setSuccessMessage('');
      setErrorMessage("Merci d'utiliser une adresse @cosavostra.com !");
    }
    else
    {
      dispatch(ResetSagaActions.ResetPassword(credentials.email));
      setErrorMessage('');
      setSuccessMessage('Email de réinitialisation envoyé avec success !');
      setTimeout(() => {
        navigate('/');
      }, 1000);
    }
  }
  
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 h-screen w-full'>
      <SignInLeftSection className='bg-teal-500 flex justify-center items-center' />
        <div className='bg-white flex flex-col justify-center'>
          <h2 className='text-4xl text-dark font-medium text-center xs:text-2xl'>Réinitialiser votre mot de passe</h2>
          <br></br>
          <form className='max-w-[400px] w-full mx-auto p-8 px-8 rounded-lg' onSubmit={reinitialisePassword}> 
            { SuccessMessage ?
              <Alert Message={ SuccessMessage } color={'green'} />
            :null
            }
            { ErrorMessage ?
              <Alert Message={ ErrorMessage } color={'red'} />
            : null
            }
            <div className='flex flex-col text-gray-400 py-2'>
              <div>
                <input
                  type='text'
                  name='email'
                  className='block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-teal-500 focus:outline-none focus:ring-0 focus:border-teal-500 peer'
                  placeholder='Email'
                  value={credentials.email}
                  onChange={handleChange}
                  error={emailError}
                  />
                { emailError ?
                  <div className='flex items-center  p-4 mb-4 text-sm text-red-400  dark:bg-gray-800 dark:text-red-400' role='alert'>
                    <BiInfoCircle className='mr-2' />
                    <span className='font-medium'>{emailError}</span>
                    </div>
                : null }
              </div>
              <button className='w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>Réinitialiser votre mot de passe</button>
            </div>
          </form>
      </div>
    </div>
  )
}
export default ResetPassword
