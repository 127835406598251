import { RxPerson } from 'react-icons/rx';
import { BiStats } from 'react-icons/bi';
import { FiSettings, FiLogOut, FiArrowDown } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import SubMenu from './SubMenu';
import { useDispatch } from 'react-redux';
import { setIsAuth } from '../../redux/auth/authSlice';
import { MdOutlineNotifications } from 'react-icons/md';
import { BsListTask } from 'react-icons/bs';

export default function SideBar() {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubMenu = () => {
    setShowSubMenu(!showSubMenu)
  }

  function logout() {
    localStorage.removeItem('token'); 
    dispatch(setIsAuth())
    navigate('/');
  }

  return (
    <aside className='fixed top-0 w-60 left-0 h-screen pt-20 transition-transform -translate-x-full bg-primary border-r border-gray-200 lg:translate-x-0 dark:bg-gray-800 dark:border-gray-700'>
      <div className='h-full px-3 pb-4 overflow-y-auto bg-primary dark:bg-gray-800'>
        <ul className='space-y-8 font-medium p-5 xs:space-y-4 lg:space-y-4'>
          <li className='flex items-center'>
            <Link to='/notification' className='flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-teal-200 dark:hover:bg-gray-700'>
              <MdOutlineNotifications />
              <span className='flex ml-3 whitespace-nowrap'>Notifications</span>
            </Link>
          </li>
          <li>
            <Link to='/offres' className='flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-teal-200 dark:hover:bg-gray-700'>
              <BsListTask />  
              <span className='flex ml-3 whitespace-nowrap'>Offres</span>
            </Link>
          </li>
          <li>
            <Link to='/candidats' className='flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-teal-200 dark:hover:bg-gray-700'>
              <RxPerson />  
              <span className='flex ml-3 whitespace-nowrap'>Candidats</span>
            </Link>
          </li>
          <li>
            <Link to='/statistique'  className='flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-teal-200 dark:hover:bg-gray-700'>
              <BiStats />
              <span className='flex ml-3 whitespace-nowrap'>Statistiques</span>
            </Link>
          </li>
        </ul>
        <ul className='space-y-2 font-medium p-1'>
          <li>
              <div className='font-medium border-t border-gray-200 dark:border-gray-700 p-5'></div>
                <Link  className='flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-teal-200 dark:hover:bg-gray-700' onClick={handleSubMenu}>
                  <FiSettings />
                  <span className='flex-1 ml-3 whitespace-nowrap'>Paramètres</span>
                  <FiArrowDown />
                </Link>
                { showSubMenu &&
                  <SubMenu className='flex flex-col gap-4 text-white rounded-lg dark:text-white hover:bg-teal-200 dark:hover:bg-gray-700 md:p-2' />
                }
          </li>
          <li>
            <div  className='absolute bottom-2 flex items-center p-2 text-white transition duration-75 rounded-lg hover:bg-teal-200 dark:hover:bg-gray-700 dark:text-white hover:w-44 group'>
              <FiLogOut />
              <button className='ml-3' onClick={logout}>Déconnexion</button>
            </div>
          </li>
        </ul>
      </div>
    </aside>
  )
}
