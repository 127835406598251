import { useSelector } from "react-redux";
import Title from "../components/Title"
import NavBar from "../components/template/NavBar"
import SideBar from "../components/template/SideBar"
import ItemCardApplicantFile from "../components/ItemCardApplicantFile";
import { BiCurrentLocation } from "react-icons/bi";
import { RiCellphoneFill } from "react-icons/ri";
import { BsPerson, BsPersonWorkspace } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function Profile() {
  const {
    user_email,
    user_firstName,
    user_lastName,
    user_roles,
    user_address,
    user_image,
    user_cellphone,
    user_positionName
    } = useSelector(
      (state) => state.auth
    );

  return (
    <>
    <NavBar />
    <SideBar />
    <div className='flex flex-col gap-12 p-4 lg:ml-64 mt-14'>
      <Title 
        title={`Profile ${user_firstName+' '+user_lastName}`}
        className={'font-bold text-3xl'}
      />
      <div className='flex flex-col py-3 gap-2 items-center'>
          <div className='bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-500 dark:border-gray-700 lg:w-[50%] w-[90%]'>
            <div className='px-6 pb-6'>
              <div className='flex justify-center'>
                <img src={`${process.env.REACT_APP_API_URL}/media/${user_image}`} className='mt-2 rounded-full shadow text-center w-[10%]' alt='' />
              </div>
              <div className='flex flex-col mt-6 mb-5'>
                <div className='flex md:flex-row flex-col justify-between font-medium border-b border-gray-200 dark:border-gray-700 p-1 md:gap-4'>
                    <div className='flex md:flex-row flex-col items-center gap-4'>
                      <ItemCardApplicantFile
                        icon={<BsPerson className='w-4 h-4' />}
                        name={'Nom et prénom :'}
                        data={user_firstName+' '+user_lastName}
                      />
                    </div>
                    <div className='flex md:flex-row md:w-1/2 flex-col items-center gap-4'>
                      <ItemCardApplicantFile
                        icon={<MdEmail className='w-4 h-4' />}
                        name={'Email :'}
                        data={user_email}
                      />
                    </div>
                  </div>
                <div className='flex md:flex-row flex-col justify-between font-medium border-b border-gray-200 dark:border-gray-700 p-1 md:gap-4'>
                  <div className='flex md:flex-row flex-col items-center gap-4'>
                    <ItemCardApplicantFile
                      icon={<BiCurrentLocation className='w-4 h-4' />}
                      name={'Bureau :'}
                      data={user_address}
                    />
                  </div>
                  <div className='flex md:flex-row md:w-1/2 flex-col items-center gap-4'>
                    <ItemCardApplicantFile
                      icon={<RiCellphoneFill className='w-4 h-4' />}
                      name={'Téléphone :'}
                      data={user_cellphone}
                    />
                  </div>
                </div>
                <div className='flex md:flex-row flex-col items-center gap-4 font-medium border-b border-gray-200 dark:border-gray-700 p-1'>
                  <BsPersonWorkspace className='w-4 h-4' />
                  <span className='text-md font-bold text-gray-900 dark:text-white truncate'>Poste :</span><span className='text-md text-gray-500 px-2 py-2 font-medium md:whitespace-nowrap'> {user_positionName}</span>
                </div>
                <div className='flex flex-col md:flex-row md:justify-between p-1'>
                  <div className='flex flex-col '>
                    <h5 className='text-md font-bold text-gray-900 dark:text-white truncate'>Roles</h5>
                    <p className='my-2 flex md:flex-row flex-col gap-2'>
                    {user_roles.map(role => {
                      let roleName = null;
                      if (role === 'ROLE_ADMIN') {
                        roleName = 'Administrateur';
                      } else if (role === 'ROLE_MANAGER') {
                        roleName = 'Manager';
                      } else if (role === 'ROLE_USER' || role === 'ROLE_HEAD_OF') {
                        roleName = 'Utilisateur';
                      } else if (role === 'ROLE_HR') {
                        roleName = 'Chargé de recrutement';
                      }
                      return roleName ? (
                        <span key={`role-${role.id}`} className={`bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300 whitespace-nowrap`}>
                          <p>{roleName}</p>
                        </span>
                      ) : null;
                    })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div> 
    </div>
    </>  
  )
}

export default Profile