export const mediaobjectSagaActions = {
  getallmediaobject
};


function getallmediaobject(payload)
{
  return {
    type: 'Getallmediaobject',
    payload
  }
}