import { FaEye } from 'react-icons/fa';
import CreateDateRow from './CreateDateRow';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { TbDots } from 'react-icons/tb';
import ShowModalFeature from '../ShowModalFeature';

export default function CandidatRow({
  list,
  handleshowmodalEdit,
  deleteCandidat,
  handleshowsidebarCV,
  handleshowmodalFeatures,
  showmodalFeature,
  handleAcceptRefuseCandidat,
  handleshareCandidat
}) {
  return (
    <>
    <tr key={`office-${list.id}`} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
      <td className='p-2 whitespace-nowrap'>
        <div className='font-bold'>
          <Link to={`/fiche_candidat/${list.id}`}>
            {list.firstname + ' ' + list.lastname}
          </Link>
        </div>
      </td>
      <td className='p-2 font-medium'>
        <Link className='flex justify-center'>
          <FaEye onClick={() => handleshowsidebarCV(list)} />
        </Link>
      </td>
      <td className='p-2 font-medium max-w-[8rem] truncate'>
        {list.poste}
      </td>
      <td className='p-2 font-medium'>
        {list.office?.office}
      </td>
      <td className='p-2 font-medium whitespace-nowrap'>
        {list.profil?.profil}
      </td>
      <td className='p-2 font-medium'>
        {list.typecontract?.typeContract}
      </td>
      <td className='p-2 font-medium'>
        {list.source?.source}
      </td>
      <td className='p-2 font-medium text-center'>
        <span style={{backgroundColor: list.status?.codecouleur ? list.status.codecouleur : 'black' }}  className={'truncate text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'}>
          {list.status?.status}
        </span>
      </td>
      <CreateDateRow list={list.createdAt} />
      <td className='flex justify-center py-2 m-2 pl-2 gap-2'>
        <Link>
          <FiEdit2 onClick={() => handleshowmodalEdit(list)} />
        </Link>
        <Link>
          <FiTrash onClick={(e) => deleteCandidat(e , list.id)} />
        </Link>
        <Link>
            {showmodalFeature[list.id] && <ShowModalFeature handleAcceptRefuseCandidat={handleAcceptRefuseCandidat} list={list} handleshareCandidat={handleshareCandidat} />}
            <TbDots onClick={() => handleshowmodalFeatures(list)} />
        </Link>
      </td>
    </tr>
  </>
  )
}
