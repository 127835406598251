import { Link } from "react-router-dom";
import CreateDateRow from "./CreateDateRow";
import UpdatedAtRow from "./UpdatedAtRow";
import { FiEdit2, FiTrash } from "react-icons/fi";
import CommentRow from "./CommentRow";
import ApprovedRow from "./ApprovedRow";

 function feedbackRow({
   list,
   deleteFeedback,
   handleshowmodalEdit
  }){ 
  return (
    <>
    <tr key={`feedback-${list.id}`} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '>
      <td className='px-6 py-4 font-medium truncate'>
        {list.englishLevel}
      </td>
      <td className='px-6 py-4 font-medium truncate'>
        {list.availability}
      </td>
      <CommentRow list={list} />
      <ApprovedRow list={list} />
      <CreateDateRow list={list.CreatedAt} />
      <UpdatedAtRow list={list.CreatedAt} />
      <td className='flex justify-center px-6 py-6 m-2 pl-2 gap-2'>
        <Link>
          <FiEdit2 onClick={() => handleshowmodalEdit(list)} />
        </Link>
        <Link>
          <FiTrash onClick={(e) => deleteFeedback(e , list.id)} />
        </Link>
      </td>
    </tr>
  </>
  )
}

export default feedbackRow