

function ValidationsDataReference(data) {
  const errors = [];

  if (data.remunerationActualFix.length === 0) {
    errors.push('Champ rénumération actuelle désirée est obligatoire!');
  }
  if (data.currentVariable.length === 0) {
    errors.push('Champ variable actuelle du contact est obligatoire!');
  }
  if (data.remunerationDesiredFix.length === 0) {
    errors.push('Champ rénumération actuelle désirée du contact est obligatoire!');
  }
  if (data.desiredVariable.length === 0) {
    errors.push('Champ variable désirée du contact est obligatoire!');
  }
  if (data.comment.length === 0) {
    errors.push('Champ commentaire est obligatoire!');
  }

  return errors;
}

export default ValidationsDataReference
