import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItemsreferences: 0,
  referenceList: [],
  success: false,
  error: null,
  reference: null
}

const referenceSlice = createSlice ({
  name: 'reference',
  initialState,
  reducers: {
    getAllreferenceSuccess(state, action)
    {
      state.totalItemsreferences = action.payload['hydra:totalItems'];
      state.referenceList = action.payload['hydra:member'];  
      state.success = true;
    },
    getActivereferenceSuccess(state, action)
    {
      state.totalItemsreferences = action.payload['hydra:totalItems'];
      state.activereferenceList = action.payload['hydra:member'];
    },
    getactivereferenceFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getAllreferenceFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    addreferenceSuccess(state, action)
    {
      state.referenceList = [...state.referenceList, action.payload]
      state.success = 'reference added with success !';
      state.error = false;
    },
    addreferenceailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        state.error = 'unauthorized';
      }
      else
      {
        state.error = 'something else happened !';
      }
    },
    editreferenceSuccess(state, action)
    {
      state.success = true;
      state.error = false;
    },
    editreferenceFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    deletereferenceSuccess(state, action)
    {
      state.success = true;
      state.referenceList = state.referenceList.filter((reference) => reference.id !== action.payload);
    },
    deletereferenceFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
  }
})

export const {
  getAllreferenceSuccess,
  getAllreferenceFailure,
  addreferenceSuccess,
  addreferenceailure,
  editreferenceSuccess,
  editreferenceFailure,
  deletereferenceSuccess,
  deletereferenceFailure,
  } = referenceSlice.actions;

export default  referenceSlice.reducer