import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

export default function AddButton({Name , onClick}) {
  return (
    <>
    <Link onClick={onClick} className='flex gap-2 max-w-fit md:max-w-fit items-center text-white bg-gradient-to-br from-teal-600 to-teal-500 hover:bg-gradient-to-blue focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center'>
        <FiPlus />
    </Link>
    </>
  )
}
