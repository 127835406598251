import { RiArrowDownSFill } from 'react-icons/ri';
import Select from 'react-select';

function FilterComponent({
  handleshowmodalFilter,
  showmodalFilter,
  animatedComponents,
  options,
  handlesearchSelect,
  classRelative,
  nameFilter,
  width,
  handlesearchButton
}) {
  return (
    <div className={classRelative}>
      <button  
        className={`inline-flex items-center h-full ${width} text-gray-500 bg-white border border-gray-300  hover:bg-gray-100 focus:ring-teal-500 focus:border-teal-500  font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-teal-700`}
        onClick={handlesearchButton}
      >
        {nameFilter}
      </button>
      <div className='absolute top-2 right-2 cursor-pointer'>
          <RiArrowDownSFill  onMouseEnter={handleshowmodalFilter} />
      </div>
      {showmodalFilter &&
      <div className={`border border-gray-400 absolute top-10 rounded-md shadow-lg bg-white divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600 ${width}`}>
        <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
          <li className='flex items-center p-2'>
              <Select
                options={options}
                components={animatedComponents}
                className='basic-multi-select w-full'
                onChange={handlesearchSelect} 
              />
          </li>
        </ul>
      </div>
      }
    </div>
  )
}

export default FilterComponent