

function ValidationsDataFeedback(data) {
  const errors = [];

  if (data.englishLevel.length === 0) {
    errors.push('Champ niveau anglais est obligatoire!');
  }
  if (data.availability.length === 0) {
    errors.push('Champ disponibilité est obligatoire!');
  }
  if (data.comment.length === 0) {
    errors.push('Champ comment est obligatoire!');
  }

  return errors;
}

export default ValidationsDataFeedback

