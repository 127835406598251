import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CandidatSagaActions } from '../redux/candidat/CandidatSagaActions';
import NavBar from '../components/template/NavBar';
import SideBar from '../components/template/SideBar';
import Title from '../components/Title';
import { BiCurrentLocation } from 'react-icons/bi';
import { RiCellphoneFill } from 'react-icons/ri';
import { BsPerson, BsPersonWorkspace } from 'react-icons/bs';
import { AiFillProfile } from 'react-icons/ai';
import { HiStatusOnline } from 'react-icons/hi';
import { FaFileContract } from 'react-icons/fa';
import { MdEmail, MdOutlineSource } from 'react-icons/md';
import Table from '../components/Table';
import AddButton from '../components/bouttons/AddButton';
import PDF from '../components/pdf/PDF';
import FileApplicantButton from '../components/bouttons/FileApplicantButton';
import { FeedBackSagaActions } from '../redux/Feedback/FeedbackSagaActions';
import ItemCardApplicantFile from '../components/ItemCardApplicantFile';
import Modal from '../components/forms/Modal';
import Pagination from '../components/Pagination';
import { referenceSagaActions } from '../redux/Reference/ReferenceSagaActions';
import EmptyTable from '../components/EmptyTable';
import { remunerationSagaActions } from '../redux/Remuneration/RemunerationSagaActions';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { historySagaActions } from '../redux/History/HistorySagaActions';
import ItemCardApplicantStatusFile from '../components/ItemCardApplicantStatusFile';
import useClickOutside from '../components/useClickOutside';

function ApplicantFile () {
  const modalRef = useRef(null);
  const modalRef1 = useRef(null);
  const modalRef2 = useRef(null);
  const dispatch = useDispatch();  
  const { id } = useParams();
  const [feedback] = useState('feedback');
  const [reference] = useState('reference');
  const [remuneration] = useState('remuneration');
  const [history] = useState('history');
  const [showsidebarPdfViewer , setshowsidebarPdfViewer] = useState(false);
  const headersFeedbacks = ['Niveau anglais' , 'Disponibilité', 'Commentaire', 'Décision', 'Créé le','Modifié le', 'Actions'];
  const headersReferences = ['Nom du contact' , 'Email du contact', 'Téléphone du contact', 'Entreprise du contact', 'Commentaire', 'Décision', 'Créé le','Modifié le', 'Actions'];
  const headersRemunerations = ['Rémunération  actuelle fixe' , 'Variable actuelle', 'Rémunération  souhaitéé fixe', 'Variable désiréé', 'Commentaire', 'Créé le','Modifié le', 'Actions'];
  const headersHistory = ["Statut avant l'action", "Responsable de l'action" , "Statut aprés l'action", 'Créé le'];
  const [showmodal , setshowmodal] = useState(false);
  const [showmodalFeedback , setshowmodalFeedback] = useState(false);
  const [showmodalReference , setshowmodalReference] = useState(false);
  const [showmodalRemuneration , setshowmodalRemuneration] = useState(false);
  const [modif , setModif] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    totalItems,
    feedbackList
  } = useSelector(
      (state) => state.feedback
  );
  
  const {
    totalItemsreferences,
    referenceList
  } = useSelector(
      (state) => state.reference
  );

  const {
    totalItemsRemunerations,
    remunerationList
  } = useSelector(
      (state) => state.remuneration
  );

  const {
    historyList
  } = useSelector(
      (state) => state.history
  );


  useEffect(() => {
    dispatch(CandidatSagaActions.getapplicantbyid({id : id }));
    dispatch(FeedBackSagaActions.getallfeedbacks(1));
    dispatch(referenceSagaActions.getallreferences(1));
    dispatch(remunerationSagaActions.getallremunerations(1));
    dispatch(historySagaActions.getallhistory(1));
  },[dispatch, id]);

  const {
    candidat
  } = useSelector(
    (state) => state.candidat
  );

  const handleshowsidebarCV = () => {
    setshowsidebarPdfViewer(!showsidebarPdfViewer);
  }

  const handleclosesidebar = () => {
    setshowsidebarPdfViewer(!showsidebarPdfViewer);
  }

  const handleshowmodalFeedback = () => {
    setModif(false);
    setSelectedItem(null)
    setshowmodalFeedback(!showmodalFeedback);
    setModif(false);
    setSelectedItem(null)
    setshowmodal(!showmodal);
  }

  const handleshowmodalReference = () => {
    setModif(false);
    setSelectedItem(null)
    setshowmodalReference(!showmodalReference);
    setModif(false);
    setSelectedItem(null)
    setshowmodal(!showmodal);
  }

  const handleshowmodalRemuneration = () => {
    setModif(false);
    setSelectedItem(null)
    setshowmodalRemuneration(!showmodalRemuneration);
    setModif(false);
    setSelectedItem(null)
    setshowmodal(!showmodal);
  }

  function onPageChange(currentPage) {
    dispatch(FeedBackSagaActions.getallfeedbacks(currentPage.selected + 1));
  }

  function onPageChangeReference(currentPage) {
    dispatch(referenceSagaActions.getallreferences(currentPage.selected + 1));
  }

  function onPageChangeRemuneration(currentPage) {
    dispatch(remunerationSagaActions.getallremunerations(currentPage.selected + 1));
  }

  function deleteFeedback(e,item) {
    e.preventDefault();
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      dispatch(FeedBackSagaActions.deletefeedback(item));
    }
  }

  function deleteReference(e,item) {
    e.preventDefault();
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      dispatch(referenceSagaActions.deletereference(item));
    }
  }

  function deleteRemuneration(e,item) {
    e.preventDefault();
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      dispatch(remunerationSagaActions.deleteremuneration(item));
    }
  }

  const handleshowmodalEditFeedback = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodalFeedback(!showmodalFeedback);
    setshowmodal(!showmodal);
  }

  const handleshowmodalEditReference = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodalReference(!showmodalReference);
    setshowmodal(!showmodal);
  }

  const handleshowmodalEditRemuneration = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodalRemuneration(!showmodalRemuneration);
    setshowmodal(!showmodal);
  }

  const handleClickOutside = (ref) => {
    if (ref === modalRef)
    {
      setshowmodal(false);
      setshowmodalFeedback(false);
    }
    else if (ref === modalRef1) {
      setshowmodal(false);
      setshowmodalReference(false);
    } else if (ref === modalRef2) {
      setshowmodal(false);
      setshowmodalRemuneration(false);
    }
  };

  useClickOutside({ refs : [modalRef,modalRef1, modalRef2], onClickOutside: handleClickOutside});

  return (
    <>
      <NavBar />
      <SideBar />
      <div className='flex flex-col gap-2 p-2 lg:ml-64 mt-14'>
        <Title 
          title={'Fiche candidat'}
          className={'font-bold text-3xl'}
        />
        {showsidebarPdfViewer && <PDF cv={`${process.env.REACT_APP_API_URL}/media/${candidat?.mediaObject.imagePath}`} showSidebar={showsidebarPdfViewer} handleclosesidebar={handleclosesidebar} />}  
        <div className='flex flex-col py-5 gap-2 items-center'>
          <div className='bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-500 dark:border-gray-700 lg:w-[90%] w-[90%]'>
            <div className="flex flex-row justify-between">
              <div className='p-5'>
                <FileApplicantButton 
                  className={'text-white bg-black hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800 truncate'}
                  title={'cirruculum vitae'}
                  action={() => handleshowsidebarCV()}
                />
              </div>
              <span className='text-sm font-bold text-gray-900 dark:text-white truncate p-5'>
                Recruteur : <p className='my-2 flex flex-row gap-2'>
                    { candidat?.applicantUsers
                      .filter(applicantUsers => applicantUsers && applicantUsers?.source && ( applicantUsers.source.includes('Add_applicant') || applicantUsers.source.includes('Add_Applicant_HR')))
                      .map(applicantuser => applicantuser.user)
                      .filter(user => user && user?.roles && (user?.roles?.includes('ROLE_HR') || user?.roles?.includes('ROLE_ADMIN')))
                      .map ( user => (
                        <span key={`add-hr-${user.id}`} className={`bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 whitespace-nowrap`}>
                          {user?.fullName}
                        </span>
                     ))
                    }
                    </p>
                </span>
            </div>  
            <div className='px-6 pb-6'>
              <div className='flex flex-col mt-6 mb-5'>
                <div className='flex md:flex-row flex-col justify-between font-medium border-b border-gray-200 dark:border-gray-700 p-1 md:gap-4'>
                  <div className='flex md:flex-row flex-col items-center gap-4'>
                    <ItemCardApplicantFile
                      icon={<BsPerson className='w-4 h-4' />}
                      name={'Nom et prénom :'}
                      data={candidat?.fullName}
                    />
                  </div>
                  <div className='flex md:flex-row md:w-1/2 flex-col items-center gap-4'>
                    <ItemCardApplicantFile
                      icon={<MdEmail className='w-4 h-4' />}
                      name={'Email :'}
                      data={candidat?.email}
                    />
                  </div>
                </div>
                <div className='flex md:flex-row flex-col justify-between font-medium border-b border-gray-200 dark:border-gray-700 p-1 md:gap-4'>
                  <div className='flex md:flex-row flex-col items-center gap-4'>
                    <ItemCardApplicantFile
                      icon={<BiCurrentLocation className='w-4 h-4' />}
                      name={'Bureau :'}
                      data={candidat?.office?.office}
                    />
                  </div>
                  <div className='flex md:flex-row md:w-1/2 flex-col items-center gap-4'>
                    <ItemCardApplicantFile
                      icon={<RiCellphoneFill className='w-4 h-4' />}
                      name={'Téléphone :'}
                      data={candidat?.cellphone}
                    />
                  </div>
                </div>
                <div className='flex md:flex-row flex-col justify-between font-medium border-b border-gray-200 dark:border-gray-700 p-1'>
                  <div className='flex md:flex-row flex-col items-center gap-2'>
                    <ItemCardApplicantFile
                      icon={<AiFillProfile className='w-4 h-4' />}
                      name={'Profil :'}
                      data={candidat?.profil?.profil}
                    />
                  </div>
                  <div className='flex md:flex-row flex-col md:w-1/2 items-center gap-2'>
                    <ItemCardApplicantFile
                      icon={<MdOutlineSource className='w-4 h-4' />}
                      name={'Source :'}
                      data={candidat?.source?.source}
                    />
                  </div>
                </div>
                <div className='flex md:flex-row flex-col justify-between font-medium border-b border-gray-200 dark:border-gray-700 p-1'>
                  <div className='flex md:flex-row flex-col items-center gap-2'>
                    <ItemCardApplicantStatusFile
                      icon={<HiStatusOnline className='w-4 h-4' />}
                      name={'Statut :'}
                      data={candidat?.status}
                    />
                  </div>
                  <div className='flex md:flex-row flex-col md:w-1/2 items-center gap-2'>
                    <ItemCardApplicantFile
                      icon={<FaFileContract className='w-4 h-4' />}
                      name={'Type de contrat :'}
                      data={candidat?.typecontract?.typeContract}
                    />
                  </div>
                </div>
                <div className='flex md:flex-row flex-col items-center gap-4 font-medium border-b border-gray-200 dark:border-gray-700 p-1'>
                  <BsPersonWorkspace className='w-4 h-4' />
                  <span className='text-sm font-bold text-gray-900 dark:text-white truncate'>Poste :</span><span className='text-[12px]s text-gray-500 px-2 py-2 font-medium md:whitespace-nowrap'> {candidat?.poste}</span>
                </div>
                <div className='flex flex-col md:flex-row md:justify-around p-1 py-3'>
                  <div className='flex flex-col justify-center items-center'>
                    <h5 className='text-sm font-medium text-gray-900 dark:text-white truncate'>Partager avec</h5>
                    <p className='my-2 flex flex-row gap-2'>
                    { candidat?.applicantUsers
                      .filter((applicantsUsers) => applicantsUsers.source.includes('Associate_applicant'))
                      .map((applicantsUsers) => applicantsUsers.user)
                      .map ( user => (
                        <span key={`associated-${user.id}`} className={`bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300 whitespace-nowrap`}>
                          {user.fullName}
                        </span>
                    ))
                    }
                    </p>
                  </div>
                  <div className='font-medium border-r border-gray-200 dark:border-gray-700'></div>
                  <div className='flex flex-col justify-center items-center'>
                    <h5 className='text-sm font-medium text-gray-900 dark:text-white truncate'>Référent</h5>
                    <p className='my-2 flex flex-row gap-2'>
                    { candidat?.applicantUsers
                    .filter((applicantsUsers) => applicantsUsers.source.includes('Add_applicant'))
                    .map((applicantsUsers) => applicantsUsers.user)
                    .filter((applicantsUsers) => applicantsUsers.roles.includes('ROLE_REFFERANT'))
                    .map ( user => (
                      <span key={`associated-${user.id}`} className={`bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 whitespace-nowrap`}>
                        {user.fullName}
                      </span>
                    ))
                    }
                    </p>
                  </div>
                  <div className='font-medium border-r border-gray-200 dark:border-gray-700'></div>
                  <div className='flex flex-col justify-center items-center'>
                    <h5 className='text-sm font-medium text-gray-900 dark:text-white truncate'>Responsable hiéarchique</h5>
                    <p className='my-2 flex flex-row gap-2'>
                    { candidat?.applicantUsers
                    .filter((applicantsUsers) => applicantsUsers.source.includes('Add_applicant'))
                    .map((applicantsUsers) => applicantsUsers.user)
                    .filter((applicantsUsers) => applicantsUsers.roles.includes('ROLE_MANAGER'))
                    .map ( user => (
                      <span key={`associated-${user.id}`} className={`bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 whitespace-nowrap`}>
                        {user.fullName}
                      </span>
                    ))
                    }
                    </p>
                  </div>
                </div>
                <div className='py-3'>
                  <Tabs>
                    <TabList>
                      <Tab>FeedBack</Tab>
                      <Tab>Prise de référence</Tab>
                      <Tab>Rémunération</Tab>
                      <Tab>Historique</Tab>
                    </TabList>
                    <TabPanel>
                    <div className='flex flex-col gap-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-500 dark:border-gray-700'>
                      <h1 className='text-2xl font-bold my-3 text-center'>Les Feedbacks</h1>
                      <div className='ml-2'>
                        <AddButton
                          onClick={handleshowmodalFeedback}
                        /> 
                      </div>
                      <div className='overflow-x-auto shadow-md sm:rounded-lg pb-4 px-4'>
                        {feedbackList?.filter((feedback) => feedback?.applicant?.id === id ).length === 0 ?
                        <EmptyTable />
                        :
                        <Table 
                          List={feedbackList?.filter((feedback) => feedback.applicant.id === id )}
                          headers={headersFeedbacks}
                          classNameTable={'w-full text-sm text-center text-gray-500 dark:text-gray-400 p-1'}
                          classNamethead={'text-xs text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
                          classNameth={'px-6 py-3'}
                          name={feedback}
                          deleteFeedback={deleteFeedback}
                          handleshowmodalEdit={handleshowmodalEditFeedback}
                        />
                        } 
                        {candidat?.feedback.length !== 0 &&
                        <div className='flex justify-center md:justify-center p-1'>
                          <Pagination
                            onPage={onPageChange}
                            totalItems={totalItems}
                            number={10}
                          />
                        </div> 
                        }  
                      </div>
                    </div>  
                  </TabPanel>
                  <TabPanel>
                    <div className='flex flex-col gap-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-500 dark:border-gray-700'>
                      <h1 className='text-2xl font-bold my-3 text-center'>Les prises de références</h1>
                      <div className='ml-2'>
                        <AddButton
                          onClick={handleshowmodalReference}
                        /> 
                      </div>
                      <div className='overflow-x-auto shadow-md sm:rounded-lg pb-4 px-4'>
                        {referenceList?.filter((reference) => reference?.applicant?.id === id ).length === 0 ?
                        <EmptyTable />
                        :
                        <Table 
                          List={referenceList?.filter((reference) => reference.applicant.id === id )}
                          headers={headersReferences}
                          classNameTable={'w-full text-sm text-center text-gray-500 dark:text-gray-400 p-1'}
                          classNamethead={'text-xs text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
                          classNameth={'px-6 py-3'}
                          name={reference}
                          deleteReference={deleteReference}
                          handleshowmodalEdit={handleshowmodalEditReference}
                        />
                        
                        } 
                        {candidat?.referencesApplicant.length !== 0 &&
                        <div className='flex justify-center md:justify-center p-1'>
                            <Pagination
                              onPage={onPageChangeReference}
                              totalItems={totalItemsreferences}
                              number={10}
                            />
                        </div>
                      }   
                      </div>
                    </div>  
                  </TabPanel>
                  <TabPanel>
                   <div className='flex flex-col gap-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-500 dark:border-gray-700'>
                   <h1 className='text-2xl font-bold my-3 text-center'>Les rémunérations</h1>
                   <div className='ml-2'>
                      <AddButton
                        onClick={handleshowmodalRemuneration}
                      /> 
                    </div>
                    <div className='overflow-x-auto shadow-md sm:rounded-lg pb-4 px-4'>
                      {remunerationList?.filter((remuneration) => remuneration?.applicant?.id === id ).length === 0 ?
                      <EmptyTable />
                      :
                      <Table 
                        List={remunerationList?.filter((remuneration) => remuneration.applicant.id === id )}
                        headers={headersRemunerations}
                        classNameTable={'w-full text-sm text-center text-gray-500 dark:text-gray-400 p-1'}
                        classNamethead={'text-xs text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
                        classNameth={'px-6 py-3'}
                        name={remuneration}
                        deleteRemuneration={deleteRemuneration}
                        handleshowmodalEdit={handleshowmodalEditRemuneration}
                      />
                      } 
                      {candidat?.remunerations.length !== 0 &&
                        <div className='flex justify-center md:justify-center p-1'>
                          <Pagination
                            onPage={onPageChangeRemuneration}
                            totalItems={totalItemsRemunerations}
                            number={10}
                          />
                        </div>  
                      } 
                      </div>
                    </div>  
                  </TabPanel>
                  <TabPanel>
                    <div className='flex flex-col gap-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-500 dark:border-gray-700'>
                      <h1 className='text-2xl font-bold my-3 text-center'>Les historiques</h1>
                        <div className='overflow-x-auto shadow-md sm:rounded-lg pb-4 px-4'>
                          {historyList?.filter((history) => history.applicant.id === id ).length === 0 ?
                          <EmptyTable />
                          :
                          <Table 
                            List={historyList?.filter((history) => history.applicant.id === id )}
                            headers={headersHistory}
                            classNameTable={'w-full text-sm text-center text-gray-500 dark:text-gray-400 p-1'}
                            classNamethead={'text-xs text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
                            classNameth={'px-6 py-3'}
                            name={history}
                          />
                          } 
                      </div>
                    </div>  
                  </TabPanel>
                </Tabs>
                </div>
              </div>
            </div>
          </div>
          { showmodalFeedback  && <Modal 
            refer={modalRef}
            config={feedback}
            modif={modif}
            selectedItem={selectedItem}
            id_applicant={candidat['@id']}
            showmodalFeedback={showmodalFeedback}
            setshowmodalFeedback={setshowmodalFeedback}
            showmodal={showmodal}
            setshowmodal={setshowmodal}
            />
          }
          { showmodalReference  && <Modal 
           refer={modalRef1}
            config={reference}
            modif={modif}
            selectedItem={selectedItem}
            id_applicant={candidat['@id']}
            showmodalReference={showmodalReference}
            setshowmodalReference={setshowmodalReference}
            showmodal={showmodal}
            setshowmodal={setshowmodal}
            />
          }      
          { showmodalRemuneration  && <Modal 
            refer={modalRef2}
            config={remuneration}
            modif={modif}
            selectedItem={selectedItem}
            id_applicant={candidat['@id']}
            showmodalRemuneration={showmodalRemuneration}
            setshowmodalRemuneration={setshowmodalRemuneration}
            showmodal={showmodal}
            setshowmodal={setshowmodal}
            />
          }      
        </div>
      </div>  
    </>
  )
}
export default ApplicantFile
