import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  getAllofferSuccess,
  getAllofferFailure,
  addofferSuccess,
  addofferFailure,
  editofferSuccess,
  editofferFailure,
  deleteofferSuccess,
  deleteofferFailure,
  getofferbyidFailure,
  getofferbyidSuccess,
} from './OffersSlice';

const token = localStorage.getItem('token')

function* workergetalloffer(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offers?_page=${action.payload}&itemsPerPage=10`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllofferSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllofferFailure(error.response))
  }
}

function* workeraddoffer(action)
{
  try
    {
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offers`,
      method: 'POST',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetalloffer({payload : 1});
  yield put(addofferSuccess(action.payload));
}
  catch(error)
  {
    yield put(addofferFailure(error))
  }  
}

function* workereditoffer(action)
{ try
    {
      let location, typeContract, skillsRequired = '';
      if(typeof action.payload.skillsRequired === 'object')
      {
        skillsRequired = action.payload.skillsRequired
      }
      else
      {
        skillsRequired = action.payload.skillsRequired.split(',');
      }
      if(typeof action.payload.location === 'object')
      {
        location = action.payload.location['@id']
      }
      else
      {
        location = action.payload.location
      }
      if(typeof action.payload.typeContract === 'object')
      {
        typeContract = action.payload.typeContract['@id']
      }
      else
      {
        typeContract = action.payload.typeContract
      }
      const data = {
        titleOffer: action.payload.titleOffer,
        CompanyDescription: action.payload.CompanyDescription,
        offerDescription: action.payload.offerDescription,
        experience: action.payload.experience,
        languages: action.payload.languages, 
        levelStudy: action.payload.levelStudy,
        skillsRequired: skillsRequired,
        remuneration: action.payload.remuneration,
        offerDeadline: action.payload.offerDeadline,
        desiredStartDate: action.payload.desiredStartDate,
        mission: action.payload.mission,
        schedule: action.payload.schedule,
        location: location,
        typeContract: typeContract,
      }
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offers/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))

yield workergetalloffer({payload : 1});
yield put(editofferSuccess(data));

}
  catch(error)
  {
    yield put(editofferFailure(error))
  }  
}

function* workerdeleteoffer(action)
{ try
    {
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offers/${action.payload}`,
      method: 'DELETE',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))

yield workergetalloffer({payload : 1});
yield put(deleteofferSuccess());

}
  catch(error)
  {
    yield put(deleteofferFailure(error))
  }  
}

function* workerfilteroffer(action)
{ 
  try
    {
      let str='';
      if(action.payload.titleoffer !== undefined)
      {
        str+=`titleOffer=${action.payload.titleoffer}`
      }
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offers?${str}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))
  yield put(getAllofferSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllofferFailure(error.response.status))
  }  
}

function* workergetofferbyid(action)
{
  try {
  const data = {
    id : action.payload.id
  }
  const res = yield call(()=> api({
  url: `${process.env.REACT_APP_API_URL}/api/offers/${action.payload.id}`,
  method: 'GET',
  data: data,
  headers: {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  },
  }));
  yield put(getofferbyidSuccess(res.data));
}
  catch(error)
  {
    yield put(getofferbyidFailure(error.response.status))
  }  
}

export function* watcheroffer()
{
  yield takeEvery('Getalloffer', workergetalloffer)
  yield takeEvery('Addoffer', workeraddoffer)
  yield takeEvery('Editoffer', workereditoffer)
  yield takeEvery('Deleteoffer', workerdeleteoffer)
  yield takeEvery('filteroffer', workerfilteroffer)
  yield takeEvery('getofferbyid', workergetofferbyid)
}