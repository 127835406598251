import LogoUser from '../../assets/images/user.png';
import { Link } from 'react-router-dom';
import { FiEdit2 } from 'react-icons/fi';

export default function UsersRow({list, onCheck, handleshowmodalEdit,handleshowmodalUpload}) {
  const isAdmin = list.roles.includes('ROLE_ADMIN');
  const isManager = list.roles.includes('ROLE_MANAGER');
  const isHR = list.roles.includes('ROLE_HR');
  const isREFFERANT = list.roles.includes('ROLE_REFFERANT');

  let role, color;
  if (isAdmin) {
    role = 'Administrateur';
    color = 'gray';
  } else if (isManager) {
    role = 'Manager';
    color = 'yellow';
  } else if (isHR) {
    role = 'Chargé de recrutement';
    color = 'pink';
  } else if (isREFFERANT) {
    role = 'Référent';
    color = 'teal';
  } else {
    role = 'Utilisateur';
    color = 'green';
  }

  return (
    <>
    <tr key={`users-${list.id}`} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '>
      <td>
        <Link>
          {list.mediaObject ?
          <img 
            className="w-10 h-10 rounded-full"
            src={`${process.env.REACT_APP_API_URL}/media/${list.mediaObject?.imagePath}`}
            alt=''
            onClick={() => handleshowmodalUpload(list)}
          />
          : 
          <img 
            className="w-10 h-10 rounded-full"
            src={LogoUser}
            alt=''
            onClick={() => handleshowmodalUpload(list)}
          />
          }
         </Link>
      </td>
      <td className='px-6 py-4 font-medium'>
        {list.firstname}
      </td>
      <td className='px-6 py-4 font-medium'>
        {list.lastname}
      </td>
      <td className='px-6 py-4 font-medium'>
        {list.username}
      </td>
      <td className='px-6 py-4 font-medium'>
        <span className={`bg-${color}-100 text-${color}-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-${color}-900 dark:text-${color}-300 whitespace-nowrap`}>
          { role }
        </span>
      </td>
      <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
        <input
          type='checkbox'
          checked={list.isActive || false }
          onChange={() => onCheck(list)}
          className='w-6 h-6 accent-primary cursor-pointer'
        />
      </td>
      <td className='flex items-center px-6 py-4 text-right font-medium'>
        <Link className='mr-3'>
          <FiEdit2 onClick={() => handleshowmodalEdit(list)} />
        </Link>
      </td>
    </tr>
  </>
  )
}
