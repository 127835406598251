import moment from 'moment';

export default function UpdatedAtRow({list}) {
  return (
    <>
      <td className='px-6 py-4'>
          {moment(list).format('DD/MM/YYYY')}
      </td>
    </>
  )
}
