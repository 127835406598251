import { useState } from "react";

const RemunerationHook = ({selectedItem}) => {
  const setDefaultValue = (value, defaultValue = '') => value || defaultValue;

  const [Data, setData] = useState({
    id:  selectedItem ? selectedItem.id : null,
    createdAt: setDefaultValue(selectedItem?.createdAt),
    updatedAt: setDefaultValue(selectedItem?.updatedAt),
    remunerationActualFix: setDefaultValue(selectedItem?.remunerationActualFix),
    currentVariable: setDefaultValue(selectedItem?.currentVariable),
    remunerationDesiredFix: setDefaultValue(selectedItem?.remunerationDesiredFix),
    desiredVariable: setDefaultValue(selectedItem?.desiredVariable),
    comment: setDefaultValue(selectedItem?.comment),
  });

  return [
    Data,
    setData,
  ];
}

export default RemunerationHook