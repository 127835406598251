import { FiTrash } from "react-icons/fi"
import { Link } from "react-router-dom"

function NotificationRow({
  list,
  deleteNotification
}) {
  return (
    <>
    <tr key={`history-${list.id}`} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '>
      <td className='px-6 py-4 font-medium truncate'>
          {list.title}
      </td>
      <td className='px-6 py-4 font-medium truncate'>
        {list.description}
      </td>
      <td className='flex justify-center items-center py-2 my-2'>
        <Link>
          <FiTrash onClick={(e) => deleteNotification(e , list.id)} />
        </Link>
      </td>
    </tr>
  </>
  )
}


export default NotificationRow
