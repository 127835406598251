import { BiInfoCircle } from 'react-icons/bi';

export default function AlertApplicant({ Message , color, className }) {
  return (
    <div className={className} role='alert'>
      <BiInfoCircle className='mr-2' />
        <div>
          <span className='font-sm'>{ Message }</span>
        </div>
    </div>
  )
}
