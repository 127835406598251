export const typecontractSagaActions = {
  getalltypecontract,
  addtypecontract,
  edittypecontract,
  getactivetypecontract
};

function getalltypecontract(payload)
{
  return {
    type: 'Getalltypecontract',
    payload
  }
}

function getactivetypecontract(payload)
{
  return {
    type: 'GetActivetypecontract',
    payload
  }
} 

function addtypecontract(payload)
{
  return {
    type: 'Addtypecontract',
    payload,
  }
}  

function edittypecontract(payload)
{
  return {
    type: 'Edittypecontract',
    payload,
  }
}  