import { useState } from "react";

const UserHook = ({selectedItem}) => {
  const [Data, setData] = useState({
    id: selectedItem ? selectedItem.id : null,
    isActive: selectedItem ? selectedItem.isActive: true,
    createdAt : selectedItem ? selectedItem.createdAt: null,
    firstname: selectedItem ? selectedItem.firstname : '',
    lastname: selectedItem ? selectedItem.lastname : '',
    username: selectedItem ? selectedItem.username : '',
    email: selectedItem ? selectedItem.email : '',
    roles: selectedItem ? selectedItem.roles : [],
    address: selectedItem ? selectedItem.address : '',
    cellphone: selectedItem ? selectedItem.cellphone : '',
    password: ''
  });

  return [
    Data,
    setData,
  ];
}

export default UserHook