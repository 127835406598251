import { useState } from 'react';
import Input from '../Input';
import GoBackButton from '../bouttons/GoBackButton';
import Password from '../sign-in/Password';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export default function UserForm({
  onSubmit,
  handleclosemodal,
  modif,
  credentials,
  onChange,
}) 
{
  const [showPassword, setShowPassword] = useState(false);

  const options = [
    { value: 'ROLE_MANAGER', label: 'Manager ' },
    { value: 'ROLE_ADMIN', label: 'administrateur' },
    { value: 'ROLE_USER', label: 'utilisateur' },
    { value: 'ROLE_HR', label: 'Chargé de recrutement' },  
    { value: 'ROLE_REFFERANT', label: 'Référent' },  
]

  const animatedComponents = makeAnimated();

  function handleTogglePassword(e) {
    e.preventDefault();
      setShowPassword(!showPassword);
  };

  const handleRole = (name, select) => {
    onChange(name, select.map((e) => e.value))
  };

  const handleChangeField = (e) => {
    const { name, value } = e.target;
    onChange(name, value)
  };

  let roles = credentials.roles
  .map(role => role)

  return (
    <>
    <form onSubmit={onSubmit}>
      <div className="grid md:grid-cols-2 md:gap-6">
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'firstname'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Nom'}
          value={credentials.firstname}
          onChange={handleChangeField}
        />
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name={'lastname'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Prénom'}
          value={credentials.lastname}
          onChange={handleChangeField}
        />  
      </div>
      <div className="grid md:grid-cols-2 md:gap-6">
        <Input
          type={'email'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'email'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Email'}
          value={credentials.email}
          onChange={handleChangeField}
        />
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'username'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Username'}
          value={credentials.username}
          onChange={handleChangeField}
        />
      </div>  
      <div className="relative z-0 w-full mb-6 group">
        <Password
          onClick={handleTogglePassword}
          showPassword={showPassword}
          onChange={handleChangeField}
          name = {'password'}
        />
      </div>
      
      <div className="grid md:grid-cols-2 md:gap-6">
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'address'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'adresse'}
          value={credentials.address}
          onChange={handleChangeField}
        />
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'cellphone'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Téléphone'}
          value={credentials.cellphone}
          onChange={handleChangeField}
        />
      </div>
      <div className="relative z-10 w-full mb-6 group">
        <Select 
          options={options}
          components={animatedComponents}
          isMulti
          className="basic-multi-select"
          onChange={(e) => handleRole('roles', e)}
          defaultValue={roles.map(role => ({
            label: role
          }))}
        />
      </div>
      <div className='flex items-center justify-between text-[#22BABB] py-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div>   
      </div>
    </form>
    </>
  )
}