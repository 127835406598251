function PDF({ cv, showSidebar, handleclosesidebar }) {
  return (
    <>
    <aside className={`fixed top-0 right-0 mt-14 z-40 w-[90%] md:w-1/2 h-screen transition-transform  transform ${showSidebar ? 'translate-x-0' : 'translate-x-full'}`}>
      <div className='h-full dark:bg-gray-800 bg-opacity-50'>
        <embed 
          src={cv}
          title="cirruculum vitae"
          className="w-full h-full" 
          type="application/pdf"
        />
      </div>  
    </aside> 
    </>
  );
}

export default PDF