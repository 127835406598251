import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  addprofilSuccess,
  addprofilailure,
  editprofilFailure,
  editprofilSuccess,
  getActiveprofilSuccess,
  getAllprofilFailure,
  getAllprofilSuccess,
  getactiveprofilFailure
} from './profilSlice';

const token = localStorage.getItem('token')

function* workergetallprofil(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/profils?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllprofilSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllprofilFailure(error.response.status))
  }  
}

function* workergetactiveprofil(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/profils?isActive=true&&pagination=false`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getActiveprofilSuccess(res.data));
}
  catch(error)
  {
    yield put(getactiveprofilFailure(error.response.status))
  }  
}

function* workeraddprofil(action)
{
  try
    {
      const data  = {
        profil: action.payload.profil,
        expertise: action.payload.expertise,
        isActive: action.payload.isActive,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/profils`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetallprofil({payload : 1}); 
  yield put(addprofilSuccess(data));
}
  catch(error)
  {
    yield put(addprofilailure(error.response.status));
  }  
}

function* workereditprofil(action)
{
  try
    {
      let expertise ='';
      if(typeof action.payload.expertise === 'object')
      {
        expertise = action.payload.expertise['@id']
      }
      else
      {
        expertise = action.payload.expertise
      }
      const data  = {
        id: action.payload.id,
        profil: action.payload.profil,
        expertise: expertise,
        isActive: action.payload.isActive,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/profils/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetallprofil({payload : 1});  
  yield put(editprofilSuccess(data));
}
  catch(error)
  {
    yield put(editprofilFailure(error.response.status));
  }
}

export function* watcherprofil()
{
  yield takeEvery('Getallprofil', workergetallprofil);
  yield takeEvery('GetActiveprofil', workergetactiveprofil);
  yield takeEvery('Addprofil', workeraddprofil);
  yield takeEvery('Editprofil', workereditprofil);
}