export const sourceSagaActions = {
  getallsource,
  addsource,
  editsource,
  getactivesource
};

function getallsource(payload)
{
  return {
    type: 'Getallsource',
    payload
  }
}

function getactivesource(payload)
{
  return {
    type: 'GetActivesource',
    payload
  }
} 

function addsource(payload)
{
  return {
    type: 'Addsource',
    payload,
  }
}  

function editsource(payload)
{
  return {
    type: 'Editsource',
    payload,
  }
}  