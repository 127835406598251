export const applicantofferSagaActions = {
  getapplicantofferfilter
};

function getapplicantofferfilter(payload)
{
  return {
    type: 'getapplicantofferfilter',
    payload,
  }
}