export const FeedBackSagaActions = {
  getallfeedbacks,
  addfeedback,
  editfeedback,
  deletefeedback,
  filterfeedback,
};

function getallfeedbacks(payload)
{
  return {
    type: 'Getallfeedback',
    payload
  }
}

function filterfeedback(payload)
{
  return {
    type: 'filterfeedback',
    payload
  }
}

function addfeedback(payload)
{
  return {
    type: 'Addfeedback',
    payload,
  }
}  

function editfeedback(payload)
{
  return {
    type: 'Editfeedback',
    payload,
  }
}  

function deletefeedback(payload)
{
  return {
    type: 'Deletefeedback',
    payload,
  }
}