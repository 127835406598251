import React from 'react'
import { FiInfo } from 'react-icons/fi'

function EmptyTable() {
 return (
  <div className='flex justify-center items-center bg-teal-100 rounded-lg p-5 text-sm text-teal-700 gap-4'>
    <FiInfo />
    <p>
      Aucun objet n'a été trouvé!
    </p>
  </div>
  )
}

export default EmptyTable

