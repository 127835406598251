import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItemsNotifications: 0,
  notificationList: [],
  success: false,
  error: null,
  notification: null
}

const notificationSlice = createSlice ({
  name: 'notification',
  initialState,
  reducers: {
    getAllnotificationSuccess(state, action)
    {
      state.totalItemsNotifications = action.payload['hydra:totalItems'];
      state.notificationList = action.payload['hydra:member'];  
      state.success = true;
    },
    getAllnotificationFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    deletenotificationSuccess(state, action)
    {
      state.success = true;
      state.notificationList = state.notificationList.filter((notification) => notification.id !== action.payload);
    },
    deletenotificationFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    editnotificationSuccess(state, action)
    {
      state.success = true;
      state.error = false;
    },
    editnotificationFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
  }
})

export const {
  getAllnotificationSuccess,
  getAllnotificationFailure,
  deletenotificationSuccess,
  deletenotificationFailure,
  editnotificationSuccess,
  editnotificationFailure
  } = notificationSlice.actions;

export default  notificationSlice.reducer