import React, { useState , useEffect } from 'react';
import SignInLeftSection from '../components/sign-in/SignInLeftSection';
import { useDispatch, useSelector } from 'react-redux';
import { authSagaActions } from '../redux/auth/authSagaActions';
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Password from '../components/sign-in/Password';
import Alert from '../components/Alerts';

function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState('');
  const [ErrorMessage, setErrorMessage] = useState('');
  const { 
    error,
    isAuthenticated
   } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(isAuthenticated) {
      setErrorMessage('')
      setSuccessMessage('Email et mot de passe validés !');
      setTimeout(() => {
        if(location?.state?.prevUrl)
        {
          navigate(location?.state?.prevUrl);
        }
        else
        {
          navigate('/home')
        }
      }, 1000);
    }
    else
    {
      setErrorMessage(error)
    }
  }, [navigate, isAuthenticated, error, location]);

  function handleTogglePassword(e) {
    e.preventDefault();
      setShowPassword(!showPassword);
  };

  function getValue(e)
  {
    setPassword(e.currentTarget.value);
    setPasswordError('')
  };

  function login(e) {
    e.preventDefault();
    if ((password.length === 0) || (username.length === 0)) {
      if (password.length === 0) {
        setPasswordError('Champ password est obligatoire!')
      }
      if (username.length === 0) {
        setEmailError('Champ mail est obligatoire!')
      }
    }    
    else
    {
      dispatch(authSagaActions.login({username, password}));
    }
  }

return (
  <div className='grid md:grid-cols-2 h-screen w-full grid-cols-1'>
    <SignInLeftSection className='bg-teal-500 flex justify-center items-center' />
      <div className='bg-white flex flex-col justify-center'>
        <h2 className='text-4xl text-dark font-medium text-center'>Se connecter</h2>
        <br></br>
        <form className='max-w-[400px] w-full mx-auto p-8 px-8 rounded-lg' onSubmit={login}> 
          { SuccessMessage && <Alert Message={ SuccessMessage } color={'green'} />}
          { ErrorMessage && <Alert Message={ ErrorMessage } color={'red'}  />}
          { emailError && <Alert Message={ emailError } color={'red'}  />}
          { passwordError && <Alert Message={ passwordError } color={'red'}  />}
          <div className='flex flex-col text-gray-400 py-2'>
            <div>
              <input
                type='text'
                name='username'
                className='block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-teal-500 focus:outline-none focus:ring-0 focus:border-teal-500 peer'
                placeholder='Email'
                onChange={(e) => {
                  setUsername(e.currentTarget.value);
                  setEmailError('')
                  }
                }
                error={emailError}
              />
            </div>
          </div>
          <Password
             onClick={handleTogglePassword}
             onChange={getValue}
             showPassword={showPassword}
             passwordError={passwordError}
          />
          <button className='w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>Se connecter</button>
          <div className='flex items-center justify-between text-[#22BABB] py-2'> 
            <div></div>
            <div> 
              <Link to='/resetPassword'>
                <p>Mot de passe oublié ?</p>
              </Link>
            </div>   
          </div>
      </form>
    </div>
  </div>
)}

export default Login
