import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  success: false,
  error: null,
}


const applicanttSlice = createSlice ({
  name: 'applicantUser',
  initialState,
  reducers: {
    addapplicantUserSuccess(state)
    {
      state.success = 'Affectation a été effectuée avec succès !';
      state.error = false;
    },
    addapplicantUsersFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        state.error = "Tu n'as pas l'accès pour faire cette action !"
      }
      else
      {
        state.error = "Quelque chose d'autre s'est passé !" 
      }
    },
    sendemailapplicantUserSuccess(state)
    {
      state.success = 'Email envoyé avec succés !'
      state.error = null
    },
    sendemaiapplicantUsersFailure(state, action)
    {
      state.error = action.payload
      state.success = null
    }
  }
});

export const {
  addapplicantUserSuccess,
  addapplicantUsersFailure,
  sendemailapplicantUserSuccess,
  sendemaiapplicantUsersFailure
  } = applicanttSlice.actions;

export default  applicanttSlice.reducer