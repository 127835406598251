import EmptyTable from "../components/EmptyTable"
import Title from "../components/Title"
import NavBar from "../components/template/NavBar"
import SideBar from "../components/template/SideBar"
import Table from "../components/Table"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { notificationSagaActions } from "../redux/Notification/NotificationSagaActions"
import Pagination from "../components/Pagination"

function Notification() {
const dispatch = useDispatch();
const [notification] = useState('notification');
const headers = ['Titre' , 'Description', 'Actions']
const {
  totalItemsNotifications,
  notificationList
} = useSelector(
  (state) => state.notification
);
const {
  user_id
  } = useSelector(
    (state) => state.auth
  );
  
  useEffect(() => {
    dispatch(notificationSagaActions.getallnotifications(1))
  },[dispatch])

  function onPageChange(currentPage) {
    dispatch(notificationSagaActions.getallnotifications(currentPage.selected + 1));
  }

  function deletenotification(e,item) {
    e.preventDefault();
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      dispatch(notificationSagaActions.deletenotification(item));
    }
  }

  return (
    <>
     <NavBar />
      <SideBar />
      <div className='flex flex-col gap-14 p-4 lg:ml-64 mt-14'>
          <Title 
            title={'Notifications'}
            className={'font-bold text-xl'}
          />
          <div className='min-h-fit'>
            <div className='overflow-x-auto shadow-md sm:rounded-lg'>
              {notificationList.length === 0 ?
                <EmptyTable />
                :
                <Table
                  List={notificationList.filter((notification) => user_id === notification.sentTo?.id)}
                  headers={headers}
                  classNameTable={'w-full text-sm text-left text-gray-500 dark:text-gray-400'}
                  classNamethead={'text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
                  classNameth={'px-6 py-3'}
                  name={notification}
                  deletenotification={deletenotification}
                />
              }
            </div>
        </div>  
        <div className='flex justify-center md:justify-center'>
            <Pagination
              onPage={onPageChange}
              totalItems={totalItemsNotifications}
              number={10}
            />
        </div>
      </div>
    </>   
  )
}

export default Notification
