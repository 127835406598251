import { useState } from "react";

const ProfilHook = ({selectedItem}) => {
  const setDefaultValue = (value, defaultValue = '') => value || defaultValue;

  const [Data, setData] = useState({
    id:  selectedItem ? selectedItem.id : null,
    createdAt: setDefaultValue(selectedItem?.createdAt),
    updatedAt: setDefaultValue(selectedItem?.updatedAt),
    profil: setDefaultValue(selectedItem?.profil),
    expertise: setDefaultValue(selectedItem?.expertise),
    isActive: setDefaultValue(selectedItem?.isActive),
  });

  return [
    Data,
    setData,
  ];
}

export default ProfilHook