import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItemshistory: 0,
  historyList: [],
  success: false,
  error: null,
  history: null
}

const historySlice = createSlice ({
  name: 'history',
  initialState,
  reducers: {
    getAllhistorySuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.historyList = action.payload['hydra:member'];  
      state.success = true;
    },
    getAllhistoryFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
  }
})

export const {
  getAllhistorySuccess,
  getAllhistoryFailure,
  addhistorySuccess,
  addhistoryailure,
  edithistorySuccess,
  edithistoryFailure,
  deletehistorySuccess,
  deletehistoryFailure,
  } = historySlice.actions;

export default  historySlice.reducer