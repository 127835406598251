export const usersSagaActions = {
  getallusers,
  editusers,
  getactiveusers,
  uploadImageUser,
  filtrerUser
};

function getallusers(payload)
{
  return {
    type: 'Getallusers',
    payload
  }
}

function getactiveusers(payload)
{
  return {
    type: 'GetActiveusers',
    payload
  }
} 

function editusers(payload)
{
  return {
    type: 'Editusers',
    payload,
  }
}

function uploadImageUser(payload)
{
  return {
    type: 'UploadImageUser',
    payload,
  }
}

function filtrerUser(payload)
{
  return {
    type: 'filtrerUser',
    payload,
  }
}