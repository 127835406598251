import EditDateRow from './EditDateRow';

export default function SourceRows({list, onCheck, handleshowmodalEdit}) {
  return (
      <>
      <tr key={`typecontract-${list.id}`} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '>
        <td className='px-6 py-4 font-medium'>
          {list.source}
        </td>
        <EditDateRow list={list} onCheck={onCheck} handleshowmodalEdit={handleshowmodalEdit} />
      </tr>
    </>
    )
}
