import { useState } from "react";

const ReferenceHook = ({selectedItem}) => {
  const setDefaultValue = (value, defaultValue = '') => value || defaultValue;

  const [Data, setData] = useState({
    id:  selectedItem ? selectedItem.id : null,
    createdAt: setDefaultValue(selectedItem?.createdAt),
    updatedAt: setDefaultValue(selectedItem?.updatedAt),
    contactName: setDefaultValue(selectedItem?.contactName),
    contactEmail: setDefaultValue(selectedItem?.contactEmail),
    contactCellphone: setDefaultValue(selectedItem?.contactCellphone),
    contactCompany: setDefaultValue(selectedItem?.contactCompany),
    comment: setDefaultValue(selectedItem?.comment),
    isApproved: setDefaultValue(selectedItem?.isApproved),
  });

  return [
    Data,
    setData,
  ];
}

export default ReferenceHook