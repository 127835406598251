export const profilSagaActions = {
  getallprofil,
  addprofil,
  editprofil,
  getactiveprofil
};

function getallprofil(payload)
{
  return {
    type: 'Getallprofil',
    payload
  }
}

function getactiveprofil(payload)
{
  return {
    type: 'GetActiveprofil',
    payload
  }
} 

function addprofil(payload)
{
  return {
    type: 'Addprofil',
    payload,
  }
}  

function editprofil(payload)
{
  return {
    type: 'Editprofil',
    payload,
  }
}