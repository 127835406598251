export default function Input({
  type,
  className,
  name,
  classNameLabel,
  placeholder,
  value,
   onChange,
   contentEditable,
   dangerouslySetInnerHTML
}) {
  return (
    <div className="relative z-0 w-full mb-6 group">
      <input 
        contentEditable={contentEditable}
        type={type}
        name={name} 
        className={className}
        placeholder=" "
        value={value}
        onChange={onChange}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
      <label htmlFor={name} className={classNameLabel}>{placeholder}</label>
    </div>
  )
}
