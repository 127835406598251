import { Navigate, Outlet, useLocation } from 'react-router-dom';

const RequireAuth = () => {
  const token = localStorage.getItem('token')
  const location = useLocation()
  if (!token) {
    return <Navigate to='/' state={{prevUrl: location.pathname}} />;
  }
  return (
    token
    ? <Outlet />
    : <Navigate to='/home' state={{prevUrl: location.pathname}} />
  )
}
export default RequireAuth
