import { useDispatch, useSelector } from 'react-redux';
import Title from '../components/Title'
import NavBar from '../components/template/NavBar'
import SideBar from '../components/template/SideBar'
import { useEffect, useState, useRef } from 'react';
import { profilSagaActions } from '../redux/profil/ProfilSagaActions';
import Pagination from '../components/Pagination';
import AddButton from '../components/bouttons/AddButton';
import Modal from '../components/forms/Modal';
import Table from '../components/Table';
import EmptyTable from '../components/EmptyTable';
import useClickOutside from '../components/useClickOutside';

function Profil() {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [showmodal , setshowmodal] = useState(false);
  const [modif , setModif] = useState(false);
  const [profil] = useState('profil');
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    totalItems,
    profilList
  } = useSelector(
      (state) => state.profil
  );
  const headers = ['Profil' , 'Expertise', 'Activé', 'Actions']

  useEffect(() => {
    dispatch(profilSagaActions.getallprofil(1));
  }, [dispatch]);

  const handleshowmodal = () => {
    setModif(false);
    setSelectedItem(null)
    setshowmodal(!showmodal);
  }

  const handleshowmodalEdit = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodal(!showmodal);
  }

  function onPageChange(currentPage) {
    dispatch(profilSagaActions.getallprofil(currentPage.selected + 1));
  }

  function onCheck(val) {
    const state = val.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} ce profil ?`)) {
      const isActive = {...val, isActive : !val.isActive};
      dispatch(profilSagaActions.editprofil({
        id: isActive.id,
        profil: isActive.profil,
        isActive: isActive.isActive,
        createdAt: isActive.createdAt,
      }
      ));
    }
  }

  const handleClickOutside = (ref) => {
    setshowmodal(false);
  };

  useClickOutside({refs:[modalRef], onClickOutside:handleClickOutside});

  return (
    <>
      <NavBar />
      <SideBar />
      <div className='flex flex-col gap-12 p-4 lg:ml-64 mt-14'>
        <Title 
          title={'Liste des profils'}
          className={'font-bold text-3xl'}
        />
        <AddButton
            onClick={handleshowmodal}
            Name='Ajouter un profil'
        />
        <div className='min-h-fit'>
          {showmodal && <Modal refer={modalRef} config={profil} modif={modif} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} />}   
          <div className='overflow-x-auto shadow-md sm:rounded-lg'>
            {profilList.length === 0 ?
              <EmptyTable />
              :
              <Table
                List={profilList}
                headers={headers}
                classNameTable={'w-full text-sm text-left text-gray-500 dark:text-gray-400'}
                classNamethead={'text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
                classNameth={'px-6 py-3'}
                handleshowmodalEdit={handleshowmodalEdit}
                onCheck={onCheck}
                name={profil}
              />
              }
          </div>
        </div>  
        <div className='flex justify-center md:justify-center'>
            <Pagination
              onPage={onPageChange}
              totalItems={totalItems}
              number={10}
            />
        </div>
      </div>
    </>    
  )
}

export default Profil
