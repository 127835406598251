import { useState } from "react";

const FeedBackHook = ({selectedItem}) => {
  const setDefaultValue = (value, defaultValue = '') => value || defaultValue;

  const [Data, setData] = useState({
    id:  selectedItem ? selectedItem.id : null,
    createdAt: setDefaultValue(selectedItem?.createdAt),
    updatedAt: setDefaultValue(selectedItem?.updatedAt),
    englishLevel: setDefaultValue(selectedItem?.englishLevel),
    availability: setDefaultValue(selectedItem?.availability),
    comment: setDefaultValue(selectedItem?.comment),
    isApproved: setDefaultValue(selectedItem?.isApproved),
  });

  return [
    Data,
    setData,
  ];
}

export default FeedBackHook