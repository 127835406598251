import api from '../../hooks/Api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
  addstatusFailure,
  addstatusSuccess,
  editstatusFailure,
  editstatusSuccess,
  getActiveStatus,
  getAllstatusSuccess,
  getstatusFailure,
} from './statusSlice';

const token = localStorage.getItem('token');

function* workergetallstatus(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/statuses?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllstatusSuccess(res.data));
}
  catch(error)
  {
    yield put(getstatusFailure(error.response.status))
  }  
}

function* workergetactivestatus(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/statuses?isActive=true&&pagination=false`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getActiveStatus(res.data));
}
  catch(error)
  {
    yield put(getstatusFailure(error.response.status))
  }  
}

function* workeraddstatus(action)
{
  try
    {
      const data  = {
        status: action.payload.status,
        codecouleur: action.color,
        isActive: action.payload.isActive
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/statuses`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield put(addstatusSuccess(data));
}
  catch(error)
  {
    yield put(addstatusFailure(error.response.status))
  }  
}

function* workereditstatus(action) {
  try {
    const data = {
      id: action.payload.id,
      status: action.payload.status,
      codecouleur: action.color,
      isActive: action.payload.isActive,
      createdAt: action.payload.createdAt
    };
    yield call(() =>
      api({
        url: `${process.env.REACT_APP_API_URL}/api/statuses/${action.payload.id}`,
        method: 'PUT',
        data: data,
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
    );
    yield put(editstatusSuccess(data));
  } catch (error) {
    yield put(editstatusFailure(error.response.status))
  }
}

export function* watcherstatus()
{
  yield takeEvery('Getallstatus', workergetallstatus)
  yield takeEvery('GetActivestatus', workergetactivestatus)
  yield takeEvery('Addstatus', workeraddstatus)
  yield takeEvery('Editstatus', workereditstatus)
}