import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../components/Pagination';
import Table from '../components/Table';
import Title from '../components/Title';
import NavBar from '../components/template/NavBar';
import SideBar from '../components/template/SideBar';
import { useEffect, useState, useRef } from 'react';
import { usersSagaActions } from '../redux/users/UsersSagaActions';
import Modal from '../components/forms/Modal';
import EmptyTable from '../components/EmptyTable';
import { FiSearch } from 'react-icons/fi';
import useClickOutside from '../components/useClickOutside';

function Users() {
  const modalRef = useRef(null);
  const modalRef1 = useRef(null);
  const dispatch = useDispatch();
  const [showmodal , setshowmodal] = useState(false);
  const [isUser , setisUser] = useState(false);
  const [showmodalUpload , setshowmodalUpload] = useState(false);
  const [modif , setModif] = useState(false);
  const [users] = useState('users');
  const [upload] = useState('upload');
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    totalItems,
    usersList
  } = useSelector(
      (state) => state.users
  );
  const headers = ['', 'Nom' , 'Prenom', 'Username', 'Role','Activé', 'Actions']

  useEffect(() => {
    dispatch(usersSagaActions.getallusers(1));
  }, [dispatch]);

  const handleshowmodalEdit = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodal(!showmodal);
    setisUser(!isUser)
  }

  const handleUploadModal = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodalUpload(!showmodalUpload);
    setshowmodal(!showmodal)
  }

  function onPageChange(currentPage) {
    dispatch(usersSagaActions.getallusers(currentPage.selected + 1));
  }

  function onCheck(val) {
    const state = val.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} cet utilisateur ?`)) {
      const isActive = {...val, isActive : !val.isActive};
      dispatch(usersSagaActions.editusers({
        id: isActive.id,
        firstname: isActive.firstname,
        lastname: isActive.lastname,
        username: isActive.username,
        password: isActive.password,
        roles: isActive.roles,
        email: isActive.email,
        address: isActive.address,
        cellphone: isActive.cellphone,
        isActive: isActive.isActive,
        createdAt: isActive.createdAt,
      }
      ));
    }
  }

  const handlesearch = (e) => {
    const { value  } = e.target;
      dispatch(usersSagaActions.filtrerUser({
        fullname : value ,
      }));
  };

  const handleClickOutside = (ref) => {
    if (ref === modalRef)
    {
      setshowmodal(false);
      setisUser(false);
    }
    else if (ref === modalRef1) {
      setshowmodal(false);
      setshowmodalUpload(false);
    } 
  };

  useClickOutside({ refs : [modalRef,modalRef1], onClickOutside: handleClickOutside});

  return (
    <>
      <NavBar />
      <SideBar />
      <div className='flex flex-col gap-12 p-4 lg:ml-64 mt-14'>
        <div className="flex flex-col gap-4 lg:flex-row lg:justify-between">
          <Title 
            title={'Liste des utilisateurs'}
            className={'font-bold text-3xl'}
          />
          <div className='relative'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <FiSearch />
            </div>
            <input 
              type='text'
              className='p-2 pl-10 text-sm md:w-72 w-72   text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-teal-500'
              placeholder='Rechercher par nom prénom email'
              name='contact'
              onChange={handlesearch}
            />
          </div>
        </div>  
        <div className='min-h-fit'>
          { isUser && <Modal refer={modalRef} config={users} modif={modif} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} isUser={isUser} setisUser={setisUser} />}
          { showmodalUpload && <Modal refer={modalRef1} config={upload} modif={modif} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} showmodalUpload={showmodalUpload} setshowmodalUpload={setshowmodalUpload} />} 
          <div className='overflow-x-auto shadow-md sm:rounded-lg'>
            {usersList.length === 0 ?
            <EmptyTable />
            :
            <Table
              List={usersList}
              headers={headers}
              classNameTable={'w-full text-sm text-left text-gray-500 dark:text-gray-400'}
              classNamethead={'text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
              classNameth={'px-6 py-3'}
              handleshowmodalEdit={handleshowmodalEdit}
              handleshowmodalUpload={handleUploadModal}
              onCheck={onCheck}
              name={users}
            />
            }
          </div>
        </div> 
        <div className='flex justify-center md:justify-center'>
          <Pagination
            onPage={onPageChange}
            totalItems={totalItems}
            number={10}
          />
        </div>  
      </div>
    </>
  )
}

export default Users