export const remunerationSagaActions = {
  getallremunerations,
  addremuneration,
  editremuneration,
  deleteremuneration,
  filterremuneration,
};

function getallremunerations(payload)
{
  return {
    type: 'Getallremuneration',
    payload
  }
}

function filterremuneration(payload)
{
  return {
    type: 'filterremuneration',
    payload
  }
}

function addremuneration(payload)
{
  return {
    type: 'Addremuneration',
    payload,
  }
}  

function editremuneration(payload)
{
  return {
    type: 'Editremuneration',
    payload,
  }
}  

function deleteremuneration(payload)
{
  return {
    type: 'Deleteremuneration',
    payload,
  }
}