import React from 'react';
import StatusRow from './rows/StatusRow';
import TypeContractRow from './rows/TypeContractRow';
import ProfilRows from './rows/ProfilRows';
import SourceRows from './rows/SourceRows';
import OfficeRows from './rows/OfficeRows';
import UsersRow from './rows/UsersRow';
import CandidatRow from './rows/CandidatRow';
import FeedbackRow from './rows/FeedbackRow';
import ReferenceRow from './rows/ReferenceRow';
import RemunerationRow from './rows/RemunerationRow';
import HistoryRow from './rows/HistoryRow';
import NotificationRow from './rows/NotificationRow';

export default function Table({
  List,
  headers,
  classNameTable,
  classNamethead,
  classNameth,
  onCheck,
  handleshowmodalEdit,
  handleshowmodalUpload,
  name,
  handleshowsidebarCV,
  deleteCandidat,
  handleAcceptRefuseCandidat,
  handleshowmodalFeatures,
  showmodalFeature,
  handleshareCandidat,
  deleteFeedback,
  deleteReference,
  deleteRemuneration,
  deletenotification
}) {
  const rows = List && List.length? List.map((list) => {
    return (
      <React.Fragment key={list.id}>
        { name === 'status' && <StatusRow list={list} onCheck={onCheck} handleshowmodalEdit={handleshowmodalEdit} />}
        { name === 'typecontract' && <TypeContractRow list={list} onCheck={onCheck} handleshowmodalEdit={handleshowmodalEdit} />}
        { name === 'profil' && <ProfilRows list={list} onCheck={onCheck} handleshowmodalEdit={handleshowmodalEdit} />}
        { name === 'source' && <SourceRows list={list} onCheck={onCheck} handleshowmodalEdit={handleshowmodalEdit} />}
        { name === 'office' && <OfficeRows list={list} onCheck={onCheck} handleshowmodalEdit={handleshowmodalEdit} />}
        { name === 'users' && <UsersRow list={list} onCheck={onCheck} handleshowmodalEdit={handleshowmodalEdit} handleshowmodalUpload={handleshowmodalUpload} />}
        { name === 'candidat' && 
        <CandidatRow
          list={list}
          onCheck={onCheck}
          handleshowmodalEdit={handleshowmodalEdit}
          handleshowmodalUpload={handleshowmodalUpload}
          handleshowsidebarCV={handleshowsidebarCV}
          deleteCandidat={deleteCandidat}
          handleAcceptRefuseCandidat={handleAcceptRefuseCandidat} 
          handleshowmodalFeatures={handleshowmodalFeatures}
          showmodalFeature={showmodalFeature}
          handleshareCandidat={handleshareCandidat}
        />
        }
        { name === 'feedback' &&
         <FeedbackRow 
          list={list}
          deleteFeedback={deleteFeedback}
          handleshowmodalEdit={handleshowmodalEdit}
        />
        }
        { name === 'reference' &&
         <ReferenceRow 
          list={list}
          deleteReference={deleteReference}
          handleshowmodalEdit={handleshowmodalEdit}
        />
        }
        { name === 'remuneration' &&
         <RemunerationRow 
          list={list}
          deleteRemuneration={deleteRemuneration}
          handleshowmodalEdit={handleshowmodalEdit}
        />
        }
        { name === 'history' &&
         <HistoryRow 
          list={list}
        />
        }
        {name === 'notification' &&
          <NotificationRow
            list={list}
            deleteNotification={deletenotification}
          />
        }
      </React.Fragment>
    )
    }) : null;
    
    const thElements = headers.map((header) => (
      <th key={header} scope='col' className={classNameth}>
        {header}
      </th>
    ));
    
    return (
      <table className={classNameTable}>
        <thead className={classNamethead}>
          <tr>
           {thElements}
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
}