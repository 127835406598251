function ItemCardApplicantStatusFile({
  icon,
  name,
  data
}) {
  return (
    <>
      {icon}
      <span className='text-sm font-bold text-gray-900 dark:text-white truncate'>{name}</span>
      <span style={{backgroundColor: data?.codecouleur ? data?.codecouleur : 'black' }}  className={'truncate text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'}>
          {data?.status}
      </span>
    </>
  )
}

export default ItemCardApplicantStatusFile
