import { Link } from "react-router-dom"

function FileApplicantButton({
  className,
  title,
  action
}) {
  return (
    <Link onClick={action} className={className} >
      {title}
    </Link>
  )
}

export default FileApplicantButton
