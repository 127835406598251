import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  addremunerationSuccess,
  addremunerationailure,
  deleteremunerationFailure,
  deleteremunerationSuccess,
  editremunerationFailure,
  editremunerationSuccess,
  getAllremunerationFailure,
  getAllremunerationSuccess,
} from './RemunerationSlice';

const token = localStorage.getItem('token')

function* workergetallremuneration(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/remunerations?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
  }
))
  yield put(getAllremunerationSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllremunerationFailure(error.response.status))
  }  
}

function* workeraddremuneration(action)
{
  try
    {
      const data  = {
        remunerationActualFix : action.payload.remunerationActualFix,
        currentVariable : action.payload.currentVariable,
        remunerationDesiredFix : action.payload.remunerationDesiredFix,
        desiredVariable : action.payload.desiredVariable,
        comment : action.payload.comment,
        applicant : action.payload.applicant
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/remunerations`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetallremuneration({payload : 1});  
  yield put(addremunerationSuccess(data));
}
  catch(error)
  {
    yield put(addremunerationailure(error.response.status))
  }  
}

function* workereditremuneration(action)
{
  try
    {
      const data  = {
        id: action.payload.id,
        remunerationActualFix : action.payload.remunerationActualFix,
        currentVariable:  action.payload.currentVariable,
        remunerationDesiredFix:  action.payload.remunerationDesiredFix,
        desiredVariable:  action.payload.desiredVariable,
        comment: action.payload.comment,
        applicant: action.payload.applicant
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/remunerations/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))
  yield workergetallremuneration({payload : 1});  
  yield put(editremunerationSuccess(data));
}
  catch(error)
  {
    yield put(editremunerationFailure(error.response.status))
  }
}

function* workerdeleteremuneration(action)
{ try
    {
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/remunerations/${action.payload}`,
      method: 'DELETE',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))

yield workergetallremuneration({payload : 1});
yield put(deleteremunerationSuccess());

}
  catch(error)
  {
    yield put(deleteremunerationFailure(error))
  }  
}

export function* watcherremuneration()
{
  yield takeEvery('Getallremuneration', workergetallremuneration)
  yield takeEvery('Addremuneration', workeraddremuneration)
  yield takeEvery('Deleteremuneration', workerdeleteremuneration)
  yield takeEvery('Editremuneration', workereditremuneration) 
}