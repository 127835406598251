import { Link } from 'react-router-dom';
import Title from '../components/Title';
import AddButton from '../components/bouttons/AddButton';
import NavBar from '../components/template/NavBar';
import SideBar from '../components/template/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { offerSagaActions } from '../redux/Offers/OffersSagaActions';
import { BiCurrentLocation } from 'react-icons/bi';
import { BsBriefcase } from 'react-icons/bs';
import moment from 'moment';
import EmptyTable from '../components/EmptyTable';
import Pagination from '../components/Pagination';
import Modal from '../components/forms/Modal';
import useClickOutside from '../components/useClickOutside';
import { FiEdit2, FiSearch, FiTrash } from 'react-icons/fi';
import '../assets/sass/spancard.scss';

function Offers() {
  const dispatch = useDispatch();
  const [showmodal , setshowmodal] = useState(false);
  const modalRef = useRef(null);
  const [modif , setModif] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [offer] = useState('offer');
  const {
    totalItems,
    offerList
  } = useSelector(
      (state) => state.offer
  );

  useEffect(() => {
    dispatch(offerSagaActions.getalloffers(1));
  }, [dispatch]);

  function onPageChange(currentPage) {
    dispatch(offerSagaActions.getalloffers(currentPage.selected + 1));
  }

  const handleshowmodal = () => {
    setModif(false);
    setSelectedItem(null)
    setshowmodal(!showmodal);
  }

  const handleClickOutside = (ref) => {
    if (ref === modalRef)
    {
      setshowmodal(false);
    }
  };

  useClickOutside({ refs : [modalRef], onClickOutside: handleClickOutside});

  const handleshowmodalEdit = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodal(!showmodal);
  }

  function deleteoffer(e,item) {
    e.preventDefault();
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      dispatch(offerSagaActions.deleteoffer(item));
    }
  }

  const handlesearch = (e) => {
    const { value  } = e.target;
      dispatch(offerSagaActions.filteroffer({
        titleoffer : value ,
    }));
  };

  const createMarkup = (data) => {
    return { __html: data };
  }

  const card = offerList.map((offerList) => {
    const createdAt = moment(offerList.createdAt);
    const currentDate = moment();
    const daysSinceCreation = currentDate.diff(createdAt, 'days');
    return (
      <div key={`offer-${offerList.id}`} className='w-full xl:w-[18.75%]'>
        <div className='bg-white mb-6 text-center shadow-lg rounded-lg flex flex-col w-full'>
          <div className='bg-transparent first:rounded-t px-5 py-3 border-b border-teal-200'>
            <div className="flex flex-row items-center justify-between">
              <h6 className='font-bold my-2 truncate p-2'>{offerList.titleOffer}</h6>
              <div className='flex flex-row items-center gap-2'>
                <Link>
                  <FiEdit2
                    className='h-4 w-4'
                    onClick={() => handleshowmodalEdit(offerList)}
                  />
                </Link>
                <Link>
                  <FiTrash
                    className='h-4 w-4'
                    onClick={(e) => deleteoffer(e , offerList.id)}
                  />
                </Link>
              </div>
            </div>
            <div className='w-[100%] flex justify-center'>
              <div className=' w-[35%] flex flex-row py-0.5 justify-center rounded-lg items-center bg-teal-100 text-teal-800 text-xs font-medium  dark:bg-teal-900 dark:text-teal-300 whitespace-nowrap gap-4'>
                <BsBriefcase />
                {offerList.typeContract?.typeContract}
              </div>
            </div>
          </div>
          <div className='flex-auto'>
            <div className='max-h-16 overflow-hidden leading-tight'>
              <span className='custom-span'
                dangerouslySetInnerHTML={createMarkup(offerList?.CompanyDescription)}>
              </span>
            </div>
            <ul className='p-2'>
              <li>
                <div className='flex flex-row justify-start items-center gap-2'>
                  <div className='flex justify-center items-center gap-2'>
                    <BiCurrentLocation className='w-4 h-4' />
                    <span className='font-bold text-sm'>Bureau</span>
                  </div>
                  <span className='text-[16px] text-gray-500 px-2 py-2 font-medium whitespace-nowrap'>{offerList.location?.office}</span>
                </div>
              </li>
            </ul>
          </div>
          <div className='flex justify-between items-center mt-4 py-6 bg-transparent rounded-b px-4 border-t border-blueGray-200'>
            <span className='text-[10px] 2xl:text-[12px] text-gray-500 font-medium whitespace-nowrap'>
              <span className='text-[12px] text-gray-500 font-medium whitespace-nowrap'>
                { daysSinceCreation === 0 ? (
                  'Aujourd\'hui'
                ) : (
                  <>
                    Offre publiée il y a{' '}
                    <span className='text-[12px] text-gray-500 font-medium whitespace-nowrap'>
                      {daysSinceCreation} jours
                    </span>
                  </>
                )}
              </span>
            </span>
            <Link to={`/offre/${offerList.id}`} className='text-[10px] 2xl:text-[12px] font-medium whitespace-nowrap text-teal-500'>
              Voir plus
            </Link>
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <NavBar />
      <SideBar />
      <div className='flex flex-col gap-4 p-2 lg:ml-64 mt-24'>
        <Title 
          title={"Nos offres d'emploi"}
          className={'font-bold text-3xl'}
        />
        <div className='w-full flex flex-col md:flex-row justify-between items-center py-3'>
          <AddButton
            onClick={handleshowmodal}
            Name='Ajouter une offre'
          /> 
          <div className='relative'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <FiSearch />
            </div>
            <input 
              type='text'
              className='p-2 pl-10 text-sm md:w-72 w-72   text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-teal-500'
              placeholder="Rechercher par titre de l'offre"
              name='titleoffer'
              onChange={handlesearch}
            />
          </div>
        </div>  
        {showmodal && <Modal refer={modalRef} config={offer} modif={modif} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} />}
        {
          offerList.length === 0 ?
            <EmptyTable />
            :
            <div className='xl:flex xl:flex-wrap justify-center gap-2'>
              {card}
            </div>
        }
        <div className='flex justify-center md:justify-center'>
          <Pagination
            onPage={onPageChange}
            totalItems={totalItems}
            number={10}
          />
        </div>   
      </div>
    </>  
  )
}

export default Offers
