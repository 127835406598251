

function ValidationsDataCandidat(data) {
  const errors = [];

  if (data.firstname.length === 0) {
    errors.push('Champ Nom est obligatoire!');
  }
  if (data.lastname.length === 0) {
    errors.push('Champ Prénom est obligatoire!');
  }
  if (data.email.length === 0) {
    errors.push('Champ Email est obligatoire!');
  }
  if (data.cellphone.length === 0) {
    errors.push('Champ Téléphone est obligatoire!');
  }
  if (data.poste.length === 0) {
    errors.push('Champ Poste est obligatoire!');
  }
  if (data.status?.length === 0) {
    errors.push('Champ Statut est obligatoire!');
  }
  if (data.office?.length === 0) {
    errors.push('Champ Bureau est obligatoire!');
  }
  if (data.profil?.length === 0) {
    errors.push('Champ Profile est obligatoire!');
  }
  if (data.typecontract?.length === 0) {
    errors.push('Champ Type de contrat est obligatoire!');
  }
  if (data.source?.length === 0) {
    errors.push('Champ source est obligatoire!');
  }
  if (data.mediaObject.length === 0) {
    errors.push('Champ media est obligatoire!');
  }

  return errors;
}

export default ValidationsDataCandidat
