import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  getAllmediaobjectSuccess,
  getAllmediaobjectFailure
} from './mediaobjectSlice';

const token = localStorage.getItem('token')

function* workergetallmediaobject(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/media_objects?pagination=false`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllmediaobjectSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllmediaobjectFailure(error.response.status))
  }  
}

export function* watchermediaobject()
{
  yield takeEvery('Getallmediaobject', workergetallmediaobject)
}