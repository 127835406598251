import Alert from "../Alerts";
import GoBackButton from "../bouttons/GoBackButton";

export default function UploadImageUserForm({
  modif,
  onSubmit,
  onChange,
  mediaObjectError,
  handleclosemodal
})
{
  return (
    <>
    <form encType="multipart/form-data" onSubmit={onSubmit}>
      { mediaObjectError ? <Alert Message={ mediaObjectError } color={'red'} /> : null }
      <input
        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        type='file' 
        name={'mediaObject'}
        accept='image/*'
        onChange={onChange}
      />
      <div className='flex items-center justify-center text-primary gap-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div> 
      </div>
    </form>
    </>
  )
}
