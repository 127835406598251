import SideBar from '../components/template/SideBar'
import Title from '../components/Title'
import NavBar from '../components/template/NavBar'
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { offerSagaActions } from '../redux/Offers/OffersSagaActions';
import Applicationminicard from '../components/Applicationminicard';
import moment from 'moment';
import Input from '../components/Input';
import CandidatHook from '../hooks/CandidatHook';
import { CandidatSagaActions } from '../redux/candidat/CandidatSagaActions';
import Alert from '../components/Alerts';
import { statusSagaActions } from '../redux/status/statusSagaAction';
import { applicantofferSagaActions } from '../redux/ApplicantOffer/ApplicantOfferSagaActions';
import ValidationsApply from '../components/forms/ValidationsApply';
import AlertApplicant from '../components/AlertApplicant';

function SingleApplication() {
  const dispatch = useDispatch();
  const [actionSuccess , setactionSuccess] = useState('');
  const [DataCandidat, setDataCandidat] = CandidatHook({selectedItem:null});
  const { id } = useParams();
  const errors = ValidationsApply(DataCandidat);
  const [FirstnameApplicantError , setFirstnameApplicantError ] = useState('');
  const [LastnameApplicantError , setLastnameApplicantError ] = useState('');
  const [EmailApplicantError , setEmailApplicantError ] = useState('');
  const [MediaObjectApplicantError , setMediaObjectApplicantError ] = useState('');
  const {
    offer
  } = useSelector(
    (state) => state.offer
  );
  const {
    success
  } = useSelector(
    (state) => state.candidat
  );

  const {
    totalItems,
    applicantofferList
  } = useSelector(
      (state) => state.applicantoffer
  );

  useEffect(() => {
    dispatch(offerSagaActions.getofferbyid({id : id }));
    dispatch(statusSagaActions.getactivestatus());
    dispatch(applicantofferSagaActions.getapplicantofferfilter(offer?.titleOffer))
  },[dispatch, id, offer?.titleOffer]);

  const handleChangeCandidat = (name, value) => {
    setDataCandidat({ ...DataCandidat, [name]: value});
  }
  
  const handleChangeField = (e) => {
    const { name, value, files } = e.target;
    if (name === "mediaObject") {
      setMediaObjectApplicantError('')
      handleChangeCandidat(name, files[0])
    }
    else
    {
      if(name === 'firstname')
      {
        setFirstnameApplicantError('')
      }
      if(name === 'lastname')
      {
        setLastnameApplicantError('')
      }
      if(name === 'email')
      {
        setEmailApplicantError('')
      }
      handleChangeCandidat(name, value)
    }
  };

  function applyapplicant (e)
  {
    e.preventDefault();
    if (errors.length > 0) {
      console.log(errors);
      errors.forEach((error) => {
        switch (error) {
          case 'Le champ Nom est obligatoire!':
            setFirstnameApplicantError(error);
            break;
          case 'Le champ Prénom est obligatoire!':
            setLastnameApplicantError(error);
            break;
          case 'Le champ Email est obligatoire!':
            setEmailApplicantError(error);
            break;
          case 'Le champ media est obligatoire!':
            setMediaObjectApplicantError(error);
            break;
          default:
            break;
        }
      });
    }
    else
    {
      if(success.data === 'Tu as déja postulé !')
      {
        setactionSuccess('');
      }
      else if(success.data !== 'Tu as déja postulé !')
      {
        dispatch(CandidatSagaActions.applyapplicant({
          firstname: DataCandidat.firstname,
          lastname: DataCandidat.lastname,
          email: DataCandidat.email,
          poste: offer.titleOffer,
          office: offer.location.id,
          typecontract: offer.typeContract.id,
          mediaObject: DataCandidat.mediaObject,
          offer: offer.id
        }));
        setactionSuccess(`Vous avez postulé à l'offre ${offer?.titleOffer} avec succès!`);
      }
    }
  }

  const countapplicantstreaties = applicantofferList.filter(
    (applicantapplication) => 
      applicantapplication.applicant.status.status !== 'Présélection' 
      && applicantapplication.applicant.status.status !== 'Refusé(e)'
  ).length;

  const countapplicantstrejected = applicantofferList.filter(
    (applicantapplication) => applicantapplication.applicant.status.status === 'Refusé(e)'
    ).length;

  const createMarkup = (data) => {
    return { __html: data };
  }

  return (
    <>
      <NavBar />
      <SideBar />
      <div className='flex flex-col gap-6 p-4 lg:ml-64 mt-14'>
        <Title 
          title={`Offre : ${offer?.titleOffer} `}
          className={'font-bold text-3xl'}
        />
        <div className='flex flex-col gap-4'>
          <div className='md:flex md:flex-row flex-col justify-center md:gap-4 gap-2'>
            <div className='relative flex flex-col items-center rounded-[20px] md:w-[40%] w-[100%] mx-auto border bg-white bg-clip-border shadow-2xl dark:!bg-navy-800 dark:text-white dark:!shadow-none p-3'>
                <h4 className='px-2 text-xl font-bold text-navy-700 dark:text-white'>
                  Détails de l'offre
                </h4>
                <div className='flex flex-col w-full mt-4 mb-8 gap-6'>
                  <div className='grid grid-cols-1 gap-4'>
                    <Applicationminicard 
                      data={offer?.referenceOffer}
                      titre={'Référence:'}
                      classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                  </div>  
                  <div className='grid grid-cols-2 gap-4'>
                    <Applicationminicard 
                      data={moment(offer?.createdAt).format('DD-MM-YYYY')}
                      titre={'Publiée le:'}
                      classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                    <Applicationminicard 
                      data={offer?.typeContract.typeContract}
                      titre={'Type de poste:'}
                      classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                  </div>
                  <div className='grid xl:grid-cols-3 grid-cols-1 gap-4'>
                    <Applicationminicard 
                      data={offer?.location.office}
                      titre={'Localisation:'}
                      classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                    <Applicationminicard 
                      data={offer?.experience}
                      titre={'Expérience:'}
                      classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                    <Applicationminicard 
                      data={offer?.levelStudy}
                      titre={"Niveau d'étude:"}
                      classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                  </div>
                  <div className='grid xl:grid-cols-3 grid-cols-1 gap-4'>
                    <Applicationminicard 
                      data={offer?.languages && offer.languages.join(' ')}
                      titre={'Langues:'}
                      classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                    <Applicationminicard 
                      data={moment(offer?.desiredStartDate).format('DD-MM-YYYY')}
                      titre={'Disponibilité:'}
                      classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                    <Applicationminicard 
                        data={offer?.OffreDeadline ? moment(offer?.OffreDeadline).format('DD-MM-YYYY') : ''}
                        titre={'Date limite:'}
                        classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                  </div>
                  <div className='grid xl:grid-cols-2 grid-cols-1 gap-4'>
                    <Applicationminicard 
                        data={offer?.skillsRequired.map((skill) => skill).join(' ')}
                        titre={'Compétences requises:'}
                        classnamespan={'text-teal-500 text-md font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300'}
                    />
                    <Applicationminicard 
                        data={offer?.remuneration}
                        titre={'Rémunération:'}
                        classnamespan={'text-[12px] text-gray-500 py-2 font-medium'}
                    />
                  </div>
                </div>  
            </div>
            <div className='relative flex flex-col items-center rounded-[20px] md:w-[60%] w-[100%]  mx-auto border bg-white bg-clip-border shadow-2xl dark:!bg-navy-800 dark:text-white dark:!shadow-none p-3'>
              <div className='mt-2 mb-8 w-full bg-gray-100 bg-clip-border px-3 py-4 shadow-md  dark:!bg-navy-700 dark:shadow-none rounded-lg'>
                  <div className='flex flex-col md:flex-row md:justify-around items-center p-1 py-3'>
                    <div className='flex flex-col justify-center items-center'>
                      <h5 className='text-sm font-medium text-gray-900 dark:text-white truncate'>Nombre des candidats postulés</h5>
                      <p className='my-2 flex flex-row gap-2'>
                        {totalItems}
                      </p>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                      <h5 className='text-sm font-medium text-gray-900 dark:text-white truncate'>Candidats traités</h5>
                      <p className='my-2 flex flex-row gap-2'>
                        {countapplicantstreaties}
                      </p>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                      <h5 className='text-sm font-medium text-gray-900 dark:text-white truncate'>Candidats rejétés</h5>
                      <p className='my-2 flex flex-row gap-2'>
                      {countapplicantstrejected}
                      </p>
                    </div>
                  </div>
              </div> 
              <div className='mt-2 mb-8 w-full bg-gray-100 bg-clip-border px-3 py-4 shadow-md  dark:!bg-navy-700 dark:shadow-none rounded-lg'>
                  <h4 className='px-2 text-xl font-bold text-navy-700 dark:text-white'>
                    Description de l'entreprise
                  </h4>
                  <p 
                    className='mt-2 px-2 text-base text-gray-600'
                    dangerouslySetInnerHTML={createMarkup(offer?.CompanyDescription)}
                  >
                  </p>
              </div> 
              <div className='mt-2 mb-8 w-full bg-gray-100 bg-clip-border px-3 py-4 shadow-md  dark:!bg-navy-700 dark:shadow-none rounded-lg'>
                  <h4 className='px-2 text-xl font-bold text-navy-700 dark:text-white'>
                    Description de l'offre
                  </h4>
                  <p 
                    className='mt-2 px-2 text-base text-gray-600'
                    dangerouslySetInnerHTML={createMarkup(offer?.offerDescription)}
                  >
                  </p>
              </div> 
              <div className='mt-2 mb-8 w-full bg-gray-100 bg-clip-border px-3 py-4 shadow-md  dark:!bg-navy-700 dark:shadow-none rounded-lg'>
                  <h4 className='px-2 text-xl font-bold text-navy-700 dark:text-white'>
                    Missions
                  </h4>
                  <p 
                    className='mt-2 px-2 text-base text-gray-600'
                    dangerouslySetInnerHTML={createMarkup(offer?.mission)}
                  >
                  </p>
              </div> 
            </div>
          </div>
          <div className='relative rounded-[20px] flex flex-col gap-4 w-[100%] mx-auto border bg-white bg-clip-border shadow-2xl dark:!bg-navy-800 dark:text-white dark:!shadow-none p-3'>
            <h4 className='px-2 text-xl font-bold text-center text-navy-700 dark:text-white'>
              Postuler à cette offre
            </h4>
            { actionSuccess && success.data !== 'Tu as déja postulé !' && <Alert Message={ actionSuccess } color={'green'} />}
            { success.data && success.data === 'Tu as déja postulé !' && <Alert Message={ success.data } color={'red'} />}
            <form encType='multipart/form-data' onSubmit={applyapplicant}>
              <div className="grid grid-cols-2 gap-8">
                <div className='flex flex-col rounded-2xl bg-gray-100 bg-clip-border px-3 py-4 shadow-md  dark:!bg-navy-700 dark:shadow-none'>
                  <Input
                      type={'text'}
                      className={'block w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
                      name = {'firstname'}
                      classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
                      placeholder={'Nom'}
                      onChange={handleChangeField}
                  /> 
                  { FirstnameApplicantError && <AlertApplicant Message={ FirstnameApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
                  <Input
                    type={'text'}
                    className={'block w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
                    name = {'lastname'}
                    classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
                    placeholder={'Prénom'}
                    onChange={handleChangeField}
                  />
                  { LastnameApplicantError && <AlertApplicant Message={ LastnameApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
                 <Input
                    type={'email'}
                    className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
                    name = {'email'}
                    classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
                    placeholder={'Email'}
                    onChange={handleChangeField}
                />
                { EmailApplicantError && <AlertApplicant Message={ EmailApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
                </div>
                <div className='flex flex-col justify-center gap-2 rounded-2xl bg-gray-100 bg-clip-border px-3 py-4 shadow-md  dark:!bg-navy-700 dark:shadow-none'>
                  <div className='flex flex-col justify-center gap-2'>
                    <label className='block  text-sm font-bold text-gray-900 dark:text-white'>Cv</label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type='file' 
                      name={'mediaObject'}
                      accept='pdf/*'
                      onChange={handleChangeField}
                    />
                    <span className='text-sm text-center text-gray-500 dark:text-gray-400'>Un seul fichier.</span>
                    <span className='text-sm text-center text-gray-500 dark:text-gray-400'>Types autorisés : pdf,doc</span>
                  </div>
                  { MediaObjectApplicantError && <AlertApplicant Message={ MediaObjectApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
                </div>
              </div>
              <div className='flex items-center justify-center text-primary gap-2'> 
                <div>
                  <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{'Postuler'}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>    
  )
}

export default SingleApplication
