import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/cv-white.png';
import LogoUser from '../../assets/images/user.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetMeSagaActions } from '../../redux/auth/authSagaActions';
import { FiArrowDown, FiX } from 'react-icons/fi';
import SubMenu from './SubMenu';
import { MdOutlineNotifications } from 'react-icons/md';
import { notificationSagaActions } from '../../redux/Notification/NotificationSagaActions';
import moment from 'moment';
import EmptyTable from '../EmptyTable';
import { io } from 'socket.io-client';

export default function NavBar() {
  const [MenuUser , SetMenuUser] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
  user_firstName,
  user_lastName,
  user_roles,
  user_image,
  user_id
  } = useSelector(
    (state) => state.auth
  );
  const {
    notificationList
  } = useSelector(
    (state) => state.notification
  );

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showNotification, setshowNotification] = useState(false);
  const isAdmin = user_roles.includes('ROLE_ADMIN');
  const isManager = user_roles.includes('ROLE_MANAGER');
  const isHeadOf = user_roles.includes('ROLE_HEAD_OF');
  const isHR = user_roles.includes('ROLE_HR');
  const isREFFERANT = user_roles.includes('ROLE_REFFERANT');

  const handleSubMenu = () => {
    setShowSubMenu(!showSubMenu)
  }

  const handleMenuUser = () => {
    SetMenuUser(!MenuUser)
  }

  const handleNotification = () => {
    setshowNotification(!showNotification)
  }

  useEffect(() => {
    if(localStorage.getItem('token'))
    {
      dispatch(GetMeSagaActions.GetMe());
    }
  },[dispatch]);

  function logout() {
    localStorage.removeItem('token');
    setTimeout(() => {
      navigate('/');
    }, 1000);
  }

  let role;
  if (isAdmin) {
    role = 'Administrateur';
  } else if (isManager) {
    role = 'Manager';
  } else if (isHeadOf) {
    role = 'Head Of';
  } else if (isHR) {
    role = 'Chargé de recrutement';
  }else if (isREFFERANT) {
    role = 'Référent';
  } else {
    role = 'Utilisateur';
  }

  useEffect(() => {
    const socket = io(process.env.REACT_APP_NODE_SERVER);
    
    socket.on('notification', (message) => {
       dispatch(notificationSagaActions.getallnotificationswithoutpagination());
    });
  }, [dispatch]);

  function deletenotification(e,item) {
    e.preventDefault();
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      dispatch(notificationSagaActions.deletenotification(item));
    }
  }

  function EditNotification(e,notification_id)
  {
    e.preventDefault();
    dispatch(notificationSagaActions.editnotification({
      id: notification_id,
      isViewed: true,
    }));
  }

  const notification = notificationList?.filter((notification) => notification.isViewed === false)
    .slice(-1).map((notification) => {
      return (
        <div key={`notif-${notification.id}`} className="flex border-b border-gray-200 dark:border-gray-700 p-2">
          <div className="ml-3 text-sm font-normal" onMouseEnter={(e) => EditNotification(e, notification.id)}>
            <span className="mb-1 text-sm font-bold text-gray-900 dark:text-white">
              {notification.title}
            </span>
            <div className="mb-2 text-sm font-medium text-gray-400">
              {notification.description}
            </div>
            <div className="mb-2 text-sm font-normal">
              le {moment(notification.createdAt).format('DD/MM/YYYY H:m:s')}
            </div>
          </div>
          <button
            onClick={(e) => deletenotification(e , notification.id)}
            type="button"
            className="-mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg  focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-fit dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <span className="sr-only">Close</span>
            <FiX />
          </button>
        </div>
      );
  });

  const handleLinkClick = () => {
    window.location.reload();
  };

  return (
    <nav className='fixed top-0 z-50 w-full bg-primary dark:bg-gray-800 dark:border-gray-700'>
      <div className='px-3 py-3 lg:px-5 lg:pl-3'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center justify-start'>
            <div className='flex ml-2 md:mr-24'>
              <Link to='/home' onClick={handleLinkClick}>
                <img src={Logo} alt='' className='cursor-pointer duration-500 w-8' />
              </Link>
              <span className='self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-white'>COSACAREER</span>
            </div>
        </div>
        <div className='flex items-center'>
          <div className='flex items-center ml-3'>
            <div className='relative flex flex-row justify-center items-center gap-8'>
              <div>
                { notificationList?.filter((notification) => notification?.isViewed === false).length > 0 
                 && (
                  <div className='text-white bg-red-500 rounded-full w-5 h-5 flex justify-center items-center text-xs absolute -top-1 right-12'>
                    { notificationList?.filter((notification) => notification.isViewed === false).length }
                  </div>
                )} 
                <MdOutlineNotifications onClick={handleNotification} className='text-white cursor-pointer' />     
              </div>    
              <button type='button' className='flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600' onClick={handleMenuUser}>
                { user_image ?
                <img className='w-8 h-8 rounded-full' src={`${process.env.REACT_APP_API_URL}/media/${user_image}`} alt='' />
                :
                <img className='w-8 h-8 rounded-full' src={LogoUser} alt='' />
                }    
              </button>
            </div>
            {showNotification &&
            <div className="absolute right-2 top-16 w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-400" role="alert">
              <h6>Notifications</h6>
              {notificationList?.filter((notification) => user_id === notification?.sentTo.id)
              .filter((notification) => notification.isViewed === false)
              .length === 0 ?
                <EmptyTable />
                : notification
              } 
            </div>
            }
            {MenuUser &&
            <div className='absolute right-2 top-10 mt-2 w-48 rounded-md shadow-lg bg-white divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600'>
              <div className='px-4 py-3'>
                <p className='text-sm text-gray-900 dark:text-white'>
                  {user_firstName + ' ' + user_lastName}
                </p>                
                <p className='text-sm font-medium text-gray-400 truncate dark:text-gray-300'>
                  {role}
                </p> 
              </div>
              <ul className='py-1'>
                <li>
                  <Link to='/offres' className='flex items-center py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white'>
                    <span className='flex-1 ml-3 whitespace-nowrap'>Offres</span>
                  </Link>
                </li>
                <li>
                  <Link to='/candidats' className='flex items-center py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white'>
                    <span className='flex-1 ml-3 whitespace-nowrap'>Candidats</span>
                  </Link>
                </li>
                <li>
                  <Link to='/statistique' className='flex items-center py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white'>
                    <span className='flex-1 ml-3 whitespace-nowrap'>Statistiques</span>
                  </Link>
                </li>
                <li>
                  <Link  className='flex items-center py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white' onClick={handleSubMenu}>
                    <span className='flex-1 ml-3 whitespace-nowrap'>Paramètres</span>
                    <FiArrowDown />
                  </Link>
                  { showSubMenu ?
                    <SubMenu className='flex items-center justify-center py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white' />
                  :
                  null }
                </li>
                <li>
                  <Link to='/profile' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white' role='menuitem'>Profil</Link>
                </li>
                <li>
                  <button className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600' role='menuitem' onClick={logout}>Déconnexion</button>
                </li>
              </ul>
            </div>
            }
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}