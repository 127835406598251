import axios from "axios";

let api = ({ url, method, data, headers }) => {
  return axios({
    url,
    method,
    data,
    headers
  });
};
export default api;

