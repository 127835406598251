import Select from "react-select";
import makeAnimated from 'react-select/animated';
import Alert from '../Alerts';
import GoBackButton from '../bouttons/GoBackButton';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { expertiseagaActions } from "../../redux/Expertise/ExpertiseSagaActions";

export default function ProfilForm(
{
  onSubmit,
  handleclosemodal,
  modif,
  DataProfil,
  onChange,
  ProfiltError
}
) {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const {
    expertiseList
  } = useSelector(
      (state) => state.expertise
  );

  useEffect(() => {
    dispatch(expertiseagaActions.getallexpertise());
  }, [dispatch]);


  const optionsExpertise = expertiseList.map(expertise => ({
    value: expertise['@id'],
    label: expertise.expertise
  }));

  const handleChangeField = (e) => {
    const { name, value } = e.target;
    onChange(name, value)
  };


  const handleSelect = (name, select) => {
    onChange(name, select.value)
  };

  return (
    <>
    <form className='space-y-6' onSubmit={onSubmit}>  
      { ProfiltError && <Alert Message={ ProfiltError } color={'red'} />}
      <div>
        <label className='block mb-2 text-sm font-medium text-gray-900'>Profil</label>
        <input
          type='text'
          name='profil'
          value={DataProfil.profil}
          className='block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-teal-500 focus:outline-none focus:ring-0 focus:border-teal-500 peer'
          placeholder='titre'
          onChange={handleChangeField}
        />
      </div>
      <div className="grid grid-cols-1 gap-6">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Expertise</label>
        <Select
          options={optionsExpertise}
          components={animatedComponents}
          className="basic-multi-select w-full" 
          onChange={(e) => handleSelect('expertise', e)}
          defaultValue={{ label: DataProfil.expertise.expertise, value: DataProfil.expertise['@id']}}
        />
      </div>
      <div className='flex items-center justify-between text-[#22BABB] py-2 gap-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div>   
      </div>
    </form>
    </>
  )
}
