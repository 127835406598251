import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { usersSagaActions } from '../redux/users/UsersSagaActions';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import GoBackButton from './bouttons/GoBackButton';

function ShareCandidat({
  DataCandidat,
  associatecandidat,
  onChange,
  credentials,
  handleclosemodal
  }) {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const {
    activeusersList
  } = useSelector(
    (state) => state.users
  )

  useEffect(() => {
    dispatch(usersSagaActions.getactiveusers());
  }, [dispatch]);

  let associated = credentials.applicantUsers
  .filter(applicantUsers => applicantUsers.source.includes('Associate_applicant'))
  .map(applicantuser => applicantuser.user);

  const optionsusersassociated = activeusersList
  .map(user =>  ({
    value: user.id,
    label: user.firstname + ' ' + user.lastname,
    email: user.email,
    user_id: user.id
  }))

  const handleSelectMulti = (name, select) => {
    onChange(name, select.map((e) => e))
  };

  return (
      <>
      <div className='flex flex-col'>
        <h3 className='mb-4 text-md font-medium text-gray-900 text-center whitespace-nowrap'>Partager {DataCandidat.firstname+' '+DataCandidat.lastname} avec : </h3>
        <form onSubmit={associatecandidat}>
          <Select
              options={optionsusersassociated}
              components={animatedComponents}
              className="basic-multi-select"
              isMulti
              onChange={(e) => handleSelectMulti('associated', e)} 
              defaultValue={associated.map(associated => ({
                value: associated.id,
                label: associated.fullName,
                email_user: associated.email,
                user_id: associated.id
              }))}
            />
            <div>
              <button className='my-5 py-2 px-2 w-full bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded whitespace-nowrap'>Partager {DataCandidat.firstname+' '+DataCandidat.lastname} avec </button>
            </div>
            <div className='flex justify-center p-2'> 
              <GoBackButton
                className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
                handleclosemodal={handleclosemodal}
                title={'Annuler'}
              />
            </div>   
        </form>
      </div>
      </>
  )
}

export default ShareCandidat
