import React from 'react'
import Logo from '../../assets/images/cv-white.png'

export default function SignInLeftSection({className}) {
  return (
    <>
      <div className={className}>
        <img src={Logo} alt='' className='lg:w-80 lg:h-80  xs:h-32 xs:w-32' /> 
        <p className='text-white font-meduim lg:text-7xl mr-4 xs:text-5xl'>Cosa<span className='font-bold'>Career</span></p>
      </div>
    </>
  )
}
