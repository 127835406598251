import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../components/Pagination';
import Title from '../components/Title';
import AddButton from '../components/bouttons/AddButton';
import NavBar from '../components/template/NavBar';
import SideBar from '../components/template/SideBar';
import { useEffect, useState, useRef } from 'react';
import { sourceSagaActions } from '../redux/source/SourceSagaActions';
import Modal from '../components/forms/Modal';
import Table from '../components/Table';
import EmptyTable from '../components/EmptyTable';
import useClickOutside from '../components/useClickOutside';

function Source() {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [showmodal , setshowmodal] = useState(false);
  const [modif , setModif] = useState(false);
  const [source] = useState('source');
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    totalItems,
    sourceList
  } = useSelector(
      (state) => state.source
  );
  const headers = ['Titre' , 'Activé', 'Actions']

  useEffect(() => {
    dispatch(sourceSagaActions.getallsource(1));
  }, [dispatch]);

  const handleshowmodal = () => {
    setModif(false);
    setSelectedItem(null)
    setshowmodal(!showmodal);
  }

  const handleshowmodalEdit = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodal(!showmodal);
  }

  function onPageChange(currentPage) {
    dispatch(sourceSagaActions.getallsource(currentPage.selected + 1));
  }

  function onCheck(val) {
    const state = val.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} cette source ?`)) {
      const isActive = {...val, isActive : !val.isActive};
      dispatch(sourceSagaActions.editsource({
        id: isActive.id,
        source: isActive.source,
        isActive: isActive.isActive,
        createdAt: isActive.createdAt,
      }
      ));
    }
  }

  const handleClickOutside = (ref) => {
    setshowmodal(false);
  };

  useClickOutside({refs:[modalRef], onClickOutside:handleClickOutside});

  return (
    <>
    <NavBar />
    <SideBar />
    <div className='flex flex-col gap-12 p-4 lg:ml-64 mt-14'>
      <Title 
        title={'Liste des sources'}
        className={'font-bold text-3xl'}
      />
      <AddButton
        onClick={handleshowmodal}
        Name='Ajouter une source'
      />
      <div className='min-h-fit'>
        {showmodal && <Modal refer={modalRef} config={source} modif={modif} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} />}   
          <div className='overflow-x-auto shadow-md sm:rounded-lg'>
          {sourceList.length === 0 ?
            <EmptyTable />
            :
            <Table
              List={sourceList}
              headers={headers}
              classNameTable={'w-full text-sm text-left text-gray-500 dark:text-gray-400'}
              classNamethead={'text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
              classNameth={'px-6 py-3'}
              handleshowmodalEdit={handleshowmodalEdit}
              onCheck={onCheck}
              name={source}
            />
          }
        </div>
      </div> 
      <div className='flex justify-center md:justify-center'>
        <Pagination
          onPage={onPageChange}
          totalItems={totalItems}
          number={10}
        />
      </div>  
    </div>
  </>
  )
}

export default Source
