import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItemsRemunerations: 0,
  remunerationList: [],
  success: false,
  error: null,
  remuneration: null
}

const remunerationSlice = createSlice ({
  name: 'remuneration',
  initialState,
  reducers: {
    getAllremunerationSuccess(state, action)
    {
      state.totalItemsRemunerations = action.payload['hydra:totalItems'];
      state.remunerationList = action.payload['hydra:member'];  
      state.success = true;
    },
    getActiveremunerationSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.activeremunerationList = action.payload['hydra:member'];
    },
    getactiveremunerationFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getAllremunerationFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    addremunerationSuccess(state, action)
    {
      state.remunerationList = [...state.remunerationList, action.payload]
      state.success = 'remuneration added with success !';
      state.error = false;
    },
    addremunerationailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        state.error = 'unauthorized';
      }
      else
      {
        state.error = 'something else happened !';
      }
    },
    editremunerationSuccess(state, action)
    {
      state.success = true;
      state.error = false;
    },
    editremunerationFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    deleteremunerationSuccess(state, action)
    {
      state.success = true;
      state.remunerationList = state.remunerationList.filter((remuneration) => remuneration.id !== action.payload);
    },
    deleteremunerationFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
  }
})

export const {
  getAllremunerationSuccess,
  getAllremunerationFailure,
  addremunerationSuccess,
  addremunerationailure,
  editremunerationSuccess,
  editremunerationFailure,
  deleteremunerationSuccess,
  deleteremunerationFailure,
  } = remunerationSlice.actions;

export default  remunerationSlice.reducer