import { Link } from 'react-router-dom';

export default function SubMenu({className}) {
  return (
    <ul className="py-1 space-y-2">
      <li>
        <Link to='/status'  className={className}>
          <span>Gestion des statuts</span>
        </Link>
      </li>
      <li>
        <Link to='/typecontract'  className={className}>
          <span>Gestion des contrats</span>
        </Link>
      </li>
      <li>
        <Link to='/profil'  className={className}>
          <span>Gestion des profils</span>
        </Link>
      </li>
      <li>
        <Link to='/source'  className={className}>
          <span>Gestion des sources</span>
        </Link>
      </li>
      <li>
        <Link to='/bureau'  className={className}>
          <span>Gestion des bureaux</span>
        </Link>
      </li>
      <li>
        <Link to='/utilisateur'  className={className}>
          <span className='whitespace-nowrap'>Gestion des utilisateurs</span>
        </Link>
      </li>
    </ul>
  )
}

