import Alert from '../Alerts';
import GoBackButton from '../bouttons/GoBackButton';

export default function OfficeForm({
  onSubmit,
  handleclosemodal,
  modif,
  credentials,
  onChange,
  OfficeError
}) 
{
  return (
    <>
    <form className='space-y-6' onSubmit={onSubmit}>  
      { OfficeError ?
        <Alert Message={ OfficeError } color={'red'} />
        : null
      }
      <div>
        <label className='block mb-2 text-sm font-medium text-gray-900'>Office</label>
        <input
          type='text'
          name='office'
          value={credentials.office}
          className='block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-teal-500 focus:outline-none focus:ring-0 focus:border-teal-500 peer'
          placeholder='titre'
          onChange={onChange}
        />
      </div>
      <div className='flex items-center justify-between text-primary gap-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div>   
      </div>
    </form>
    </>
  )
}
