import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  profilList: [],
  activeprofilList: [],
  success: false,
  error: null,
}

const profilSlice = createSlice ({
  name: 'profil',
  initialState,
  reducers: {
    getAllprofilSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.profilList = action.payload['hydra:member'];  
      state.success = true;
    },
    getActiveprofilSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.activeprofilList = action.payload['hydra:member'];
    },
    getactiveprofilFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getAllprofilFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    addprofilSuccess(state)
    {
      state.success = true;
      state.error = false; 
    },
    addprofilailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    editprofilSuccess(state, action)
    {
      state.success = true;
    },
    editprofilFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    }
  }
})

export const {
  getAllprofilSuccess,
  getAllprofilFailure,
  addprofilSuccess,
  addprofilailure,
  editprofilSuccess,
  editprofilFailure,
  getActiveprofilSuccess,
  getactiveprofilFailure
  } = profilSlice.actions;

export default  profilSlice.reducer