export const notificationSagaActions = {
  getallnotifications,
  deletenotification,
  editnotification,
  getallnotificationswithoutpagination
};

function getallnotifications(payload)
{
  return {
    type: 'Getallnotification',
    payload
  }
}

function deletenotification(payload)
{
  return {
    type: 'Deletenotification',
    payload,
  }
}

function editnotification(payload)
{
  return {
    type: 'Editnotification',
    payload,
  }
}  

function getallnotificationswithoutpagination(payload)
{
  return {
    type: 'getallnotificationswithoutpagination',
    payload,
  }
}  

