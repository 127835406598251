import Input from "../Input"
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import GoBackButton from "../bouttons/GoBackButton";
import Alert from "../Alerts";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function ReferenceForm({
  modif,
  handleclosemodal,
  onChange,
  OnSubmit,
  DataReference,
  contactNameError,
  EmailContactError,
  TelephoneContactError,
  CompanyContactError,
  CommentError,
  setNomContactError,
  setEmailContactError,
  setTelephoneContactError,
  setCompanyContactError,
  setCommentError,
}) {
  const animatedComponents = makeAnimated();
  const optionsDecision = [
    {
      label:'Approuvé',
      value: true
    },
    {
      label:'Non Approuvé',
      value: false
    }
  ]

  const handleChangeField = (e) => {
    const { name, value } = e.target;
    if(name === 'contactName')
    {
      setNomContactError('')
    }
    if(name === 'contactEmail')
    {
      setEmailContactError('')
    }
    if(name === 'contactCellphone')
    {
      setTelephoneContactError('')
    }
    if(name === 'contactCompany')
    {
      setCompanyContactError('')
    }
    onChange(name, value)
  };

  const handleSelect = (name, select) => {
    onChange(name, select.value)
  };

  const handleCkEditor = (value, name) => 
  {
    if(name === 'comment')
    {
      setCommentError('')
    }
    onChange(name, value)
  }

  return (
    <form onSubmit={OnSubmit}>
      { contactNameError && <Alert Message={ contactNameError } color={'red'} /> }
      { EmailContactError && <Alert Message={ EmailContactError } color={'red'} /> }
      { TelephoneContactError && <Alert Message={ TelephoneContactError } color={'red'} /> }
      { CompanyContactError && <Alert Message={ CompanyContactError } color={'red'} /> }
      { CommentError && <Alert Message={ CommentError } color={'red'} /> }
      <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'contactName'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Nom du contact'}
          onChange={handleChangeField}
          value={DataReference.contactName}
        />
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'contactEmail'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Email du contact'}
          onChange={handleChangeField}
          value={DataReference.contactEmail}
        />
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'contactCellphone'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Téléphone du contact'}
          onChange={handleChangeField}
          value={DataReference.contactCellphone}
        />
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'contactCompany'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Entreprise du contact'}
          onChange={handleChangeField}
          value={DataReference.contactCompany}
        />
      </div>
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Commentaire</label>
        <CKEditor
          editor={ ClassicEditor }
          data={DataReference.comment}
          onChange={(event, editor) => handleCkEditor(editor.getData(), 'comment')}
        />   
      </div>  
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Decision</label>
        <Select
          options={optionsDecision}
          components={animatedComponents}
          className="basic-multi-select" 
          onChange={(e) => handleSelect('isApproved', e)}
          defaultValue={{
            label: DataReference.isApproved ? 'Approuvé' : 'Non Approuvé',
            value: DataReference.isApproved
          }}

        />   
      </div> 
      <div className='flex items-center justify-between text-primary gap-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div>   
      </div> 
    </form>
  )
}

export default ReferenceForm
