export const referenceSagaActions = {
  getallreferences,
  addreference,
  editreference,
  deletereference,
  filterreference,
};

function getallreferences(payload)
{
  return {
    type: 'Getallreference',
    payload
  }
}

function filterreference(payload)
{
  return {
    type: 'filterreference',
    payload
  }
}

function addreference(payload)
{
  return {
    type: 'Addreference',
    payload,
  }
}  

function editreference(payload)
{
  return {
    type: 'Editreference',
    payload,
  }
}  

function deletereference(payload)
{
  return {
    type: 'Deletereference',
    payload,
  }
}