import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  addsourceSuccess,
  addsourceailure,
  editsourceFailure,
  editsourceSuccess,
  getActivesourceSuccess,
  getAllsourceFailure,
  getAllsourceSuccess,
  getactivesourceFailure
} from './SourceSlice';

const token = localStorage.getItem('token')

function* workergetallsource(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/sources?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllsourceSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllsourceFailure(error.response.status))
  }  
}

function* workergetactivesource(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/sources?isActive=true`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getActivesourceSuccess(res.data));
}
  catch(error)
  {
    yield put(getactivesourceFailure(error.response.status))
  }  
}

function* workeraddsource(action)
{
  try
    {
      const data  = {
        source: action.payload.source,
        isActive: action.payload.isActive,
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/sources`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield put(addsourceSuccess(data));
}
  catch(error)
  {
    yield put(addsourceailure(error.response.status))
  }  
}

function* workereditsource(action)
{
  try
    {
      const data  = {
        id: action.payload.id,
        source: action.payload.source,
        isActive: action.payload.isActive,
        createdAt: action.payload.createdAt,
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/sources/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield put(editsourceSuccess(data));
}
  catch(error)
  {
    yield put(editsourceFailure(error.response.status))
  }
}

export function* watchersource()
{
  yield takeEvery('Getallsource', workergetallsource)
  yield takeEvery('GetActivesource', workergetactivesource)
  yield takeEvery('Addsource', workeraddsource)
  yield takeEvery('Editsource', workereditsource) 
}