export const expertiseagaActions = {
  getallexpertise,
};

function getallexpertise(payload)
{
  return {
    type: 'Getallexpertise',
    payload
  }
}