export const officeSagaActions = {
  getalloffice,
  addoffice,
  editoffice,
  getactiveoffice
};

function getalloffice(payload)
{
  return {
    type: 'Getalloffice',
    payload
  }
}

function getactiveoffice(payload)
{
  return {
    type: 'GetActiveoffice',
    payload
  }
} 

function addoffice(payload)
{
  return {
    type: 'Addoffice',
    payload,
  }
}  

function editoffice(payload)
{
  return {
    type: 'Editoffice',
    payload,
  }
}  