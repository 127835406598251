import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  isAuthenticated: false,
  user_id: '',
  user_email: '',
  user_firstName: '',
  user_lastName: '',
  user_roles: [],
  user_address: '',
  user_cellphone: '',
  user_image: null,
  user: null,
  error: null,
  token: null,
  success: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      localStorage.setItem('token', action.payload.data.token)
      state.token = action.payload.data.token
      state.error = null;
    },
    loginFailure(state, action) {
      state.isAuthenticated = false;
      if(action.payload === 401) {
        state.error = 'Mot de passe incorrect !'
      } else {
        state.error = 'Une erreur est survenue veuillez réessayer ultérieurement'
      }
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    ChangePasswordSuccess(state, action)
        {
          state.status = action.payload;
          state.token = action.payload;
          state.error = null;
        },
    ChangePasswordFailure(state, action)
    {
      state.status = null;
      state.token= null;
      if(action.payload === 404) {
        toast.error('Veuillez vérifier votre mail!', {theme: 'colored'});
      } else {
        toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'colored'});
      }
    },
    ResetSuccess(state)
    {
      state.success = true
    },
    GetMeSuccess(state , action) 
    {
      state.user_id = action.payload.data.id;
      state.user_email = action.payload.data.email;
      state.user_firstName = action.payload.data.firstname;
      state.user_lastName = action.payload.data.lastname;
      state.user_roles = action.payload.data.roles;
      state.user_address = action.payload.data.address;
      state.user_cellphone = action.payload.data.cellphone;
      state.user_image = action.payload.data.mediaObject?.imagePath;
      state.user_positionName = action.payload.data.positionName;
    },
    setIsAuth(state) {
      state.isAuthenticated = false;
    }
  },
})

export const {
  loginSuccess,
  loginFailure,
  setSuccess,
  ChangePasswordSuccess,
  ChangePasswordFailure,
  ResetSuccess,
  GetMeSuccess,
  setIsAuth
} = authSlice.actions;

export default  authSlice.reducer