
import NavBar from '../../components/template/NavBar';
import SideBar from '../../components/template/SideBar';
import Title from '../../components/Title';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { useEffect } from 'react';
import { statusSagaActions } from '../../redux/status/statusSagaAction';
import { CandidatSagaActions } from '../../redux/candidat/CandidatSagaActions';
import { sourceSagaActions } from '../../redux/source/SourceSagaActions';
import { profilSagaActions } from '../../redux/profil/ProfilSagaActions';
import { officeSagaActions } from '../../redux/office/OfficeSagaActions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

function Statistique() {
  const dispatch = useDispatch();
  const {
    activeStatusList
  } = useSelector(
      (state) => state.status
  );
  const {
    candidatList
  } = useSelector(
      (state) => state.candidat
  );
  const {
    activesourceList
  } = useSelector(
      (state) => state.source
  );
  const {
    activeprofilList
  } = useSelector(
      (state) => state.profil
  );
  const {
    activeofficeList
  } = useSelector(
      (state) => state.office
  );

  useEffect(() => {
    dispatch(statusSagaActions.getactivestatus());
    dispatch(CandidatSagaActions.getapplicantswithoutpagination());
    dispatch(sourceSagaActions.getactivesource());
    dispatch(profilSagaActions.getactiveprofil());
    dispatch(officeSagaActions.getactiveoffice());
  }, [dispatch]);

  const getCounts = (list, property) => {
    return list.reduce((counts, item) => {
      const count = candidatList.reduce((acc, candidat) => {
        if (candidat[property][property] === item[property]) {
          return acc + 1;
        }
        return acc;
      }, 0);
  
      return { ...counts, [item[property]]: count };
    }, {});
  };

  function getRandomNumber(min, max) {
    const randomBuffer = new Uint32Array(1);
    window.crypto.getRandomValues(randomBuffer);
    const randomNumber = randomBuffer[0] / (0xffffffff + 1);
    return Math.floor(randomNumber * (max - min + 1)) + min;
  }

  const generateRandomColor = () => {
    const r = getRandomNumber(0, 255);
    const g = getRandomNumber(0, 255);
    const b = getRandomNumber(0, 255);
    return `rgba(${r}, ${g}, ${b}, 0.2)`;
  };

  const generateChartData = (counts, label) => {
    const backgroundColors = Object.values(counts).map(() => generateRandomColor());
    return {
      labels: Object.keys(counts).filter(key => counts[key] > 0),
      datasets: [
        {
          label: label,
          data: Object.values(counts),
          backgroundColor: backgroundColors,
          borderColor:  backgroundColors.map((color) => color.replace('0.2', '1')),
          borderWidth: 1,
        },
      ],
    };
  };

  const statusCounts = getCounts(activeStatusList, 'status');
  const sourceCounts = getCounts(activesourceList, 'source');
  const ProfilCounts = getCounts(activeprofilList, 'profil');
  const officeCounts = getCounts(activeofficeList, 'office');
  
  const dataStatus = generateChartData(statusCounts, 'Nombre des candidats par source');
  const dataSource = generateChartData(sourceCounts, 'Nombre des candidats par source');
  const dataProfil = generateChartData(ProfilCounts, 'Nombre des candidats par profil');
  const dataOffice = generateChartData(officeCounts, 'Nombre des candidats par bureau');

  const posteCounts = candidatList.reduce((counts, candidat) => {
    const poste = candidat.poste;
    if (counts.hasOwnProperty(poste)) {
      counts[poste]++;
    } else {
      counts[poste] = 1;
    }
    return counts;
  }, {});

  const addedApplicants = candidatList.reduce((counts, candidat) => {
    const createdAt = new Date(candidat.createdAt);
    const month = createdAt.getMonth() + 1;
    const year = createdAt.getFullYear();
    const monthYear = `${month}-${year}`;
  
    if (counts.hasOwnProperty(monthYear)) {
      counts[monthYear]++;
    } else {
      counts[monthYear] = 1;
    }
    return counts;
  }, {});

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };
  
  const dataBar = {
    labels: Object.keys(posteCounts),
    datasets: [
      {
        label: 'Poste par candidat',
        data: Object.values(posteCounts),
        backgroundColor: Object.values(posteCounts).map(() => generateRandomColor()),
      },
    ]
  };

  const dataBar2 = {
    labels:Object.keys(addedApplicants),
    datasets: [
      {
        label: 'Poste par candidat',
        data: Object.keys(addedApplicants).map(key => addedApplicants[key]),
        backgroundColor: Object.values(addedApplicants).map(() => generateRandomColor()),
      },
    ],
  };

  return (
  <>
    <NavBar />
    <SideBar />
    <div className='flex flex-col p-4 lg:ml-64 mt-14 gap-4'>
        <Title 
          title={'Statistiques'}
          className={'font-bold text-3xl'}
        />
      <div className="flex flex-col lg:flex lg:flex-row">
        <div className='md:w-1/4'>
          <label className='flex justify-center text-sm font-bold text-gray-900 dark:text-white'>Nombre des candidats par statut</label>
          <Pie data={dataStatus} />
        </div>
        <div className='md:w-1/4'>
          <label className='flex justify-center text-sm font-bold text-gray-900 dark:text-white'>Nombre des candidats par source</label>
          <Pie data={dataSource} />
        </div>
        <div className='md:w-1/4'>
          <label className='flex justify-center text-sm font-bold text-gray-900 dark:text-white'>Nombre des candidats par profil</label>
          <Pie data={dataProfil} />
        </div>
        <div className='md:w-1/4'>
          <label className='flex justify-center text-sm font-bold text-gray-900 dark:text-white'>Nombre des candidats par bureau</label>
          <Pie data={dataOffice} />
        </div>
      </div>
      <div className="flex flex-col lg:flex lg:flex-row">
        <div className='lg:w-1/2'>
          <Bar  options={options} data={dataBar} />
        </div>
        <div className='lg:w-1/2'>
          <Line options={options} data={dataBar2} />
        </div>
      </div>  
    </div>
  </>  
  )
}

export default Statistique
