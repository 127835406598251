import CreateDateRow from "./CreateDateRow";

function HistoryRow({
   list,
  }){ 
  return (
    <>
    <tr key={`history-${list.id}`} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '>
      <td className='px-6 py-4 font-medium truncate'>
        <span style={{backgroundColor: list.preUpdatedStatus?.codecouleur ? list.preUpdatedStatus.codecouleur : 'black' }}  className={'truncate text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'}>
          {list.preUpdatedStatus.status}
        </span>
      </td>
      <td className='px-6 py-4 font-medium truncate'>
        {list.user.fullName}
      </td>
      <td className='px-6 py-4 font-medium truncate'>
        <span style={{backgroundColor: list.postUpdatedStatus?.codecouleur ? list.postUpdatedStatus.codecouleur : 'black' }}  className={'truncate text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'}>
          {list.postUpdatedStatus.status}
        </span>
      </td>
      <CreateDateRow list={list.CreatedAt} />
    </tr>
  </>
  )
}

export default HistoryRow