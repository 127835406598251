import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  addtypecontractSuccess,
  addtypecontractailure,
  edittypecontractFailure,
  edittypecontractSuccess,
  getActivetypecontractSuccess,
  getAlltypecontractFailure,
  getAlltypecontractSuccess,
  getactivetypecontractFailure
} from './TypeContractSlice';

const token = localStorage.getItem('token')

function* workergetalltypecontract(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/type_contracts?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAlltypecontractSuccess(res.data));
}
  catch(error)
  {
    yield put(getAlltypecontractFailure(error.response.status))
  }  
}

function* workergetactivetypecontract(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/type_contracts?isActive=true&&pagination=false`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getActivetypecontractSuccess(res.data));
}
  catch(error)
  {
    yield put(getactivetypecontractFailure(error.response.status))
  }  
}

function* workeraddtypecontract(action)
{
  try
    {
      const data  = {
        typeContract: action.payload.type_contract,
        isActive: action.payload.isActive
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/type_contracts`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield put(addtypecontractSuccess(data));
}
  catch(error)
  {
    yield put(addtypecontractailure(error.response.status))
  }  
}

function* workeredittypecontract(action)
{
  try
    {
      const data  = {
        id: action.payload.id,
        typeContract: action.payload.type_contract,
        isActive: action.payload.isActive,
        createdAt: action.payload.createdAt,
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/type_contracts/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield put(edittypecontractSuccess(data));
}
  catch(error)
  {
    yield put(edittypecontractFailure(error.response.status))
  }
}

export function* watcherTypeContract()
{
  yield takeEvery('Getalltypecontract', workergetalltypecontract)
  yield takeEvery('GetActivetypecontract', workergetactivetypecontract)
  yield takeEvery('Addtypecontract', workeraddtypecontract)
  yield takeEvery('Edittypecontract', workeredittypecontract) 
}