function Applicationminicard({data,titre,classnamespan}) {
  return (
    <div className="flex flex-row items-center rounded-2xl bg-gray-100 bg-clip-border px-3 py-4 shadow-md  dark:!bg-navy-700 dark:shadow-none gap-4">
      <div className="flex flex-col justify-start">
        <p className="text-sm font-bold text-gray-900 dark:text-white truncate">{titre}</p>
        <p className={classnamespan}>
            {data}
        </p>
      </div>
    </div>
  )
}

export default Applicationminicard