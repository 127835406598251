import Select from "react-select";
import makeAnimated from 'react-select/animated';
import GoBackButton from "../bouttons/GoBackButton";
import Alert from "../Alerts";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function FeedbackForm({
  modif,
  handleclosemodal,
  onChange,
  DataFeedback,
  onSubmit,
  CommentError,
  setCommentError,
  EnglishLevelError,
  setEnglishLevelError,
  AvaibilityError,
  setAvaibilityError
}) {
  const animatedComponents = makeAnimated();
  const optionsLevelEnglish = [
    {
      label:'A1',
      value:'A1'
    },
    {
      label:'A2',
      value:'A2'
    }
    ,
    {
      label:'B1',
      value:'B1'
    }
    ,
    {
      label:'B2',
      value:'B2'
    }
    ,
    {
      label:'C1',
      value:'C1'
    },
    {
      label:'C2',
      value:'C2'
    }
  ]
  const optionsAvaibility = [
    {
      label:'Immédiatement',
      value:'Immédiatement'
    },
    {
      label:'Aprés 3 mois',
      value:'Aprés 3 mois'
    },
    {
      label:'Aprés 6 mois',
      value:'Aprés 6 mois'
    }
  ]
  const optionsDecision = [
    {
      label:'Approuvé',
      value:true
    },
    {
      label:'Non Approuvé',
      value: false
    }
  ]

  const handleSelect = (name, select) => {
    if(name === 'englishLevel' )
    {
      setEnglishLevelError('')
    }
    if(name === 'availability' )
    {
      setAvaibilityError('')
    }
    onChange(name, select.value)
  };

  const handleCkEditor = (value, name) => 
  {
    if(name === 'comment')
    {
      setCommentError('')
    }
    onChange(name , value)
  }

  return (
  <form onSubmit={onSubmit}>
    { EnglishLevelError && <Alert Message={ EnglishLevelError } color={'red'} /> }
    { AvaibilityError && <Alert Message={ AvaibilityError } color={'red'} /> }
    { CommentError && <Alert Message={ CommentError } color={'red'} /> }
    <div className="grid grid-cols-1 gap-6">
      <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Niveau anglais</label>
      <Select
        options={optionsLevelEnglish}
        components={animatedComponents}
        className="basic-multi-select w-full" 
        onChange={(e) => handleSelect('englishLevel', e)}
        defaultValue={{ label: DataFeedback.englishLevel, value: DataFeedback.englishLevel }}
      />
    </div>
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Disponibilité</label>
      <Select
        options={optionsAvaibility}
        components={animatedComponents}
        className="basic-multi-select" 
        onChange={(e) => handleSelect('availability', e)}
        defaultValue={{ label: DataFeedback.availability, value: DataFeedback.availability }}
      />    
    </div>  
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Commentaire</label>
      <CKEditor
        editor={ ClassicEditor }
        data={DataFeedback.comment}
        onChange={(event, editor) => handleCkEditor(editor.getData(), 'comment')}
      />   
    </div>  
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Decision</label>
      <Select
        options={optionsDecision}
        components={animatedComponents}
        className="basic-multi-select" 
        onChange={(e) => handleSelect('isApproved', e)}
        defaultValue={{
          label: DataFeedback.isApproved ? 'Approuvé' : 'Non Approuvé',
          value: DataFeedback.isApproved ? true : false
        }}
      />   
    </div> 
    <div className='flex items-center justify-between text-primary gap-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div>   
      </div> 
  </div>
  </form>
  )
}

export default FeedbackForm
