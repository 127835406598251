

function ValidationsDataApplications(data) {
  const errors = [];

  if (data.titleOffer.length === 0) {
    errors.push("Champ titre de l'offre est obligatoire!");
  }
  if (data.CompanyDescription.length === 0) {
    errors.push("Champ description de l'entreprise est obligatoire!");
  }
  if (data.offerDescription.length === 0) {
    errors.push("Champ description de l'offre est obligatoire!");
  }
  if (data.experience.length === 0) {
    errors.push('Champ expériences est obligatoire!');
  }
  if (data.languages.length === 0) {
    errors.push('Champ langues est obligatoire!');
  }
  if (data.levelStudy.length === 0) {
    errors.push("Champ niveau d'étude est obligatoire!");
  }
  if (data.skillsRequired.length === 0) {
    errors.push('Champ compétences requis est obligatoire!');
  }
  if (data.desiredStartDate === null) {
    errors.push('Champ date de début souhaitée est obligatoire!');
  }
  if (data.mission.length === 0) {
    errors.push('Champ mission est obligatoire!');
  }
  if (data.schedule.length === 0) {
    errors.push('Champ horaire est obligatoire!');
  }
  if (data.location?.length === 0) {
    errors.push('Champ bureau est obligatoire!');
  }
  if (data.typeContract?.length === 0) {
    errors.push("Champ Type d'emploi désiré est obligatoire!");
  }

  return errors;
}

export default ValidationsDataApplications
