export const authSagaActions = {
  login
};

export const ChangePasswordSagaActions = {
  ChangePassword
};

export const ResetSagaActions = {
  ResetPassword
};

export const GetMeSagaActions = {
  GetMe
};

function login(payload)
{
  return {
    type: 'LOGIN',
    payload
  }
}

function ChangePassword(payload)
{
  return {
    type: 'ChangePassword',
    payload
  }
}

function ResetPassword(payload)
{
  return {
    type: 'RESET',
    payload
  }
}

function GetMe()
{
  return {
    type: 'GETME',
  }
}