import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  getAllpowerbiFailure,
  getAllpowerbiSuccess,
} from './PowerbiSlice';

function* workerpowerbi(action)
{
  try
    {
    const tokenResponse = yield call(() => api({
      url: `${process.env.REACT_APP_API_URL}/sign-in-azure`,
      method: 'GET',
    }
    ))
    const azure_token = JSON.parse(tokenResponse.data).access_token;
    const res = yield call(()=> api({
      url: `https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_GROUP_ID}/datasets/${process.env.REACT_APP_DATASET_ID}/refreshes`,
      method: 'POST',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${azure_token}`,
    },
  }
))
  yield put(getAllpowerbiSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllpowerbiFailure(error))
  }  
}

export function* watcherpowerbi()
{
  yield takeEvery('refreshDataset', workerpowerbi)
}