import { useEffect } from "react";

function useClickOutside({
  refs,
  onClickOutside
 }) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const modalBackground = document.querySelector('.modal-background');
      for (const ref of refs) {
        if (ref.current && ref.current.contains(event.target) && event.target === modalBackground) {
          onClickOutside(ref);
          break;
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [refs, onClickOutside]);
}

export default useClickOutside
