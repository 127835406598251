import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  addfeedbackSuccess,
  addfeedbackailure,
  deletefeedbackFailure,
  deletefeedbackSuccess,
  editfeedbackFailure,
  editfeedbackSuccess,
  getAllfeedbackFailure,
  getAllfeedbackSuccess,
} from './FeedbackSlice';

const token =  localStorage.getItem('token');

function* workergetallfeedback(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/feedback?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
  }
))
  yield put(getAllfeedbackSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllfeedbackFailure(error.response.status))
  }  
}

function* workeraddfeedback(action)
{
  try
    {
      const data  = {
        availability: action.payload.availability,
        englishLevel: action.payload.englishLevel,
        comment: action.payload.comment,
        isApproved: action.payload.isApproved,
        applicant: action.payload.applicant
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/feedback`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetallfeedback({payload : 1});  
  yield put(addfeedbackSuccess(data));
}
  catch(error)
  {
    yield put(addfeedbackailure(error.response.status))
  }  
}

function* workereditfeedback(action)
{
  try
    {
      const data  = {
        id: action.payload.id,
        availability : action.payload.availability,
        englishLevel: action.payload.englishLevel,
        comment: action.payload.comment,
        isApproved: action.payload.isApproved,
        applicant: action.payload.applicant
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/feedback/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetallfeedback({payload : 1});  
  yield put(editfeedbackSuccess(data));
}
  catch(error)
  {
    yield put(editfeedbackFailure(error.response.status))
  }
}

function* workerdeletefeedback(action)
{ try
    {
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/feedback/${action.payload}`,
      method: 'DELETE',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))

yield workergetallfeedback({payload : 1});
yield put(deletefeedbackSuccess());

}
  catch(error)
  {
    yield put(deletefeedbackFailure(error))
  }  
}

export function* watcherfeedback()
{
  yield takeEvery('Getallfeedback', workergetallfeedback)
  yield takeEvery('Addfeedback', workeraddfeedback)
  yield takeEvery('Deletefeedback', workerdeletefeedback)
  yield takeEvery('Editfeedback', workereditfeedback) 
}