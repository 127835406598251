import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { statusSagaActions } from '../../redux/status/statusSagaAction';
import Alert from '../Alerts';
import StatutForm from './StatutForm';
import FormTitle from './FormTitle';
import TypeContractForm from './TypeContractForm';
import { typecontractSagaActions } from '../../redux/typecontract/TypecontractSagaAction';
import ProfilForm from './ProfilForm';
import { profilSagaActions } from '../../redux/profil/ProfilSagaActions';
import SourceForm from './SourceForm';
import { sourceSagaActions } from '../../redux/source/SourceSagaActions';
import { officeSagaActions } from '../../redux/office/OfficeSagaActions';
import UserForm from './UserForm';
import OfficeForm from './OfficeForm';
import { usersSagaActions } from '../../redux/users/UsersSagaActions';
import UserHook from '../../hooks/UserHook';
import UploadImageUserForm from './UploadImageUserForm';
import CandidatForm from './CandidatForm';
import CandidatHook from '../../hooks/CandidatHook';
import { CandidatSagaActions } from '../../redux/candidat/CandidatSagaActions';
import ValidationsDataCandidat from './ValidationsDataCandidat';
import AcceptRefuseCandidat from '../AcceptRefuseCandidat';
import { ApplicantUserSagaActions } from '../../redux/ApplicantUser/ApplicantUserSagaActions';
import ShareCandidat from '../ShareCandidat';
import FeedbackForm from './FeedbackForm';
import FeedBackHook from '../../hooks/FeedBackHook';
import { FeedBackSagaActions } from '../../redux/Feedback/FeedbackSagaActions';
import ReferenceHook from '../../hooks/ReferenceHook';
import ReferenceForm from './ReferenceForm';
import { referenceSagaActions } from '../../redux/Reference/ReferenceSagaActions';
import RemunerationHook from '../../hooks/RemunerationHook';
import RemunerationForm from './RemunerationForm';
import { remunerationSagaActions } from '../../redux/Remuneration/RemunerationSagaActions';
import ValidationsDataReference from './ValidationsDataReference';
import ValidationsDataFeedback from './ValidationsDataFeedback';
import ValidationDataRemuneration from './ValidationsDataRemuneration';
import ProfilHook from '../../hooks/ProfilHook';
import '../../assets/sass/modal.scss';
import OfferForm from './OfferForm';
import OfferHook from '../../hooks/Offer';
import { offerSagaActions } from '../../redux/Offers/OffersSagaActions';
import ValidationsDataOffers from './ValidationDataOffer';

export default function Modal({
  config,
  modif,
  selectedItem,
  id_applicant,
  showmodal,
  setshowmodal,
  showmodalFeedback,
  setshowmodalFeedback,
  showmodalReference,
  setshowmodalReference,
  showmodalRemuneration,
  setshowmodalRemuneration,
  isOpenShareCandidat,
  isOpenAcceptRefuse,
  isCandidat,
  setIsCandidat,
  setIsOpenAcceptRefuse,
  setIsOpenShareCandidat,
  setisUser,
  isUser,
  showmodalUpload,
  setshowmodalUpload,
  refer,
}) {
  const dispatch = useDispatch();
  const [statusError , setStatutError] = useState('');
  const [TypeContractError , setTypeContractError] = useState('');
  const [ProfilError , setProfilError] = useState('');
  const [SourceError , setSourceError] = useState('');
  const [OfficeError , setOfficeError] = useState('');
  const [codecouleurError , setCodeCouleurError] = useState('');
  const [FirstnameApplicantError , setFirstnameApplicantError ] = useState('');
  const [LastnameApplicantError , setLastnameApplicantError ] = useState('');
  const [EmailApplicantError , setEmailApplicantError ] = useState('');
  const [CellphoneApplicantError , setCellphoneApplicantError ] = useState('');
  const [PosteApplicantError , setPosteApplicantError ] = useState('');
  const [StatusApplicantError , setStatusApplicantError ] = useState('');
  const [OfficeApplicantError , setOfficeApplicantError ] = useState('');
  const [ProfilApplicantError , setProfilApplicantError ] = useState('');
  const [TypeContractApplicantError , setTypeContractApplicantError ] = useState('');
  const [SourceApplicantError , setSourceApplicantError ] = useState('');
  const [NomContactError , setNomContactError ] = useState('');
  const [EmailContactError , setEmailContactError ] = useState('');
  const [TelephoneContactError , setTelephoneContactError ] = useState('');
  const [CompanyContactError , setCompanyContactError ] = useState('');
  const [CommentError , setCommentError ] = useState('');
  const [EnglishLevelError , setEnglishLevelError ] = useState('');
  const [RemunerationActualFixError , setRemunerationActualFixError] = useState('');
  const [CurrentVariableError , setCurrentVariableError] = useState('');
  const [RemunerationDesiredFixError , setremunerationDesiredFixError] = useState('');
  const [DesiredVariableError , setDesiredVariableError] = useState('');
  const [AvaibilityError , setAvaibilityError] = useState('');
  const [MediaObjectApplicantError , setMediaObjectApplicantError ] = useState('');
  const [mediaObjectError , setmediaObjectError] = useState('');
  const [actionSuccess , setactionSuccess] = useState('');
  const [DataUser, setDataUser] = UserHook({selectedItem});
  const [DataCandidat, setDataCandidat] = CandidatHook({selectedItem});
  const [DataFeedback, setDataFeedback] = FeedBackHook({selectedItem});
  const [DataReference, setDataReference] = ReferenceHook({selectedItem});
  const [DataRemuneration, setDataRemuneration] = RemunerationHook({selectedItem});
  const [DataOffer, setDataOffer] = OfferHook({selectedItem});
  const [DataProfil, setDataProfil] = ProfilHook({selectedItem});
  const errors = ValidationsDataCandidat(DataCandidat);
  const errorsReference = ValidationsDataReference(DataReference);
  const errorsFeedback = ValidationsDataFeedback(DataFeedback);
  const errorsRemuneration = ValidationDataRemuneration(DataRemuneration);
  const errorsOffers = ValidationsDataOffers(DataOffer);
  const [titleOfferError,settitleOfferError] = useState('');
  const [CompanyDescriptionError,setCompanyDescriptionError] = useState('');
  const [OfferDescriptionError,setOfferDescriptionError] = useState('');
  const [experienceError,setexperienceError] = useState('');
  const [languagesError,setlanguagesError] = useState('');
  const [levelStudyError,setlevelStudyError] = useState('');
  const [skillsRequiredError,setskillsRequiredError] = useState('');
  const [remunerationOfferError,setremunerationOfferError] = useState('');
  const [OfferDeadlineError,setOfferDeadlineError] = useState('');
  const [desiredStartDateError,setdesiredStartDateError] = useState('');
  const [missionError,setmissionError] = useState('');
  const [scheduleError,setscheduleError] = useState('');
  const [locationError,setlocationError] = useState('');
  const [typeContractError,settypeContractError] = useState('');
  const [color, setColor] = useState(selectedItem ? selectedItem.codecouleur : '#ffffff');
  const [credentials, setCredentials] = useState({
    id: selectedItem ? selectedItem.id : null,
    isActive: selectedItem ? selectedItem.isActive: true,
    createdAt : selectedItem ? selectedItem.createdAt: null,
    status: selectedItem ? selectedItem.status : '',
    typecontract: selectedItem ? selectedItem.typeContract : '',
    source: selectedItem ? selectedItem.source : '',
    office: selectedItem ? selectedItem.office : '',
    mediaObject: selectedItem ? selectedItem.mediaObject : '',
  });
  const {
    error,
  } = useSelector(
      (state) => state.offer
  );

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "mediaObject") {
      setCredentials({ ...credentials, [name]: files[0] });
    }
    else
    {
      setCredentials({ ...credentials, [name]: value });
    }
  };

  const handleChangeUser = (name, value) => {
    setDataUser({ ...DataUser, [name]: value});
  }

  const handleChangeCandidat = (name, value) => {
    setDataCandidat({ ...DataCandidat, [name]: value});
  }

  const handleChangeFeedback = (name, value) => {
    setDataFeedback({ ...DataFeedback, [name]: value});
  }

  const handleChangeReference = (name, value) => {
    setDataReference({ ...DataReference, [name]: value});
  }

  const handleChangeRemuneration = (name, value) => {
    setDataRemuneration({ ...DataRemuneration, [name]: value});
  }

  const handleChangeProfil = (name, value) => {
    setDataProfil({ ...DataProfil, [name]: value});
  }

  const handleChangeOffer = (name, value) => {
    setDataOffer({ ...DataOffer, [name]: value});
  }

  function handleColorChangeComplete(selectedColor) {
    setColor(selectedColor.hex);
  }

  const handleclosemodal = () => {
    setshowmodal(!showmodal)
    if(config === 'candidat')
    {
      setIsCandidat(!isCandidat)
    }
    if(config === 'users')
    {
      setisUser(!isUser)
    }
    if(config === 'users')
    {
      setisUser(!isUser)
    }
  }

  const handleclosemodalShareCandidat = () => {
    setshowmodal(!showmodal)
    setIsOpenShareCandidat(!isOpenShareCandidat)
  }

  const handleclosemodalAcceptRefuseCandidat = () => {
    setshowmodal(!showmodal)
    setIsOpenAcceptRefuse(!isOpenAcceptRefuse)
  }

  const handleclosemodalFeedback = () => {
    setshowmodal(!showmodal)
    setshowmodalFeedback(!showmodalFeedback)
  }

  const handleclosemodalReference = () => {
    setshowmodal(!showmodal)
    setshowmodalReference(!showmodalReference)
  }

  const handleclosemodalRemuneration = () => {
    setshowmodal(!showmodal)
    setshowmodalRemuneration(!showmodalRemuneration)
  }

  const handleclosemodalUpload = () => {
    setshowmodalUpload(!showmodalUpload)
    setshowmodal(!showmodal)
  }

  function TimeoutAfterAction() {
    setTimeout(() => {
      setshowmodal(!showmodal);
    }, 1000);
  }

  function addstatut(e) {
    e.preventDefault();
    if ((credentials.status.length === 0) || (color.length === 0)) {
      if (credentials.statuslength === 0) 
      {
        setStatutError('Champ statut est obligatoire!')
      }
      if (color.length === 0)
      {
        setCodeCouleurError('Champ couleur est obligatoire!')
      }
    }    
    else
    {
      if(modif)
      {
        dispatch(statusSagaActions.editstatus({
          id: credentials.id,
          status: credentials.status,
          isActive: credentials.isActive,
          createdAt: credentials.createdAt
        },
        color))
        setactionSuccess('Statut modifié avec succès!')
      }
      else
      {
        dispatch(statusSagaActions.addstatus({
          status: credentials.status,
          isActive: credentials.isActive,    
        },
        color,))
        setactionSuccess('Statut ajouté avec succès!');
      }
      TimeoutAfterAction();
    }
  }

  function addTypeContract(e) {
    e.preventDefault();
    if ((credentials.typecontract.length === 0)) {
      setTypeContractError('Champ type de contrat est obligatoire!')
    }    
    else
    {
      if(modif)
      {
        dispatch(typecontractSagaActions.edittypecontract({
          id: credentials.id,
          type_contract: credentials.typecontract,
          isActive: credentials.isActive,
          createdAt: credentials.createdAt
        }));
        setactionSuccess('Type de contrat modifié avec succès!');
      }
      else
      {
        dispatch(typecontractSagaActions.addtypecontract({
          type_contract : credentials.typecontract,
          isActive: credentials.isActive
        }));
        setactionSuccess('Type de contrat ajouté avec succès!');
      }
      TimeoutAfterAction();
    }
  }

  function addprofil(e) {
    e.preventDefault();
    if ((DataProfil.profil.length === 0)) {
      setProfilError('Champ profil est obligatoire!')
    }    
    else
    {
      if(modif)
      {
        dispatch(profilSagaActions.editprofil({
          id: DataProfil.id,
          profil: DataProfil.profil,
          expertise: DataProfil.expertise,
          isActive: DataProfil.isActive,
        }));
        setactionSuccess('Profil modifié avec succès!');
      }
      else
      {
        dispatch(profilSagaActions.addprofil({
          profil: DataProfil.profil,
          expertise: DataProfil.expertise,
          isActive: true,
        }));
        setactionSuccess('Profil ajouté avec succès!');
      }
      TimeoutAfterAction();
    }
  }

  function addsource(e) {
    e.preventDefault();
    if ((credentials.source.length === 0)) {
      setSourceError('Champ source est obligatoire!')
    }    
    else
    {
      if(modif)
      {
        dispatch(sourceSagaActions.editsource({
          id: credentials.id,
          source: credentials.source,
          isActive: credentials.isActive,
          createdAt: credentials.createdAt
        }));
        setactionSuccess('Source modifié avec succès!');
      }
      else
      {
        dispatch(sourceSagaActions.addsource({
          source: credentials.source,
          isActive: credentials.isActive,
        }));
        setactionSuccess('Source ajouté avec succès!');
      }
      TimeoutAfterAction();
    }
  }

  function addoffice(e) {
    e.preventDefault();
    if ((credentials.office.length === 0)) {
      setOfficeError('Champ bureau est obligatoire!')
    }    
    else
    {
      if(modif)
      {
        dispatch(officeSagaActions.editoffice({
          id: credentials.id,
          office: credentials.office,
          isActive: credentials.isActive,
          createdAt: credentials.createdAt
        }));
        setactionSuccess('Bureau modifié avec succès!');
      }
      else
      {
        dispatch(officeSagaActions.addoffice({
          office: credentials.office,
          isActive: credentials.isActive,
        }));
        setactionSuccess('Bureau ajouté avec succès!');
      }
      TimeoutAfterAction();
    }
  }

  function modifuser(e) {
    e.preventDefault();
    if(modif)
    {
      dispatch(usersSagaActions.editusers({
        id: DataUser.id,
        firstname: DataUser.firstname,
        lastname: DataUser.lastname,
        username: DataUser.username,
        password: DataUser.password,
        roles: DataUser.roles,
        email: DataUser.email,
        address: DataUser.address,
        cellphone: DataUser.cellphone,
        isActive : DataUser.isActive,
        createdAt: DataUser.createdAt
      }));
      setactionSuccess('Utilisateur modifié avec succès!');
      setTimeout(() => {
        setshowmodal(!showmodal);
        setisUser(!isUser)
      }, 1000);
    }
  };

  function addcandidat(e) {
    e.preventDefault();
    if (errors.length > 0) {
      errors.forEach((error) => {
        switch (error) {
          case 'Champ Nom est obligatoire!':
            setFirstnameApplicantError(error);
            break;
          case 'Champ Prénom est obligatoire!':
            setLastnameApplicantError(error);
            break;
          case 'Champ Email est obligatoire!':
            setEmailApplicantError(error);
            break;
          case 'Champ Téléphone est obligatoire!':
            setCellphoneApplicantError(error);
            break;
          case 'Champ Poste est obligatoire!':
            setPosteApplicantError(error);
            break;
          case 'Champ Statut est obligatoire!':
            setStatusApplicantError(error);
            break;
          case 'Champ Bureau est obligatoire!':
            setOfficeApplicantError(error);
            break;
          case 'Champ Profile est obligatoire!':
            setProfilApplicantError(error);
            break;
          case 'Champ Type de contrat est obligatoire!':
            setTypeContractApplicantError(error);
            break;
          case 'Champ source est obligatoire!':
            setSourceApplicantError(error);
            break;
          case 'Champ media est obligatoire!':
            setMediaObjectApplicantError(error);
            break;
          default:
            break;
        }
      });
    }
    else {
      const actionPayload = {
          firstname: DataCandidat.firstname,
          lastname: DataCandidat.lastname,
          email: DataCandidat.email,
          linkedin: DataCandidat.linkedin,
          cellphone: DataCandidat.cellphone,
          poste: DataCandidat.poste,
          office: DataCandidat.office,
          profil: DataCandidat.profil,
          typecontract: DataCandidat.typecontract,
          source: DataCandidat.source,
          status: DataCandidat.status,
          mediaObject: DataCandidat.mediaObject,
      };
      if(modif)
      {
        DataCandidat.applicantUsers = DataCandidat.Directeur.concat(DataCandidat.Rh).concat(DataCandidat.Refferant);
        const parsedApplicantsUser = DataCandidat.applicantUsers.map(str => JSON.parse(str));
        actionPayload.id = DataUser.id
        actionPayload.applicantUsers = parsedApplicantsUser
        console.log(actionPayload);
        dispatch(CandidatSagaActions.editcandidat(actionPayload)
        );
      }
      else
      {
        DataCandidat.applicantUsers = DataCandidat.Directeur.concat(DataCandidat.Rh);
        const parsedApplicantsUser = DataCandidat.applicantUsers.map(str => JSON.parse(str)); 
        actionPayload.applicantUsers = parsedApplicantsUser
        dispatch(CandidatSagaActions.addcandidat(actionPayload));
      }
      setactionSuccess('Candidat ajouté avec succès!');
      setTimeout(() => {
        setshowmodal(!showmodal);
        setIsCandidat(!isCandidat)
      }, 1000);
    }
  }

  function uploadimageuser(e) {
    e.preventDefault();
    if (!credentials.mediaObject) {
      setmediaObjectError('Champ image est obligatoire!')
    }    
    else
    {
      if(modif)
      { 
        dispatch(usersSagaActions.uploadImageUser({
          id: credentials.id,
          mediaObject : credentials.mediaObject
        }));
        setactionSuccess('Image utilisateur modifié avec succès!');
        setTimeout(() => {
          setshowmodal(!showmodal);
          setshowmodalUpload(!showmodalUpload)
        }, 1000);
      }
    }
  }

  function acceptcandidat(e){
    e.preventDefault();
    dispatch(CandidatSagaActions.acceptrefusecandidat({
      email: DataCandidat.email,
      decision: true
    }));
    setactionSuccess('Candidat accepté !');
    setTimeout(() => {
      setshowmodal(!showmodal);
      setIsOpenAcceptRefuse(!isOpenAcceptRefuse)
    }, 1000);
  }

  function refusercandidat(e){
    e.preventDefault();
    dispatch(CandidatSagaActions.acceptrefusecandidat({
      email: DataCandidat.email,
      decision: false
    }));
    setactionSuccess('Candidat refusé !');
    setTimeout(() => {
      setshowmodal(!showmodal);
      setIsOpenAcceptRefuse(!isOpenAcceptRefuse)
    }, 1000);
  }

  function associatecandidat(e){
    e.preventDefault();
    dispatch(ApplicantUserSagaActions.addApplicantUser({
      source: 'Associate_applicant',
      applicant_id: selectedItem.id,
      associated: DataCandidat.associated,
    }));
    dispatch(ApplicantUserSagaActions.sendemailApplicantUser({
      email_applicant: selectedItem.email,
      emails: DataCandidat.associated,
    }));
    setactionSuccess('Candidat a été partagé avec succès!');
    setTimeout(() => {
      setshowmodal(!showmodal);
      setIsOpenShareCandidat(!isOpenShareCandidat)
    }, 1000);
  };

  function addfeedback(e) {
    e.preventDefault();
    if (errorsFeedback.length > 0) {
      errorsFeedback.forEach((error) => {
        switch (error) {
          case 'Champ niveau anglais est obligatoire!':
            setEnglishLevelError(error);
            break;
          case 'Champ disponibilité est obligatoire!':
            setAvaibilityError(error);
            break;
          case 'Champ comment est obligatoire!':
            setCommentError(error);
            break;
          default:
            break;
        }
      });
    }
    else
    {
      if(modif)
      {
        dispatch(FeedBackSagaActions.editfeedback({
          id: DataFeedback.id,
          availability : DataFeedback.availability,
          englishLevel: DataFeedback.englishLevel,
          comment: DataFeedback.comment,
          isApproved: DataFeedback.isApproved,
          applicant: id_applicant
        }));
        setactionSuccess('Feedback modifié avec succès!');
      }
      else
        { 
          dispatch(FeedBackSagaActions.addfeedback({
            availability : DataFeedback.availability,
            englishLevel: DataFeedback.englishLevel,
            comment: DataFeedback.comment,
            isApproved: DataFeedback.isApproved,
            applicant: id_applicant
          }));
        setactionSuccess('Feedback ajouté avec succès!');
      }
      setTimeout(() => {
        setshowmodal(!showmodal);
        setshowmodalFeedback(!showmodalFeedback)
      }, 1000);
    }
  }

  function addreference(e) {
    e.preventDefault();
    if (errorsReference.length > 0) {
      errorsReference.forEach((error) => {
        switch (error) {
          case 'hamp nom du contact est obligatoire!':
            setNomContactError(error);
            break;
          case 'Champ email du contact est obligatoire!':
            setEmailContactError(error);
            break;
          case 'Champ téléphone du contact est obligatoire!':
            setTelephoneContactError(error);
            break;
          case 'Champ entreprise du contact est obligatoire!':
            setCompanyContactError(error);
            break;
          case 'Champ commentaire est obligatoire!':
            setCommentError(error);
            break;
          default:
            break;
        }
      });
    }
    else 
    {
      if(modif)
      {
        dispatch(referenceSagaActions.editreference({
          id: DataReference.id,
          contactName : DataReference.contactName,
          contactEmail:  DataReference.contactEmail,
          contactCellphone:  DataReference.contactCellphone,
          contactCompany:  DataReference.contactCompany,
          comment: DataReference.comment,
          isApproved: DataReference.isApproved,
          applicant: id_applicant
        }));
        setactionSuccess('Référence modifié avec succès!');
      }
      else
        { 
         dispatch(referenceSagaActions.addreference({
          contactName : DataReference.contactName,
          contactEmail:  DataReference.contactEmail,
          contactCellphone:  DataReference.contactCellphone,
          contactCompany:  DataReference.contactCompany,
          comment: DataReference.comment,
          isApproved:  DataReference.isApproved,
          applicant: id_applicant
        }));
        setactionSuccess('Référence ajouté avec succès!');
      }
      setTimeout(() => {
        setshowmodal(!showmodal);
        setshowmodalReference(!showmodalReference)
      }, 1000);
    }
  }

  function addremuneration(e) {
    e.preventDefault();
    if (errorsRemuneration.length > 0) {
      errorsRemuneration.forEach((error) => {
        switch (error) {
          case 'Champ rénumération actuelle désirée est obligatoire!':
            setRemunerationActualFixError(error);
            break;
          case 'Champ variable actuelle du contact est obligatoire!':
            setCurrentVariableError(error);
            break;
          case 'Champ variable désirée du contact est obligatoire!':
            setDesiredVariableError(error);
            break;
          case 'Champ rénumération actuelle désirée du contact est obligatoire!':
            setremunerationDesiredFixError(error);
              break;  
          case 'Champ commentaire est obligatoire!':
            setCommentError(error);
            break;      
          default:
            break;
        }
      });
    }
    else
    {
      if(modif)
      {
        dispatch(remunerationSagaActions.editremuneration({
          id: DataReference.id,
          remunerationActualFix : parseFloat(DataRemuneration.remunerationActualFix),
          currentVariable: parseFloat(DataRemuneration.currentVariable),
          remunerationDesiredFix: parseFloat(DataRemuneration.remunerationDesiredFix),
          desiredVariable:  parseFloat(DataRemuneration.desiredVariable),
          comment: DataRemuneration.comment,
          applicant: id_applicant
        }));
        setactionSuccess('Rémunération modifié avec succès!');
      }
      else
        {
        dispatch(remunerationSagaActions.addremuneration({
          remunerationActualFix : parseFloat(DataRemuneration.remunerationActualFix),
          currentVariable: parseFloat(DataRemuneration.currentVariable),
          remunerationDesiredFix: parseFloat(DataRemuneration.remunerationDesiredFix),
          desiredVariable:  parseFloat(DataRemuneration.desiredVariable),
          comment: DataRemuneration.comment,
          applicant: id_applicant
        }));
        setactionSuccess('Rémunération ajouté avec succès!');
      }
      setTimeout(() => {
        setshowmodal(!showmodal);
        setshowmodalRemuneration(!showmodalRemuneration)
      }, 1000);
    }
  }

  function addOffer(e) {
    e.preventDefault();
    if (errorsOffers.length > 0) {
      errorsOffers.forEach((error) => {
        switch (error) {
          case "Champ titre de l'offre est obligatoire!":
            settitleOfferError(error);
            break;
          case "Champ description de l'entreprise est obligatoire!":
            setCompanyDescriptionError(error);
            break;
          case "Champ description de l'offre est obligatoire!":
            setOfferDescriptionError(error);
            break;
          case 'Champ expériences est obligatoire!':
            setexperienceError(error);
            break;
          case 'Champ langues est obligatoire!':
            setlanguagesError(error);
            break;
          case "Champ niveau d'étude est obligatoire!":
            setlevelStudyError(error);
            break;
          case 'Champ compétences requis est obligatoire!':
            setskillsRequiredError(error);
            break;
          case "Champ date de début souhaitée est obligatoire!":
            setdesiredStartDateError(error);
            break;
          case 'Champ mission est obligatoire!':
            setmissionError(error);
            break;
          case 'Champ horaire est obligatoire!':
            setscheduleError(error);
            break;
          case "Champ Type d'emploi désiré est obligatoire!":
            settypeContractError(error);
            break;
          case "Champ bureau est obligatoire!":
            setlocationError(error);
            break;  
          default:
            break;
        }
      });
    }
    else {
      const actionPayload = {
        titleOffer: DataOffer.titleOffer,
        CompanyDescription: DataOffer.CompanyDescription,
        offerDescription: DataOffer.offerDescription,
        experience: DataOffer.experience,
        languages: DataOffer.languages, 
        levelStudy: DataOffer.levelStudy,
        skillsRequired: modif ?  DataOffer.skillsRequired : DataOffer.skillsRequired.split(','),
        remuneration: DataOffer.remuneration,
        offerDeadline: DataOffer.offerDeadline,
        desiredStartDate: DataOffer.desiredStartDate,
        mission: DataOffer.mission,
        schedule: DataOffer.schedule,
        location: DataOffer.location,
        typeContract: DataOffer.typeContract,
      };      
      if (modif) 
      {
        actionPayload.id = DataOffer.id;
        dispatch(offerSagaActions.editoffer(actionPayload));
        setactionSuccess('Une Offre a été modifiéé avec succès!');
      } else {
        dispatch(offerSagaActions.addoffer(actionPayload));
        setactionSuccess('Une Offre a été ajoutée avec succès!');
      };
      setTimeout(() => {
        setshowmodal(!showmodal);
      }, 1000);
    }
  }

  let className = 'overflow-y-auto';

  if (config === 'candidat') {
    className += ' h-[90%]';
  } else if (config === 'offer') {
    className += ' md:w-[70%] w-[90%] h-[90%]';
  } else {
    className += 'max-w-md';
  }
  return (
  <>
  { showmodal &&  
  <div ref={refer} className='modal-background' >
    <div className={className}>
      <div className='relative bg-white rounded-lg shadow'>
        <div className='px-6 2xl:py-6 py-6 justify-center'>
          { config === 'status' && <FormTitle modif={modif} addtitle={'Ajouter un statut'} modiftitle={'Modifier un statut'} />}
          { config === 'typecontract' && <FormTitle modif={modif} addtitle={'Ajouter un type de contrat'} modiftitle={'Modifier un type de contrat'} />}
          { config === 'profil' && <FormTitle modif={modif} addtitle={'Ajouter un profil'} modiftitle={'Modifier un profil'} />}
          { config === 'source' && <FormTitle modif={modif} addtitle={'Ajouter une source'} modiftitle={'Modifier une source'} />}
          { config === 'office' && <FormTitle modif={modif} addtitle={'Ajouter un bureau'} modiftitle={'Modifier un bureau'} />}
          { config === 'users' && <FormTitle modif={modif}  modiftitle={'Modifier un utilisateur'} />}
          { config === 'upload'  && <FormTitle modif={modif}  modiftitle={"Modifier l'image d'un utilisateur"} />}
          { config === 'candidat'  && <FormTitle modif={modif}  addtitle={'Ajouter un candidat'} modiftitle={"Modifier un candidat"} />}
          { config === 'AcceptRefusecandidat' && isOpenAcceptRefuse && <h3 className='mb-4 text-md font-medium text-gray-900 text-center whitespace-nowrap'>Accepter / Refuser candidat {selectedItem.firstname+' '+selectedItem.lastname}</h3>}
          { config === 'feedback' && <FormTitle modif={modif}  addtitle={'Ajouter un feedback'} modiftitle={"Modifier un feedback"} />}
          { config === 'reference' && <FormTitle modif={modif}  addtitle={'Ajouter une prise de référence'} modiftitle={"Modifier une prise de référence"} />}
          { config === 'remuneration' && <FormTitle modif={modif}  addtitle={'Ajouter une rémunération'} modiftitle={"Modifier une rémunération"} />}
          { config === 'offer' && <FormTitle modif={modif}  addtitle={'Ajouter une offre'} modiftitle={"Modifier une offre"} />}
          { actionSuccess && !error && <Alert Message={ actionSuccess } color={'green'} /> }
          { error && <Alert Message={ error } color={'red'} /> }
          { config === 'status' &&
          <StatutForm
             onSubmit={addstatut}
             statusError={statusError}
             credentials={credentials}
             onChange={handleChange}
             codecouleurError={codecouleurError}
             color={color}
             handleColorChangeComplete={handleColorChangeComplete}
             handleclosemodal={handleclosemodal}
             modif={modif}
            />
          }
          { config === 'typecontract' &&
            <TypeContractForm
              onSubmit={addTypeContract}
              TypeContractError={TypeContractError}
              credentials={credentials}
              onChange={handleChange}
              handleclosemodal={handleclosemodal}
              modif={modif}
            />
          }
          { config === 'profil' &&
            <ProfilForm
              onSubmit={addprofil}
              ProfiltError={ProfilError}
              DataProfil={DataProfil}
              onChange={handleChangeProfil}
              handleclosemodal={handleclosemodal}
              modif={modif}
            />
          }
          { config === 'source' &&
            <SourceForm
              onSubmit={addsource}
              SourceError={SourceError}
              credentials={credentials}
              onChange={handleChange}
              handleclosemodal={handleclosemodal}
              modif={modif}
            />
          }
          { config === 'office' &&
            <OfficeForm
              onSubmit={addoffice}
              OfficeError={OfficeError}
              credentials={credentials}
              onChange={handleChange}
              handleclosemodal={handleclosemodal}
              modif={modif}
            />
          }
          { config === 'users' && isUser &&
            <UserForm
              onSubmit={modifuser}
              credentials={DataUser}
              onChange={handleChangeUser}
              handleclosemodal={handleclosemodal}
              modif={modif}
            />
          }
          { config === 'upload' && showmodalUpload &&
            <UploadImageUserForm
              onSubmit={uploadimageuser}
              credentials={credentials.file}
              onChange={handleChange}
              handleclosemodal={handleclosemodalUpload}
              modif={modif}
              mediaObjectError = {mediaObjectError}
            />
          }
          { config === 'candidat' && isCandidat &&
            <CandidatForm
              onSubmit={addcandidat}
              onChange={handleChangeCandidat}
              credentials={DataCandidat}
              handleclosemodal={handleclosemodal}
              modif={modif}
              FirstNameError={FirstnameApplicantError}
              setFirstnameApplicantError={setFirstnameApplicantError}
              LastNameError={LastnameApplicantError}
              setLastnameApplicantError={setLastnameApplicantError}
              EmailApplicantError={EmailApplicantError}
              setEmailApplicantError={setEmailApplicantError}
              CellphoneApplicantError={CellphoneApplicantError}
              setCellphoneApplicantError={setCellphoneApplicantError}
              PosteApplicantError={PosteApplicantError}
              setPosteApplicantError={setPosteApplicantError}
              StatusApplicantError={StatusApplicantError}
              setStatusApplicantError={setStatusApplicantError}
              OfficeApplicantError={OfficeApplicantError}
              setOfficeApplicantError={setOfficeApplicantError}
              ProfilApplicantError={ProfilApplicantError}
              setProfilApplicantError={setProfilApplicantError}
              TypeContractApplicantError={TypeContractApplicantError}
              setTypeContractApplicantError={setTypeContractApplicantError}
              SourceApplicantError={SourceApplicantError}
              setSourceApplicantError={setSourceApplicantError}
              MediaObjectApplicantError={MediaObjectApplicantError}
              setMediaObjectApplicantError={setMediaObjectApplicantError}
            />
          }
          { config === 'AcceptRefusecandidat' && isOpenAcceptRefuse &&
            <AcceptRefuseCandidat
              DataCandidat = {DataCandidat}
              acceptcandidat={acceptcandidat}
              refusercandidat={refusercandidat}
              onChange={handleChangeCandidat}
              credentials={DataCandidat}
              handleclosemodal={handleclosemodalAcceptRefuseCandidat}
            />
          }
          { config === 'Sharecandidat'  && isOpenShareCandidat &&
            <ShareCandidat
              DataCandidat = {DataCandidat}
              associatecandidat={associatecandidat}
              onChange={handleChangeCandidat}
              credentials={DataCandidat}
              handleclosemodal={handleclosemodalShareCandidat}
            />
          }
          { config === 'feedback' && showmodalFeedback &&
            <FeedbackForm
              DataFeedback = {DataFeedback}
              handleclosemodal={handleclosemodalFeedback}
              onChange={handleChangeFeedback}
              onSubmit={addfeedback}
              modif={modif}
              CommentError={CommentError} 
              setCommentError={setCommentError}
              EnglishLevelError={EnglishLevelError}
              setEnglishLevelError={setEnglishLevelError}
              AvaibilityError={AvaibilityError}
              setAvaibilityError={setAvaibilityError}
            />
          }
          { config === 'reference' && showmodalReference &&
           <ReferenceForm 
            DataReference = {DataReference}
            onChange={handleChangeReference}
            handleclosemodal={handleclosemodalReference}
            modif={modif}
            OnSubmit={addreference}
            contactNameError={NomContactError}
            EmailContactError={EmailContactError}
            TelephoneContactError={TelephoneContactError}
            CompanyContactError={CompanyContactError}
            CommentError={CommentError}
            setNomContactError={setNomContactError}
            setEmailContactError = {setEmailContactError}
            setTelephoneContactError  = {setTelephoneContactError}
            setCompanyContactError  = {setCompanyContactError}
            setCommentError = {setCommentError}
           />
          }
          { config === 'remuneration' && showmodalRemuneration &&
           <RemunerationForm 
            DataRemuneration= {DataRemuneration}
            onChange={handleChangeRemuneration}
            handleclosemodal={handleclosemodalRemuneration}
            modif={modif}
            OnSubmit={addremuneration}
            RemunerationActualFixError={RemunerationActualFixError}
            setRemunerationActualFixError={setRemunerationActualFixError}
            CurrentVariableError={CurrentVariableError}
            setCurrentVariableError={setCurrentVariableError}
            RemunerationDesiredFixError={RemunerationDesiredFixError}
            setremunerationDesiredFixError={setremunerationDesiredFixError}
            DesiredVariableError={DesiredVariableError}
            setDesiredVariableError={setDesiredVariableError}
            CommentError={CommentError}
            setCommentError = {setCommentError}
           />
          }
          { config === 'offer' && 
            <OfferForm 
            modif={modif}
            handleclosemodal={handleclosemodal}
            onChange={handleChangeOffer}
            onSubmit={addOffer}
            DataOffer={DataOffer}
            titleOfferError={titleOfferError}
            settitleOfferError={settitleOfferError}
            CompanyDescriptionError={CompanyDescriptionError}
            setCompanyDescriptionError={setCompanyDescriptionError}
            OfferDescriptionError={OfferDescriptionError}
            setOfferDescriptionError={setOfferDescriptionError}
            experienceError={experienceError}
            setexperienceError={setexperienceError}
            languagesError={languagesError}
            setlanguagesError={setlanguagesError}
            levelStudyError={levelStudyError}
            setlevelStudyError={setlevelStudyError}
            skillsRequiredError={skillsRequiredError}
            setskillsRequiredError={setskillsRequiredError}
            remunerationError={remunerationOfferError}
            setremunerationError={setremunerationOfferError}
            OfferDeadlineError={OfferDeadlineError}
            setOfferDeadlineError={setOfferDeadlineError}
            desiredStartDateError={desiredStartDateError}
            setdesiredStartDateError={setdesiredStartDateError}
            missionError={missionError}
            setmissionError={setmissionError}
            scheduleError={scheduleError}
            setscheduleError={setscheduleError}
            locationError={locationError}
            setlocationError={setlocationError}
            typeContractError={typeContractError}
            settypeContractError={settypeContractError}
            existoffer={error}
            />
          }
        </div>
      </div>
    </div>
  </div>
  }
  </>
)}