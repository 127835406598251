import { ChromePicker } from 'react-color';

export default function ColorInput({color , handleColorChangeComplete}) {
  return (
    <ChromePicker
      color={color}
      onChangeComplete={handleColorChangeComplete}
      width='auto'
    />
  )
}
