import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  candidatList: [],
  success: false,
  error: null,
  candidat: null
}

const candidatSlice = createSlice ({
  name: 'candidat',
  initialState,
  reducers: {
    getAllcandidatSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.candidatList = action.payload['hydra:member'];  
      state.success = true;
    },
    getAllcandidatFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    addcandidatSuccess(state,action)
    {
      state.success = action.payload;
      state.error = false;
    },
    addcandidatailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    editcandidatSuccess(state)
    {
      state.success = true;
      state.error = false;
    },
    editcandidatFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    deletecandidatSuccess(state, action)
    {
      state.success = true;
      state.candidatList = state.candidatList.filter((candidat) => candidat.id !== action.payload);
    },
    deletecandidatFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    acceptrefusecandidatSuccess(state, action)
    {
      state.success = true;
      state.candidatList = state.candidatList.filter((candidat) => candidat.id !== action.payload);
    },
    acceptrefusecandidatFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getcandidatbyidSuccess(state, action)
    {
      state.candidat = action.payload
      state.success = true;
      state.error = false;
    },
    getcandidatbyidFailure(state, action)
    {
      state.success = false;
      if(action.payload === 404)
      {
        state.error = 'applicant not found';
      }
      else if (action.payload === 401)
      {
        state.error = 'unauthorized !';
      }
      else
      {
        state.error = 'something else happened !';
      }
    }
  }
})

export const {
  getAllcandidatSuccess,
  getAllcandidatFailure,
  addcandidatSuccess,
  addcandidatailure,
  editcandidatSuccess,
  editcandidatFailure,
  deletecandidatSuccess,
  deletecandidatFailure,
  acceptrefusecandidatSuccess,
  acceptrefusecandidatFailure,
  getcandidatbyidSuccess,
  getcandidatbyidFailure
  } = candidatSlice.actions;

export default  candidatSlice.reducer