import DatePicker from 'react-datepicker';
import Input from '../Input'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { typecontractSagaActions } from '../../redux/typecontract/TypecontractSagaAction';
import { officeSagaActions } from '../../redux/office/OfficeSagaActions';
import GoBackButton from '../bouttons/GoBackButton';
import moment from 'moment';
import AlertApplicant from '../AlertApplicant';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function ApplicationForm({
  onSubmit,
  handleclosemodal,
  modif,
  DataOffer,
  onChange,
  titleOfferError,
  settitleOfferError,
  CompanyDescriptionError,
  setCompanyDescriptionError,
  OfferDescriptionError,
  setOfferDescriptionError,
  experienceError,
  setexperienceError,
  languagesError,
  setlanguagesError,
  levelStudyError,
  setlevelStudyError,
  skillsRequiredError,
  setskillsRequiredError,
  remunerationError,
  setremunerationError,
  OfferDeadlineError,
  setOfferDeadlineError,
  desiredStartDateError,
  setdesiredStartDateError,
  missionError,
  setmissionError,
  scheduleError,
  setscheduleError,
  locationError,
  setlocationError,
  typeContractError,
  settypeContractError,
}) {
  const dispatch = useDispatch();
  const animatedComponents = makeAnimated();
  const [ selecteDateoffreDeadline, setSelecteDateoffreDeadline] = useState(null);
  const [ selecteDatedesiredStartDate, setSelecteDatedesiredStartDate] = useState(null);
  const [inputValueSkilledrequired, setInputValueSkilledrequired] = useState('');
  const optionslanguages = [
    {
      label:'Français',
      value:'Français'
    },
    {
      label:'Anglais',
      value:'Anglais'
    },
    {
      label:'Allemand',
      value:'Allemand'
    },
    {
      label:'espagnol',
      value:'espagnol'
    }
  ]

  const optionsexperiences= [
    {
      label:'Junior',
      value:'Junior'
    },
    {
      label:'Senior',
      value:'Senior'
    },
    {
      label:'Confirmé',
      value:'Confirmé'
    },
    {
      label:'Stage',
      value:'Stage'
    },
    {
      label:'Alternant',
      value:'Alternant'
    }
  ]
  const {
    activeTypeContractList
  } = useSelector(
      (state) => state.typecontract
  );

  const {
    activeofficeList
  } = useSelector(
      (state) => state.office
  );

  useEffect(() => {
    dispatch(typecontractSagaActions.getactivetypecontract());
    dispatch(officeSagaActions.getactiveoffice());
  }, [dispatch]);

  const optionsTypeContract = activeTypeContractList.map(typecontract => ({
    value: typecontract['@id'],
    label: typecontract.typeContract
  }));

  const optionsOffice = activeofficeList.map(office => ({
    value: office['@id'],
    label: office.office,
  }));

  const handleChangeField = (e) => {
    const { name, value} = e.target;
    if(name==='titleOffer')
    {
      settitleOfferError('')
    }
    if(name==='levelStudy')
    {
      setlevelStudyError('')
    }
    if(name==='schedule')
    {
      setscheduleError('')
    }
    if(name==='skillsRequired')
    {
      setskillsRequiredError('')
      setInputValueSkilledrequired(value);
      onChange(name , value.split(',').map((word => word)))
    }
    if(name==='remuneration')
    {
      setremunerationError('')
    }
    onChange(name, value)
  };

  const handleSelect = (name, select) => {
    if(name==='experience')
    {
      setexperienceError('')
    }
    if(name==='typeContract')
    {
      settypeContractError('')
    }
    if(name==='location')
    {
      setlocationError('')
    }
    onChange(name, select.value)
  };

  const handleSelectMulti = (name, select) => {
    if(name==='languages')
    {
      setlanguagesError('')
    }
    onChange(name, select.map((e) => e.value))
  };

  const handleDate = (name,date) => {
    if(name === 'desiredStartDate')
    { 
      setSelecteDatedesiredStartDate(date);
      setdesiredStartDateError('')
    }
    if(name === 'offerDeadline')
    {
      setSelecteDateoffreDeadline(date);
      setOfferDeadlineError('')
    }
    onChange(name , moment(date).format('DD-MM-YYYY'));
  }

  const handleCkEditor = (value, name) => 
  {
    if(name==='CompanyDescription')
    {
      setCompanyDescriptionError('')
    }
    if(name==='offerDescription')
    {
      setOfferDescriptionError('')
    }
    if(name==='mission')
    {
      setmissionError('')
    }
    onChange(name , value)
  }

  const highlightWordBeforeComma = (text, displayed_text='') => {
    const words = text.split(',');
    if(text === '')
    {
      displayed_text ='';
    }
    else
    {
      for (let i = 0; i < words.length; i++) {
        displayed_text +=`<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">${words[i]}</span>`;
      }
    }
    return displayed_text;
  };

  return (
    <form onSubmit={onSubmit}> 
      <div className='flex flex-col gap-2'>
        <div className='grid md:grid-cols-3 grid-cols-1 overflow-auto gap-6'>
          <div className='flex flex-col'>
            <Input
              type={'text'}
              className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
              name = {'titleOffer'}
              classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 z-20 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4'}
              placeholder={"Titre de l'offre"}
              onChange={handleChangeField}
              value={DataOffer.titleOffer}
            />
            { titleOfferError && <AlertApplicant Message={ titleOfferError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
          </div>
          <div className='flex flex-col'>
            <Input
              type={'text'}
              className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
              name = {'levelStudy'}
              classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4'}
              placeholder={"Niveau d'étude"}
              onChange={handleChangeField}
              value={DataOffer.levelStudy}
            />
            { levelStudyError && <AlertApplicant Message={ levelStudyError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
          </div>
          <div className='flex flex-col'>
            <Input
              type={'text'}
              className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
              name = {'schedule'}
              classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4'}
              placeholder={'Régime horaire'}
              onChange={handleChangeField}
              value={DataOffer.schedule}
            />
            { scheduleError && <AlertApplicant Message={ scheduleError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
          </div>
        </div>
        <div className='grid grid-cols-1'>
          <div className='flex flex-col'>
            <label className='block  text-sm font-medium text-gray-900 dark:text-white'>Description de l'entreprise</label>
            <CKEditor
              editor={ ClassicEditor }
              data={DataOffer.CompanyDescription}
              onChange={(event, editor) => handleCkEditor(editor.getData(), 'CompanyDescription')}
            />
            { CompanyDescriptionError && <AlertApplicant Message={ CompanyDescriptionError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
          </div>
        </div>
        <div className='grid grid-cols-1'>
          <div className='flex flex-col'>
            <label className='block  text-sm font-medium text-gray-900 dark:text-white'>Description de l'offre</label>
            <CKEditor
              editor={ ClassicEditor }
              data={DataOffer.offerDescription}
              onChange={(event, editor) => handleCkEditor(editor.getData(), 'offerDescription')}
            />
            { OfferDescriptionError && <AlertApplicant Message={ OfferDescriptionError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
          </div>
        </div>
        <div className='grid grid-cols-3 gap-6'>
          <div className='flex flex-col gap-2'>
            <label className='block  text-sm font-medium text-gray-900 dark:text-white'>Expériences</label>
            <Select
              options={optionsexperiences}
              components={animatedComponents}
              className='basic-multi-select' 
              onChange={(e) => handleSelect('experience', e)}
              defaultValue={{label: DataOffer.experience, value: DataOffer.experience }}
            />
            { experienceError && <AlertApplicant Message={ experienceError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
          </div>
          <div className='flex flex-col gap-2'>
            <label className='block  text-sm font-medium text-gray-900 dark:text-white'>Langues</label>
            <Select
              options={optionslanguages}
              isMulti
              components={animatedComponents}
              className='basic-multi-select' 
              onChange={(e) => handleSelectMulti('languages', e)}
              defaultValue={DataOffer.languages.map(langue => ({
                value: langue,
                label: langue
                }), 
              )}
            />   
            { languagesError && <AlertApplicant Message={ languagesError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
          </div>
          <div className='flex flex-col gap-2'>
            <label className='block  text-sm font-medium text-gray-900 dark:text-white truncate'>Type d'emploi désiré</label>
            <Select
              options={optionsTypeContract}
              components={animatedComponents}
              className='basic-multi-select' 
              onChange={(e) => handleSelect('typeContract', e)}
              defaultValue={{ label: DataOffer.typeContract.typeContract, value: DataOffer.typeContract['@id'] }}
            />   
            { typeContractError && <AlertApplicant Message={ typeContractError } color={'red'} className={'flex items-center text-xs text-red-800'} /> } 
          </div>
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <div className='flex flex-col'>
              <Input
                  contentEditable={true}
                  type={'text'}
                  className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
                  name = {'skillsRequired'}
                  classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4'}
                  placeholder={'Compétences requis'}
                  onChange={handleChangeField}
                  value={DataOffer.skillsRequired}
                />
                <span
                  className='-mt-5'
                  dangerouslySetInnerHTML={{ __html: highlightWordBeforeComma(inputValueSkilledrequired)}}
                />
                <AlertApplicant Message={'Veuillez entrer virgule a la fin de chaque compétence'} color={'gray'} className={'flex items-center text-xs text-gray-600'} />
                { skillsRequiredError && <AlertApplicant Message={ skillsRequiredError } color={'red'} className={'flex items-center text-xs text-red-800'} /> } 
            </div>
            <div className='flex flex-col'>
              <Input
                type={'text'}
                className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
                name = {'remuneration'}
                classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4'}
                placeholder={'Rémunération'}
                onChange={handleChangeField}
                value={DataOffer.remuneration}
              />
              { remunerationError && <AlertApplicant Message={ remunerationError } color={'red'} className={'flex items-center text-xs text-red-800'} /> } 
            </div>
          </div>
          <div className='grid grid-cols-3 gap-6'>
            <div className='flex flex-col gap-2'>
              <label className='block  text-sm font-medium text-gray-900 dark:text-white truncate'>La Date limite</label>
              <DatePicker
                placeholderText='Choisir une date'
                name='offerDeadline'
                className='w-full'
                selected={selecteDateoffreDeadline}
                dateFormat='dd/MM/yyyy'
                onChange={(e) => handleDate('offerDeadline', e)}
                value={DataOffer.offerDeadline}
                showIcon
              />
              { OfferDeadlineError && <AlertApplicant Message={ OfferDeadlineError } color={'red'} className={'flex items-center text-xs text-red-800'} /> } 
            </div>
            <div className='flex flex-col gap-2'>
              <label className='block  text-sm font-medium text-gray-900 dark:text-white truncate'>Date de début souhaitée</label>
              <DatePicker
                placeholderText='Choisir une date'
                showIcon
                name='desiredStartDate'
                className='w-full'
                selected={selecteDatedesiredStartDate}
                dateFormat='dd/MM/yyyy'
                onChange={(e) => handleDate('desiredStartDate', e)}
                value={DataOffer.desiredStartDate}
              />
              { desiredStartDateError && <AlertApplicant Message={ desiredStartDateError } color={'red'} className={'flex items-center text-xs text-red-800'} /> } 
            </div>
            <div className='flex flex-col gap-2'>
              <label className='block  text-sm font-medium text-gray-900 dark:text-white truncate'>Bureaux</label>
              <Select
                options={optionsOffice}
                components={animatedComponents}
                className='basic-multi-select' 
                onChange={(e) => handleSelect('location', e)}
                defaultValue={{ label: DataOffer.location.office, value: DataOffer.location['@id'] }}
              />    
              { locationError && <AlertApplicant Message={ locationError } color={'red'} className={'flex items-center text-xs text-red-800'} /> } 
            </div>
          </div>
          <div className='grid grid-cols-1 gap-2'> 
            <label className='block  text-sm font-medium text-gray-900 dark:text-white'>Description de la mission</label>
            <CKEditor
              editor={ ClassicEditor }
              data={DataOffer.mission}
              onChange={(event, editor) => handleCkEditor(editor.getData(), 'mission')}
            />
            { missionError && <AlertApplicant Message={ missionError } color={'red'} className={'flex items-center text-xs text-red-800'} /> } 
          </div>
      </div>
      <div className='flex items-center justify-center text-primary gap-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div>   
      </div>
    </form>      
  )
}


export default ApplicationForm
