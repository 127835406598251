import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import ChangePassword from './pages/ChangePassword';
import Home from './pages/Home';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import RequireAuth from './redux/auth/RequireAuth';
import Status from './pages/Status';
import TypeContract from './pages/TypeContract';
import Profil from './pages/Profil';
import Source from './pages/Source';
import Office from './pages/Office';
import Users from './pages/Users';
import ApplicantFile from './pages/ApplicantFile';
import Profile from './pages/Profile';
import Notification from './pages/Notification';
import Statistique from './pages/Statistique/Statistique';
import SingleApplication from './pages/SingleApplication';
import Offers from './pages/Offers';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          {/* public routes */}
          <Route path='/' element={<Login />} />
          <Route path='/resetPassword' element={<ResetPassword />} />
          <Route path='/reset_password/:token' element={<ChangePassword />} />
          <Route path='/offre/:id' element={<SingleApplication />} />
          {/* protected routes*/}
          <Route element={<RequireAuth />}>
            <Route path='/home' element={<Home />} />
            <Route path='/status' element={<Status />} />
            <Route path='/typecontract' element={<TypeContract />} />
            <Route path='/profil' element={<Profil />} />
            <Route path='/source' element={<Source />} />
            <Route path='/bureau' element={<Office />} />
            <Route path='/utilisateur' element={<Users />} />
            <Route path='/candidats' element={<Home />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/fiche_candidat/:id' element={<ApplicantFile />} />
            <Route path='/notification' element={<Notification />} />
            <Route path='/statistique' element={<Statistique />} />
            <Route path='/offres' element={<Offers />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter> 
  );
}

export default App;
