import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../hooks/Api";
import { 
  addapplicantUserSuccess,
  addapplicantUsersFailure,
} from "./ApplicantUserSlice";

const token = localStorage.getItem('token');

function* workeraddapplicantUsers(action)
{
  try
    {
      const data = {
        source: action.payload.source,
        applicant_id: action.payload.applicant_id,
        associated: action.payload.associated,
      }
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/associer_candidat`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield put(addapplicantUserSuccess(data));
}
  catch(error)
  {
    yield put(addapplicantUsersFailure(error))
  }  
}

export function* watcherapplicantUser()
{
  yield takeEvery('addApplicantUsers', workeraddapplicantUsers)
}