import { useState } from "react";
import { useSelector } from "react-redux";

const CandidatHook = ({selectedItem}) => {
  const {
    activeStatusList
  } = useSelector(
      (state) => state.status
  );

  const defaultUser = '';

  const getSelectedUser = (role,source) =>
  selectedItem?.applicantUsers
    ?.filter((applicantUser) => applicantUser.source.includes(source))
    ?.map((applicantUser) => applicantUser.user)
    ?.filter((user) => user.roles.includes(role))
    ?.map((selectedUser) => `{"user": "${selectedUser['@id']}", "source": "${source}"}`) ?? [];

  const getAssociatedUser = (source) =>
    selectedItem?.applicantUsers
    ?.filter((applicantUser) => applicantUser.source.includes(source))
    ?.map((applicantUser) => applicantUser.user)
    ?.map((selectedUser) => {
      return JSON.parse(`{
          "user_id": "${selectedUser.id}",
          "source": "${source}",
          "email_user": "${selectedUser.email}"
      }`);
    }) ?? [defaultUser];

  const setDefaultValue = (value, defaultValue = '') => value || defaultValue;
  const setDefaultValueStatus = (value, defaultValue = activeStatusList.filter(status => status.status === 'Présélection').map(status => status['@id'])[0]) => {
    return value || defaultValue;
  };

  const [Data, setData] = useState({
    id:  selectedItem ? selectedItem.id : null,
    createdAt: setDefaultValue(selectedItem?.createdAt),
    updatedAt: setDefaultValue(selectedItem?.updatedAt),
    firstname: setDefaultValue(selectedItem?.firstname),
    lastname: setDefaultValue(selectedItem?.lastname),
    email: setDefaultValue(selectedItem?.email),
    linkedin : setDefaultValue(selectedItem?.linkedin),
    cellphone: setDefaultValue(selectedItem?.cellphone),
    poste: setDefaultValue(selectedItem?.poste),
    status: setDefaultValueStatus(selectedItem?.status),
    office: setDefaultValue(selectedItem?.office),
    profil: setDefaultValue(selectedItem?.profil),
    typecontract: setDefaultValue(selectedItem?.typecontract),
    source: setDefaultValue(selectedItem?.source),
    mediaObject: setDefaultValue(selectedItem?.mediaObject),
    applicantUsers: setDefaultValue(selectedItem?.applicantUsers, []),
    Rh: getSelectedUser('ROLE_HR','Add_applicant'),
    Directeur: getSelectedUser('ROLE_MANAGER','Add_applicant'),
    Refferant: getSelectedUser('ROLE_REFFERANT','Add_applicant'),
    associated: getAssociatedUser('Associate_applicant'),
  });

  return [
    Data,
    setData,
  ];
}

export default CandidatHook