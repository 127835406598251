import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalItems: 0,
  statusList: [],
  activeStatusList: [],
  success: false,
  error: null,
}

const statusSlice = createSlice ({
    name: 'status',
    initialState,
    reducers: {
      getAllstatusSuccess(state , action)
      {
        state.totalItems = action.payload['hydra:totalItems'];
        state.statusList = action.payload['hydra:member'];  
        state.success = true;
      },
      getActiveStatus(state, action) {
        state.totalItems = action.payload['hydra:totalItems'];
        state.activeStatusList = action.payload['hydra:member'];
      },  
      getstatusFailure(state , action)
      {
        state.success = false;
        if(action.payload===401)
        {
          toast.error('action unauthorized', {theme: 'colored'});
        }
        else
        {
          toast.error('something else happened !',{theme: 'colored'})  
        }
      },
      addstatusSuccess(state, action)
      {
        state.success = true;
        state.error = false;
        state.statusList = [...state.statusList, action.payload]
      },
      addstatusFailure(state,action)
      {
        state.success = false;
        if(action.payload===401)
        {
          toast.error('action unauthorized', {theme: 'colored'});
        }
        else
        {
          toast.error('something else happened !',{theme: 'colored'})  
        }
      },
      editstatusSuccess(state, action)
      {
        state.success = true;
        state.statusList = state.statusList.map(i => i.id === action.payload.id ? action.payload : i)
      },
      editstatusFailure(state, action)
      {
        state.success = false;
        if(action.payload===401)
        {
          toast.error('action unauthorized', {theme: 'colored'});
        }
        else
        {
          toast.error('something else happened !',{theme: 'colored'})  
        }
      },
    }
})

export const {
  getAllstatusSuccess,
  getActiveStatus,
  getstatusFailure,
  addstatusSuccess,
  addstatusFailure,
  editstatusSuccess,
  editstatusFailure,
  } = statusSlice.actions;

export default  statusSlice.reducer