

function ValidationsDataReference(data) {
  const errors = [];

  if (data.contactName.length === 0) {
    errors.push('Champ nom du contact est obligatoire!');
  }
  if (data.contactEmail.length === 0) {
    errors.push('Champ email du contact est obligatoire!');
  }
  if (data.contactCellphone.length === 0) {
    errors.push('Champ téléphone du contact est obligatoire!');
  }
  if (data.contactCompany.length === 0) {
    errors.push('Champ entreprise du contact est obligatoire!');
  }
  if (data.comment.length === 0) {
    errors.push('Champ commentaire est obligatoire!');
  }

  return errors;
}

export default ValidationsDataReference
