import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  addcandidatSuccess,
  addcandidatailure,
  editcandidatFailure,
  editcandidatSuccess,
  getAllcandidatFailure,
  getAllcandidatSuccess,
  deletecandidatSuccess,
  deletecandidatFailure,
  acceptrefusecandidatSuccess,
  acceptrefusecandidatFailure,
  getcandidatbyidSuccess,
  getcandidatbyidFailure
} from './CandidatSlice';

const token = localStorage.getItem('token')

function* workergetallcandidat(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/applicants?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllcandidatSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllcandidatFailure(error.response.status))
  }  
}

function* workergetallcandidatwithoutpagination(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/applicants`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllcandidatSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllcandidatFailure(error.response.status))
  }  
}

function* getcandidatbyid(id)
{
  const res = yield call(()=> api({
  url: `${process.env.REACT_APP_API_URL}/api/applicants/${id}`,
  method: 'GET',
  data: id,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  }));
if (false === res.ok) {
  throw new Error('Failed to get candidat');
}

return res.data;
}

function* workerfiltercandidat(action)
{ 
  let str='';
  if(action.payload.contact)
  {
    str+=`contact=${action.payload.contact}`
  }
  
  if(action.payload.poste !== undefined)
  {
    str+=`poste=${action.payload.poste}`
  }
  if(action.payload.office !== undefined)
  {
    str+=`office.office=${action.payload.office}`
  }
  if(action.payload.profil !== undefined)
  {
    str+=`profil.profil=${action.payload.profil}`
  }
  if(action.payload.typecontract !== undefined)
  {
    str+=`typecontract.typeContract=${action.payload.typecontract}`
  }
  if(action.payload.status !== undefined)
  {
    str+=`status.status=${action.payload.status}`
  }
  if(action.payload.Dev !== undefined)
  {
    str+=`profil.expertise.expertise=${action.payload.Dev}`
  }
  if(action.payload.Crea !== undefined)
  {
    str+=`profil.expertise.expertise=${action.payload.Crea}`
  }
  if(action.payload.Marketing !== undefined)
  {
    str+=`profil.expertise.expertise=${action.payload.Marketing}`
  }
  if(action.payload.Ops !== undefined)
  {
    str+=`profil.expertise.expertise=${action.payload.Ops}`
  }
  if(action.payload.ProjectManagement !== undefined)
  {
    str+=`profil.expertise.expertise=${action.payload.ProjectManagement}`
  }
  if(action.payload.responseoffer !== undefined)
  {
    str+=`status=${action.payload.responseoffer}&_page=${action.payload.page}`
  }
  if(action.payload.enprocessoffer !== undefined)
  {
    str+=`enprocess&_page=${action.payload.page}`
  }
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/applicants?${str}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllcandidatSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllcandidatFailure(error.response.status))
  }  
}

function* uploadPicture(file) {
  const formData = new FormData();
  formData.append('file', file);

  const response = yield call(() => api({
    url: `${process.env.REACT_APP_API_URL}/api/media_objects`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }));

  if (false === response.ok) {
    throw new Error('Failed to upload picture');
  }

  return response.data;
}

function* workeraddcandidat(action)
{
  try
    {
      let mediaObject = '';
      const picture = yield call(uploadPicture, action.payload.mediaObject);
      mediaObject = `/api/media_objects/${picture.id}`;
      const data = {
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        email: action.payload.email,
        linkedin: action.payload.linkedin,
        cellphone: action.payload.cellphone,
        poste: action.payload.poste,
        office: action.payload.office,
        profil: action.payload.profil,
        typecontract: action.payload.typecontract,
        source: action.payload.source,
        status: action.payload.status,
        mediaObject: mediaObject,
        applicantUsers: action.payload.applicantUsers,
      }
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/applicants`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
      
  }
))
  yield workergetallcandidat({payload : 1});
  yield put(addcandidatSuccess(data));
}
  catch(error)
  {
    yield put(addcandidatailure(error))
  }  
}

function* workerapplycandidat(action)
{
  try
    {
      const picture = yield call(uploadPicture, action.payload.mediaObject);
      const data = {
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        email: action.payload.email,
        poste: action.payload.poste,
        office: action.payload.office,
        typecontract: action.payload.typecontract,
        mediaObject: picture.id,
        offer: action.payload.offer
      }
      const response = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/postuler_candidat`,
      method: 'POST',
      data: data,
  }
))
  yield put(addcandidatSuccess(response));
}
  catch(error)
  {
    yield put(addcandidatailure(error))
  }  
}

function* workereditcandidat(action)
{ try
    {
      let mediaObject = '';
      let status , office, typecontract, profil, source = '';
      const applicant = yield call(getcandidatbyid , action.payload.id);
      if(action.payload.mediaObject['@id'] !== applicant.mediaObject['@id'])
      {
        const picture = yield call(uploadPicture, action.payload.mediaObject);
        mediaObject = `/api/media_objects/${picture.id}`;
      }
      else
      {
        mediaObject = applicant.mediaObject['@id']
      }
      if(typeof action.payload.status === 'object')
      {
        status = action.payload.status['@id']
      }
      else
      {
        status = action.payload.status
      }
      if(typeof action.payload.office === 'object')
      {
        office = action.payload.office['@id']
      }
      else
      {
        office = action.payload.office
      }
      if(typeof action.payload.typecontract === 'object')
      {
        typecontract = action.payload.typecontract['@id']
      }
      else
      {
        typecontract = action.payload.typecontract
      }
      if(typeof action.payload.profil === 'object')
      {
        profil = action.payload.profil['@id']
      }
      else
      {
        profil = action.payload.profil
      }
      if(typeof action.payload.source === 'object')
      {
        source = action.payload.source['@id']
      }
      else
      {
        source = action.payload.source
      }
      const data = {
        id: action.payload.id,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        email: action.payload.email,
        linkedin: action.payload.linkedin,
        cellphone: action.payload.cellphone,
        poste: action.payload.poste,
        status: status,
        office: office,
        typecontract:typecontract,
        profil: profil,
        source: source,
        mediaObject: mediaObject,
        applicantUsers: action.payload.applicantUsers,
      }
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/applicants/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))

yield workergetallcandidat({payload : 1});
yield put(editcandidatSuccess(data));

}
  catch(error)
  {
    yield put(editcandidatFailure(error))
  }  
}

function* workerdeletecandidat(action)
{ try
    {
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/applicants/${action.payload}`,
      method: 'DELETE',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))

yield workergetallcandidat({payload : 1});
yield put(deletecandidatSuccess());

}
  catch(error)
  {
    yield put(deletecandidatFailure(error))
  }  
}

function* workerAcceptRefusecandidat(action)
{
  try
    {
      const data = {
        email : action.payload.email,
        decision: action.payload.decision
      }
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/decision_candidat`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield workergetallcandidat({payload : 1});
  yield put(acceptrefusecandidatSuccess(res.data));
}
  catch(error)
  {
    yield put(acceptrefusecandidatFailure(error.response.status))
  }  
}

function* workergetcandidatbyid(action)
{
  try {
  const data = {
    id : action.payload.id
  }
  const res = yield call(()=> api({
  url: `${process.env.REACT_APP_API_URL}/api/applicants/${action.payload.id}`,
  method: 'GET',
  data: data,
  headers: {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  },
  }));
  yield put(getcandidatbyidSuccess(res.data));
}
  catch(error)
  {
    yield put(getcandidatbyidFailure(error.response.status))
  }  
}

export function* watchercandidat()
{
  yield takeEvery('Getallcandidat', workergetallcandidat)
  yield takeEvery('Addcandidat', workeraddcandidat)
  yield takeEvery('Editcandidat', workereditcandidat)
  yield takeEvery('Deletecandidat', workerdeletecandidat)
  yield takeEvery('filtercandidat', workerfiltercandidat)
  yield takeEvery('AcceptRefuseCandidat', workerAcceptRefusecandidat)
  yield takeEvery('getapplicantbyid', workergetcandidatbyid)
  yield takeEvery('Getallcandidatwithoutpagination', workergetallcandidatwithoutpagination)
  yield takeEvery('Applyapplicant', workerapplycandidat)
}