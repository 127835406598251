import ReactPaginate from 'react-paginate';

export default function Pagination({onPage, totalItems,number}) {
  return (
    <>
      { totalItems > 0 && totalItems > number &&
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        pageCount={Math.ceil(totalItems / number)}
        containerClassName={'flex flex-row gap-2 md:items-center'}
        previousClassName={'flex border border-primary text-base rounded-md hover:bg-primary hover:text-white items-center'}
        previousLinkClassName={'py-2 px-4 cursor-pointer'}
        nextClassName={'flex items-center border border-primary text-base rounded-md hover:bg-primary hover:text-white'}
        nextLinkClassName={'py-2 px-4 cursor-pointer'}
        pageClassName={'flex items-center border border-primary text-base rounded-md hover:bg-primary hover:text-white'}
        pageLinkClassName={'py-2 px-4 cursor-pointer'}
        breakClassName={'p-2 text-base'}
        activeClassName={'border border-primary text-white bg-primary text-base rounded-md'}
        onPageChange={onPage}
      /> 
      } 
    </>
  );

}
