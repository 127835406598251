import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  addofficeSuccess,
  addofficeailure,
  editofficeFailure,
  editofficeSuccess,
  getActiveofficeSuccess,
  getAllofficeFailure,
  getAllofficeSuccess,
  getactiveofficeFailure
} from './OfficeSlice';

const token = localStorage.getItem('token')

function* workergetalloffice(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offices?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllofficeSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllofficeFailure(error.response.status))
  }  
}

function* workergetactiveoffice(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offices?isActive=true&&pagination=false`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getActiveofficeSuccess(res.data));
}
  catch(error)
  {
    yield put(getactiveofficeFailure(error.response.status))
  }  
}

function* workeraddoffice(action)
{
  try
    {
      const data  = {
        office: action.payload.office,
        isActive: action.payload.isActive,
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offices`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield put(addofficeSuccess(data));
}
  catch(error)
  {
    yield put(addofficeailure(error.response.status))
  }  
}

function* workereditoffice(action)
{
  try
    {
      const data  = {
        id: action.payload.id,
        office: action.payload.office,
        isActive: action.payload.isActive,
        createdAt: action.payload.createdAt,
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/offices/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield put(editofficeSuccess(data));
}
  catch(error)
  {
    yield put(editofficeFailure(error.response.status))
  }
}

export function* watcheroffice()
{
  yield takeEvery('Getalloffice', workergetalloffice)
  yield takeEvery('GetActiveoffice', workergetactiveoffice)
  yield takeEvery('Addoffice', workeraddoffice)
  yield takeEvery('Editoffice', workereditoffice) 
}