import { useDispatch, useSelector } from 'react-redux';
import Title from '../components/Title';
import AddButton from '../components/bouttons/AddButton';
import NavBar from '../components/template/NavBar';
import SideBar from '../components/template/SideBar';
import { useEffect , useState, useRef } from 'react';
import { CandidatSagaActions } from '../redux/candidat/CandidatSagaActions';
import Pagination from '../components/Pagination';
import Table from '../components/Table';
import Modal from '../components/forms/Modal';
import { FiInfo, FiSearch } from 'react-icons/fi';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { officeSagaActions } from '../redux/office/OfficeSagaActions';
import { profilSagaActions } from '../redux/profil/ProfilSagaActions';
import { typecontractSagaActions } from '../redux/typecontract/TypecontractSagaAction';
import { statusSagaActions } from '../redux/status/statusSagaAction';
import PDF from '../components/pdf/PDF';
import { RiArrowDownSFill } from 'react-icons/ri';
import FilterComponent from '../components/FilterComponent';
import useClickOutside from '../components/useClickOutside';

function Home () {
  const modalRef = useRef(null);
  const modalRef1 = useRef(null);
  const modalRef2 = useRef(null);
  const dispatch = useDispatch();
  const [showmodal , setshowmodal] = useState(false);
  const [showmodalFeature, setshowmodalFeature] = useState({});
  const [showsidebarPdfViewer , setshowsidebarPdfViewer] = useState(false);
  const [modif , setModif] = useState(false);
  const [candidat] = useState('candidat');
  const [AcceptRefusecandidat] = useState('AcceptRefusecandidat');
  const [Sharecandidat] = useState('Sharecandidat');
  const [isOpenAcceptRefuse, setIsOpenAcceptRefuse] = useState(false);
  const [isOpenShareCandidat, setIsOpenShareCandidat] = useState(false);
  const [isCandidat, setIsCandidat] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showmodalFilter , setshowmodalFilter ]= useState(false);
  const [showmodalFilterPoste , setshowmodalFilterPoste ]= useState(false);
  const [showmodalFilterOffice , setshowmodalFilterOffice ]= useState(false);
  const [showmodalFilterProfil , setshowmodalFilterProfil ]= useState(false);
  const [showmodalFilterTypeContract , setshowmodalFilterTypeContract ]= useState(false);
  const [showmodalFilterStatus , setshowmodalFilterStatus ]= useState(false);
  const [showmodalFilterDev , setshowmodalFilterDev ]= useState(false);
  const [showmodalFilterCREA , setshowmodalFilterCREA  ]= useState(false);
  const [showmodalFilterOPS , setshowmodalFilterOPS ]= useState(false);
  const [showmodalFilterMarketing , setshowmodalFilterMarketing ]= useState(false);
  const [showmodalFilterProjectManagement , setshowmodalFilterProjectManagement ]= useState(false);
  const [ApplicantStatus, setApplicantStatus] = useState('')

  const animatedComponents = makeAnimated();
  const {
    totalItems,
    candidatList
  } = useSelector(
      (state) => state.candidat
  );
  const {
    activeofficeList
  } = useSelector(
      (state) => state.office
  );
  const {
    activeprofilList
  } = useSelector(
      (state) => state.profil
  );
  const {
    activeTypeContractList
  } = useSelector(
      (state) => state.typecontract
  );
  const {
    activeStatusList
  } = useSelector(
      (state) => state.status
  );

  const headers = ['Candidat' , 'CV', 'Poste', 'Bureau', 'Profil', 'Type de contrat', 'Source', 'Statut', 'Créé le', 'Actions']

  const optionsOffice = activeofficeList.map(office => ({
    value: office['@id'],
    label: office.office,
  }));

  const optionsProfil = activeprofilList.map(profil => ({
    value: profil['@id'],
    label: profil.profil
  }));

  const optionsProfilDev = activeprofilList
  .filter((profil => profil?.expertise?.expertise.includes('DEV')))
  .map(profil => ({
    value: profil['@id'],
    label: profil.profil
  }));

  const optionsProfilCREA = activeprofilList
  .filter((profil => profil?.expertise?.expertise.includes('CREA')))
  .map(profil => ({
    value: profil['@id'],
    label: profil.profil
  }));

  const optionsProfilmarketing = activeprofilList
  .filter((profil => profil?.expertise?.expertise.includes('MARKETING')))
  .map(profil => ({
    value: profil['@id'],
    label: profil.profil
  }));

  const optionsProfilOPS = activeprofilList
  .filter((profil => profil?.expertise?.expertise.includes('OPS')))
  .map(profil => ({
    value: profil['@id'],
    label: profil.profil
  }));

  const optionsProfilProjectManagement = activeprofilList
  .filter((profil => profil?.expertise?.expertise.includes('Gestion de projet')))
  .map(profil => ({
    value: profil['@id'],
    label: profil.profil
  }));

  const optionsTypeContract = activeTypeContractList.map(typecontract => ({
    value: typecontract['@id'],
    label: typecontract.typeContract
  }));

  const optionsStatus = activeStatusList.map(status => ({
    value: status['@id'],
    label: status.status
  }));

  const handleshowmodalFilter = () => {
    setshowmodalFilter(!showmodalFilter);
  }

  useEffect(() => {
    dispatch(CandidatSagaActions.getallcandidats(1));
    dispatch(officeSagaActions.getactiveoffice());
    dispatch(profilSagaActions.getactiveprofil());
    dispatch(typecontractSagaActions.getactivetypecontract());
    dispatch(statusSagaActions.getactivestatus());
  }, [dispatch]);

  const handleshowmodal = () => {
    setModif(false);
    setSelectedItem(null)
    setIsCandidat(!isCandidat)
    setshowmodal(!showmodal);
  }

  const handleshowFilterPoste = () => {
    setshowmodalFilterPoste(!showmodalFilterPoste);
  }

  const handleshowFilterOffice = () => {
    setshowmodalFilterOffice(!showmodalFilterOffice);
  }

  const handleshowFilterProfil = () => {
    setshowmodalFilterProfil(!showmodalFilterProfil);
  }

  const handleshowFilterTypeContract = () => {
    setshowmodalFilterTypeContract(!showmodalFilterTypeContract);
  }

  const handleshowFilterStatus = () => {
    setshowmodalFilterStatus(!showmodalFilterStatus);
  }

  const handleshowmodalFilterDev = () => {
    setshowmodalFilterDev(!showmodalFilterDev);
  }

  const handleshowmodalFilterCrea = () => {
    setshowmodalFilterCREA(!showmodalFilterCREA);
  }

  const handleshowmodalFilterOps = () => {
    setshowmodalFilterOPS(!showmodalFilterOPS);
  }

  const handleshowmodalFilterMarketing = () => {
    setshowmodalFilterMarketing(!showmodalFilterMarketing);
  }

  const handleshowmodalFilterProjectManagement = () => {
    setshowmodalFilterProjectManagement(!showmodalFilterProjectManagement);
  }

  const handleshowmodalEdit = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodal(!showmodal);
    setIsCandidat(!isCandidat)
  }

  const handleshowmodalFeatures = (item) => {
    setSelectedItem(item)
    setshowmodalFeature(prevState => ({
      ...prevState,
      [item.id]: !prevState[item.id]
    }));
  }

  const handleshowsidebarCV = (item) => {
    setSelectedItem(item)
    setshowsidebarPdfViewer(!showsidebarPdfViewer);
  }

  const handleclosesidebar = () => {
    setSelectedItem(null)
    setshowsidebarPdfViewer(!showsidebarPdfViewer);
  }

  const handleAcceptRefuseCandidat = (item) => {
    setSelectedItem(item);
    setIsOpenAcceptRefuse(!isOpenAcceptRefuse);
    setshowmodal(!showmodal);
  }

  const handleshareCandidat = (item) => {
    setSelectedItem(item);
    setIsOpenShareCandidat(!isOpenShareCandidat);
    setshowmodal(!showmodal);
  }

  function onPageChange(currentPage) {
    if(ApplicantStatus === '')
    {
      dispatch(CandidatSagaActions.getallcandidats(currentPage.selected + 1));
      setApplicantStatus('');
    }
    else if(ApplicantStatus === 'Recruté(e)')
    {
      dispatch(CandidatSagaActions.filtercandidat({
        responseoffer : 'Recruté(e)',
        page : currentPage.selected + 1
      }));
    }
    else if(ApplicantStatus === 'Refusé(e)')
    {
      dispatch(CandidatSagaActions.filtercandidat({
        responseoffer : 'Refusé(e)',
        page : currentPage.selected + 1
      }));
    } 
    else if(ApplicantStatus === 'enprocess')
    {
      dispatch(CandidatSagaActions.filtercandidat({
        enprocessoffer : '',
        page : currentPage.selected + 1
      }));
    } 
  }

  function deleteCandidat(e,item) {
    e.preventDefault();
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      dispatch(CandidatSagaActions.deletecandidat(item));
    }
  }

  const handlesearch = (e) => {
    const { name, value  } = e.target;
    if(name === 'poste')
    {
      dispatch(CandidatSagaActions.filtercandidat({
        poste : value ,
      }));
    }
    else
    {
      dispatch(CandidatSagaActions.filtercandidat({
        contact : value ,
      }));
    }
  };

  const handlesearchDev = () => {
    dispatch(CandidatSagaActions.filtercandidat({
      Dev : 'Dev'
    }))
  }

  const handlesearchCrea = () => {
    dispatch(CandidatSagaActions.filtercandidat({
      Crea : 'Crea'
    }))
  }

  const handlesearchMarketing = () => {
    dispatch(CandidatSagaActions.filtercandidat({
      Marketing : 'Marketing'
    }))
  }

  const handlesearchOps = () => {
    dispatch(CandidatSagaActions.filtercandidat({
      Ops : 'OPS'
    }))
  }

  const handlesearchProjectManagement = () => {
    dispatch(CandidatSagaActions.filtercandidat({
      ProjectManagement : 'Gestion de projet'
    }))
  }
 
  const handlesearchSelect = (name, select) => {
    if(name === 'office')
    {
      dispatch(CandidatSagaActions.filtercandidat({
        office : select.label ,
      }));
    }
    if(
      name === 'profil' 
      || name === 'profildev' 
      || name === 'profilcrea' 
      || name === 'profilops' 
      || name === 'profilprojectmanagement'
      || name === 'profilmarketing'
      ) 
    {
      dispatch(CandidatSagaActions.filtercandidat({
        profil : select.label ,
      }));
    }
    if(name === 'typecontract')
    {
      dispatch(CandidatSagaActions.filtercandidat({
        typecontract : select.label,
      }));
    }
    if(name === 'status')
    {
      dispatch(CandidatSagaActions.filtercandidat({
        status : select.label,
      }));
    }
  }

  const handlesearchTous = () => {
    dispatch(CandidatSagaActions.getallcandidats(1));
    setApplicantStatus('');
  }

  const handlesearchTreatd = () => {
    dispatch(CandidatSagaActions.filtercandidat({
      enprocessoffer : '',
      page: 1
    }))
    setApplicantStatus('')
    setApplicantStatus('enprocess')
  }

  const handlesearchRejected = () => {
    dispatch(CandidatSagaActions.filtercandidat({
      responseoffer : 'Refusé(e)',
      page: 1
    }))
    setApplicantStatus('')
    setApplicantStatus('Refusé(e)')
  }

  const handlesearchAccepted = () => {
    dispatch(CandidatSagaActions.filtercandidat({
      responseoffer : 'Recruté(e)',
      page: 1
    }))
    setApplicantStatus('')
    setApplicantStatus('Recruté(e)')
  }
  
  const handleClickOutside = (ref) => {
    if (ref === modalRef)
    {
      setshowmodal(false);
      setIsCandidat(false);
    }
    else if (ref === modalRef1) {
      setshowmodal(false);
      setIsOpenShareCandidat(false);
    } else if (ref === modalRef2) {
      setshowmodal(false);
      setIsOpenAcceptRefuse(false);
    }
  };

  useClickOutside({ refs : [modalRef,modalRef1, modalRef2], onClickOutside: handleClickOutside});

  return (
    <>
      <NavBar />
      <SideBar />
      <div className='flex flex-col gap-14 p-4 lg:ml-64 mt-14'>
        <div className="flex flex-col lg:flex-row">
          <Title 
            title={'Liste des candidats'}
            className={'font-bold text-xl w-[20%]'}
          />
          <div className='flex flex-wrap lg:flex-row items-center w-full gap-6'>
            <FilterComponent
                nameFilter={'DEV'}
                animatedComponents={animatedComponents}
                classRelative={'relative z-20'}
                handlesearchSelect={(e) => handlesearchSelect('profildev' , e)}
                options={optionsProfilDev}
                showmodalFilter={showmodalFilterDev}
                handleshowmodalFilter={handleshowmodalFilterDev}
                width={'w-36 lg:w-36'}
                handlesearchButton={handlesearchDev}
            />
            <FilterComponent
                nameFilter={'CREA'}
                animatedComponents={animatedComponents}
                classRelative={'relative z-20'}
                handlesearchSelect={(e) => handlesearchSelect('profilcrea' , e)}
                options={optionsProfilCREA}
                showmodalFilter={showmodalFilterCREA}
                handleshowmodalFilter={handleshowmodalFilterCrea}
                width={'w-36 lg:w-36'}
                handlesearchButton={handlesearchCrea}
            />
            <FilterComponent
                nameFilter={'OPS'}
                animatedComponents={animatedComponents}
                classRelative={'relative z-10'}
                handlesearchSelect={(e) => handlesearchSelect('profilops' , e)}
                options={optionsProfilOPS}
                showmodalFilter={showmodalFilterOPS}
                handleshowmodalFilter={handleshowmodalFilterOps}
                width={'w-36 lg:w-36'}
                handlesearchButton={handlesearchOps}
            />
            <FilterComponent
                nameFilter={'Marketing'}
                animatedComponents={animatedComponents}
                classRelative={'relative z-10'}
                handlesearchSelect={(e) => handlesearchSelect('profilmarketing' , e)}
                options={optionsProfilmarketing}
                showmodalFilter={showmodalFilterMarketing}
                handleshowmodalFilter={handleshowmodalFilterMarketing}
                width={'w-36 lg:w-36'}
                handlesearchButton={handlesearchMarketing}
            />
            <FilterComponent
                nameFilter={'Gestion de projet'}
                animatedComponents={animatedComponents}
                classRelative={'relative'}
                handlesearchSelect={(e) => handlesearchSelect('profilprojectmanagement' , e)}
                options={optionsProfilProjectManagement}
                showmodalFilter={showmodalFilterProjectManagement}
                handleshowmodalFilter={handleshowmodalFilterProjectManagement}
                width={'w-44 lg:w-44'}
                handlesearchButton={handlesearchProjectManagement}
            />
          </div>  
        </div>
        <div className='w-full flex flex-col md:flex-row justify-between items-center py-3 gap-2'>
            <AddButton
              onClick={handleshowmodal}
              Name='Ajouter un candidat'
            />
            <div className='flex flex-col lg:flex-row lg:justify-start justify-center gap-6'>
              <div className='relative'>
                <button  
                  className={`inline-flex items-center justify-center  h-full w-36 lg:w-36 text-gray-500 bg-white border border-gray-300  hover:bg-gray-100 focus:ring-teal-500 focus:border-teal-500  font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-teal-700`}
                  onClick={handlesearchTous}
                >
                  Tous
                </button>
              </div>
              <div className='relative'>
                <button  
                  className={`inline-flex items-center justify-center h-full w-36 lg:w-36 text-white bg-[#3f23cd] border border-gray-300  hover:bg-[#3f23cd] focus:ring-teal-500 focus:border-teal-500  font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-teal-700`}
                  onClick={handlesearchTreatd}
                >
                    En process
                </button>
              </div>
              <div className='relative'>
                <button  
                  className={`inline-flex items-center justify-center h-full w-36 lg:w-36 text-white bg-[#e51818] border border-bg-[#e51818]  hover:bg-bg-[#e51818] focus:ring-teal-500 focus:border-teal-500  font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-teal-700`}
                  onClick={handlesearchRejected}
                >
                    Refusé(e)
                </button>
              </div>
              <div className='relative'>
                <button  
                  className={`inline-flex items-center justify-center h-full w-36 lg:w-36 text-white bg-[#24c606] border border-[#24c606]  hover:bg-[#24c606] focus:ring-teal-500 focus:border-teal-500  font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-teal-700`}
                  onClick={handlesearchAccepted}
                >
                    Recruté(e) 
                </button>
              </div>
            </div> 
            <div className='flex flex-col lg:flex-row md:justify-items-center gap-4'>
              <div className='relative z-10'>
                <button  
                  className='inline-flex items-center h-full w-full lg:w-36 text-gray-500 bg-white border border-gray-300  hover:bg-gray-100 focus:ring-teal-500 focus:border-teal-500  font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-teal-700'
                  onClick={handleshowmodalFilter}
                >
                    Filtrer par :
                </button>
                <div className='absolute top-2 right-2 cursor-pointer'>
                      <RiArrowDownSFill />
                </div>
                {showmodalFilter &&
                <div className='border border-gray-400 absolute top-10 rounded-md shadow-lg bg-white divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600 w-36 lg:w-36'>
                  <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
                    <li className='flex flex-col'>
                      <div className='flex items-center'>
                        <div onClick={handleshowFilterPoste} className='absolute right-2 cursor-pointer'>
                          <RiArrowDownSFill />
                        </div>
                        <label className='px-4 py-4 font-medium text-sm'>Poste</label>
                      </div>
                    { showmodalFilterPoste &&
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none'>
                          <FiSearch />
                        </div>
                        <input 
                          type='text'
                          className='p-2 m-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-32 lg:w-32 bg-gray-50 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-teal-500'
                          placeholder='poste'
                          name='poste'
                          onChange={handlesearch}
                        />
                      </div>
                    }
                    </li>
                    <li className='flex flex-col'>
                      <div className='flex items-center'>
                        <div onClick={handleshowFilterOffice} className='absolute right-2 cursor-pointer'>
                          <RiArrowDownSFill />
                        </div>
                        <label className='px-4 py-4 font-medium text-sm'>Bureau</label>
                      </div>
                      {showmodalFilterOffice &&  
                      <Select
                        options={optionsOffice}
                        components={animatedComponents}
                        className='basic-multi-select p-2 m-2'
                        onChange={(e) => handlesearchSelect('office' , e)} 
                      />
                      }
                    </li>
                    <li className='flex flex-col'>
                      <div className='flex items-center'>
                        <div onClick={handleshowFilterProfil} className='absolute right-2 cursor-pointer'>
                          <RiArrowDownSFill />
                        </div>
                        <label className='px-4 py-4 font-medium text-sm'>Profil</label>
                      </div>  
                      {showmodalFilterProfil && 
                      <Select
                        options={optionsProfil}
                        components={animatedComponents}
                        className='basic-multi-select p-2 m-2'
                        onChange={(e) => handlesearchSelect('profil' , e)} 
                      />
                    }
                    </li>
                    <li className='flex flex-col'>
                        <div className='flex items-center'>
                          <div onClick={handleshowFilterTypeContract} className='absolute right-2 cursor-pointer'>
                            <RiArrowDownSFill />
                          </div>
                        <label className='px-4 py-4 font-medium text-sm'>Type de contrat</label>
                      </div>
                      {showmodalFilterTypeContract && 
                        <Select
                          options={optionsTypeContract}
                          components={animatedComponents}
                          className='basic-multi-select p-2 m-2'
                          onChange={(e) => handlesearchSelect('typecontract' , e)} 
                        />
                      }
                    </li>
                    <li className='flex flex-col'>
                      <div className='flex items-center'>
                        <div onClick={handleshowFilterStatus} className='absolute right-2 cursor-pointer'>
                          <RiArrowDownSFill />
                        </div>
                        <label className='px-4 py-4 font-medium text-sm'>Statut</label>
                      </div>
                      {showmodalFilterStatus && 
                      <Select
                        options={optionsStatus}
                        components={animatedComponents}
                        className='basic-multi-select p-2 m-2'
                        onChange={(e) => handlesearchSelect('status' , e)} 
                      />
                      }
                    </li>
                  </ul>
                </div>
                }
              </div>
              <div className='relative'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                  <FiSearch />
                </div>
                <input 
                  type='text'
                  className='p-2 pl-10 text-sm md:w-72 w-full   text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-teal-500'
                  placeholder='Rechercher par nom prénom email'
                  name='contact'
                  onChange={handlesearch}
                />
              </div>
            </div>
        </div>
        <div className='min-h-fit'>
          {isCandidat && <Modal refer={modalRef} config={candidat} modif={modif} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} isCandidat={isCandidat} setIsCandidat={setIsCandidat} />}
          {isOpenAcceptRefuse && <Modal refer={modalRef2} config={AcceptRefusecandidat} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} isOpenAcceptRefuse={isOpenAcceptRefuse} setIsOpenAcceptRefuse={setIsOpenAcceptRefuse} />}    
          {isOpenShareCandidat && <Modal refer={modalRef1} config={Sharecandidat} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} isOpenShareCandidat={isOpenShareCandidat} setIsOpenShareCandidat={setIsOpenShareCandidat} />}    
          {showsidebarPdfViewer && <PDF cv={`${process.env.REACT_APP_API_URL}/media/${selectedItem.mediaObject.imagePath}`} showSidebar={showsidebarPdfViewer} handleclosesidebar={handleclosesidebar} />}  
          <div className='overflow-x-auto shadow-md sm:rounded-lg'>
            {candidatList?.length === 0 ?
              <div className='flex justify-center items-center bg-teal-100 rounded-lg p-5 text-sm text-teal-700 gap-4'>
                <FiInfo />
                <p>
                  Aucun objet n'a été trouvé!
                </p>
              </div>
            :
            <Table
              List={candidatList}
              headers={headers}
              classNameTable={'w-full text-sm text-center text-gray-500 dark:text-gray-400 truncate'}
              classNamethead={'text-xs text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
              classNameth={'px-6 py-3'}
              handleshowmodalFeatures={handleshowmodalFeatures}
              handleshowmodalEdit={handleshowmodalEdit}
              name={candidat}
              handleshowsidebarCV={handleshowsidebarCV}
              deleteCandidat = {deleteCandidat}
              handleAcceptRefuseCandidat={handleAcceptRefuseCandidat}
              showsidebarPdfViewer={showsidebarPdfViewer}
              showmodalFeature={showmodalFeature}
              handleshareCandidat={handleshareCandidat}
            />
            } 
          </div>
        </div> 
        <div className='flex justify-center md:justify-center'>
          <Pagination
            onPage={onPageChange}
            totalItems={totalItems}
            number={10}
          />
      </div>   
    </div>  
  </>
  )
}
export default Home
