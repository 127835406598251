import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  TypeContractList: [],
  activeTypeContractList: [],
  success: false,
  error: null,
}

const TypeContractSlice = createSlice ({
  name: 'typecontract',
  initialState,
  reducers: {
    getAlltypecontractSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.TypeContractList = action.payload['hydra:member'];  
      state.success = true;
    },
    getActivetypecontractSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.activeTypeContractList = action.payload['hydra:member'];
    },
    getactivetypecontractFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getAlltypecontractFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    addtypecontractSuccess(state, action)
    {
      state.success = true;
      state.error = false;
      state.TypeContractList = [...state.TypeContractList, action.payload]
    },
    addtypecontractailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    edittypecontractSuccess(state, action)
    {
      state.success = true;
      state.TypeContractList = state.TypeContractList.map(i => i.id === action.payload.id ? action.payload : i)
    },
    edittypecontractFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    }
  }
})

export const {
  getAlltypecontractSuccess,
  getAlltypecontractFailure,
  addtypecontractSuccess,
  addtypecontractailure,
  edittypecontractSuccess,
  edittypecontractFailure,
  getActivetypecontractSuccess,
  getactivetypecontractFailure
  } = TypeContractSlice.actions;

export default  TypeContractSlice.reducer