import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  expertiseList: [],
  success: false,
  error: null,
  expertise: null
}

const expertiseSlice = createSlice ({
  name: 'expertise',
  initialState,
  reducers: {
    getAllexpertiseSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.expertiseList = action.payload['hydra:member'];  
      state.success = true;
    },
    getAllexpertiseFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    }, 
  }
})

export const {
  getAllexpertiseSuccess,
  getAllexpertiseFailure,
  } = expertiseSlice.actions;

export default  expertiseSlice.reducer