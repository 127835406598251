import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  mediaobjectList: [],
  success: false,
  error: null,
}

const mediaobjectSlice = createSlice ({
  name: 'mediaobject',
  initialState,
  reducers: {
    getAllmediaobjectSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.mediaobjectList = action.payload['hydra:member'];  
      state.success = true;
    },
    getAllmediaobjectFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    }
  }
})

export const {
  getAllmediaobjectSuccess,
  getAllmediaobjectFailure
  } = mediaobjectSlice.actions;

export default  mediaobjectSlice.reducer