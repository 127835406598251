

function ValidationsApply(data) {
  const errors = [];

  if (data.firstname.length === 0) {
    errors.push('Le champ Nom est obligatoire!');
  }
  if (data.lastname.length === 0) {
    errors.push('Le champ Prénom est obligatoire!');
  }
  if (data.email.length === 0) {
    errors.push('Le champ Email est obligatoire!');
  }
  if (data.mediaObject.length === 0) {
    errors.push('Le champ media est obligatoire!');
  }

  return errors;
}

export default ValidationsApply
