import { FiCheck, FiX } from 'react-icons/fi';
import GoBackButton from './bouttons/GoBackButton';

function AcceptRefuseCandidat({
  acceptcandidat,
  refusercandidat,
  handleclosemodal
  }) {
  return (
      <>
      <div className='flex flex-col'>
        <div className="flex justify-evenly">
          <button onClick={acceptcandidat} className='flex flex-row gap-2 items-center text-white bg-gradient-to-br from-teal-600 to-teal-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2'>
              <FiCheck />
                Accepter 
          </button>
          <button onClick={refusercandidat}  className='flex flex-row gap-2 items-center text-white bg-gradient-to-br from-red-600 to-red-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2'>
            <FiX />
                Refuser 
          </button>
        </div>
        <div className='flex justify-center p-2'> 
          <GoBackButton
              className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
              handleclosemodal={handleclosemodal}
              title={'Annuler'}
            />
          </div>  
      </div>
      </>
  )
}

export default AcceptRefuseCandidat
