import { FiEdit2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';

export default function EditDateRow({list, onCheck, handleshowmodalEdit}) {
  return (
    <>
    <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
      <input
        type='checkbox'
        checked={list.isActive || false }
        onChange={() => onCheck(list)}
        className='w-6 h-6 accent-primary cursor-pointer'
      />
    </td>
    <td className='flex items-center px-6 py-4 text-right font-medium'>
      <Link className='mr-3'>
        <FiEdit2 onClick={() => handleshowmodalEdit(list)} />
      </Link>
    </td>
  </>  
  )
}
