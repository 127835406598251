import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { officeSagaActions } from '../redux/office/OfficeSagaActions';
import NavBar from '../components/template/NavBar';
import SideBar from '../components/template/SideBar';
import Title from '../components/Title';
import AddButton from '../components/bouttons/AddButton';
import Modal from '../components/forms/Modal';
import Table from '../components/Table';
import Pagination from '../components/Pagination';
import EmptyTable from '../components/EmptyTable';
import useClickOutside from '../components/useClickOutside';

function Office() {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [showmodal , setshowmodal] = useState(false);
  const [modif , setModif] = useState(false);
  const [office] = useState('office');
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    totalItems,
    officeList
  } = useSelector(
      (state) => state.office
  );
  const headers = ['Titre' , 'Activé', 'Actions']

  useEffect(() => {
    dispatch(officeSagaActions.getalloffice(1));
  }, [dispatch]);

  const handleshowmodal = () => {
    setModif(false);
    setSelectedItem(null)
    setshowmodal(!showmodal);
  }

  const handleshowmodalEdit = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodal(!showmodal);
  }

  function onPageChange(currentPage) {
    dispatch(officeSagaActions.getalloffice(currentPage.selected + 1));
  }

  function onCheck(val) {
    const state = val.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} ce bureau?`)) {
      const isActive = {...val, isActive : !val.isActive};
      dispatch(officeSagaActions.editoffice({
        id: isActive.id,
        office: isActive.office,
        isActive: isActive.isActive,
        createdAt: isActive.createdAt,
      }
      ));
    }
  }

  const handleClickOutside = (ref) => {
    setshowmodal(false);
  };

  useClickOutside({refs:[modalRef], onClickOutside:handleClickOutside});

  return (
    <>
      <NavBar />
      <SideBar />
      <div className='flex flex-col gap-12 p-4 lg:ml-64 mt-14'>
        <Title 
          title={'Liste des bureaux'}
          className={'font-bold text-3xl'}
        />
        <AddButton
          onClick={handleshowmodal}
          Name='Ajouter un bureau'
        />
        <div className='min-h-fit'>
          {showmodal && <Modal refer={modalRef} config={office} modif={modif} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} />}   
          <div className='overflow-x-auto shadow-md sm:rounded-lg'>
            {officeList.length === 0 ?
              <EmptyTable />
              :
              <Table
                List={officeList}
                headers={headers}
                classNameTable={'w-full text-sm text-left text-gray-500 dark:text-gray-400'}
                classNamethead={'text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
                classNameth={'px-6 py-3'}
                handleshowmodalEdit={handleshowmodalEdit}
                onCheck={onCheck}
                name={office}
              />
              }
          </div>
        </div>
        <div className='flex justify-center md:justify-center'>
          <Pagination
            onPage={onPageChange}
            totalItems={totalItems}
            number={10}
          />
        </div>   
      </div>
    </>
  )
}

export default Office
