import { FiCheck, FiShare2, FiXCircle } from "react-icons/fi"

function ShowModalFeature({
  handleAcceptRefuseCandidat,
  handleshareCandidat,
  list,
}) 
{
  return (
  <>
    <div className='border border-gray-400 rounded-md shadow-lg fixed right-2 bg-white divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600 mt-5 px-2.5 py-2.5'>
      <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
        <li className='flex flex-col justify-center gap-2 py-2'>
          {
            list.status.status === 'Entretien 1' || list.status.status === 'Refusé(e)' ? (
              <>
              <button 
                className='flex gap-2 justify-center text-white  px-5 py-2.5 text-center bg-gradient-to-br from-teal-600 to-teal-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 rounded-lg text-sm font-medium disabled:opacity-25'
                disabled
              >
                <FiCheck />
                <FiXCircle />
              </button>
              </>
              ) : (
                <div 
                onClick={() => handleAcceptRefuseCandidat(list)}
                className='flex gap-2 justify-center text-white  px-5 py-2.5 text-center bg-gradient-to-br from-teal-600 to-teal-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 rounded-lg text-sm font-medium '
              >
                <FiCheck />
                <FiXCircle />
              </div>
            )  
          }
          {
            list.status.status === 'Refusé(e)' ? (
              <button 
                className='flex justify-center text-white  py-2.5 text-center bg-gradient-to-br from-orange-600 to-orange-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 rounded-lg disabled:opacity-25'
                disabled
              >
                <FiShare2 />
              </button>
            ) :
            (
              <div 
                onClick={() => handleshareCandidat(list)}
                className='flex justify-center text-white  py-2.5 text-center bg-gradient-to-br from-orange-600 to-orange-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 rounded-lg'
              >
                <FiShare2 />
              </div>
            )
          }
        </li>
      </ul>
    </div>
  </>
  )
}

export default ShowModalFeature