import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  officeList: [],
  activeofficeList: [],
  success: false,
  error: null,
}

const officeSlice = createSlice ({
  name: 'office',
  initialState,
  reducers: {
    getAllofficeSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.officeList = action.payload['hydra:member'];  
      state.success = true;
    },
    getActiveofficeSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.activeofficeList = action.payload['hydra:member'];
    },
    getactiveofficeFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getAllofficeFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    addofficeSuccess(state, action)
    {
      state.success = true;
      state.error = false;
      state.officeList = [...state.officeList, action.payload]
    },
    addofficeailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    editofficeSuccess(state, action)
    {
      state.success = true;
      state.officeList = state.officeList.map(i => i.id === action.payload.id ? action.payload : i)
    },
    editofficeFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    }
  }
})

export const {
  getAllofficeSuccess,
  getAllofficeFailure,
  addofficeSuccess,
  addofficeailure,
  editofficeSuccess,
  editofficeFailure,
  getActiveofficeSuccess,
  getactiveofficeFailure
  } = officeSlice.actions;

export default  officeSlice.reducer