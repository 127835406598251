import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  feedbackList: [],
  success: false,
  error: null,
  feedback: null
}

const feedbackSlice = createSlice ({
  name: 'feedback',
  initialState,
  reducers: {
    getAllfeedbackSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.feedbackList = action.payload['hydra:member'];  
      state.success = true;
    },
    getActivefeedbackSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.activefeedbackList = action.payload['hydra:member'];
    },
    getactivefeedbackFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getAllfeedbackFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    addfeedbackSuccess(state, action)
    {
      state.success = 'Feedback added with success !';
      state.error = false;
      state.feedbackList = [...state.feedbackList, action.payload]
    },
    addfeedbackailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        state.error = 'unauthorized';
      }
      else
      {
        state.error = 'something else happened !';
      }
    },
    editfeedbackSuccess(state, action)
    {
      state.success = true;
      state.error = false;
    },
    editfeedbackFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    deletefeedbackSuccess(state, action)
    {
      state.success = true;
      state.feedbackList = state.feedbackList.filter((feedback) => feedback.id !== action.payload);
    },
    deletefeedbackFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
  }
})

export const {
  getAllfeedbackSuccess,
  getAllfeedbackFailure,
  addfeedbackSuccess,
  addfeedbackailure,
  editfeedbackSuccess,
  editfeedbackFailure,
  deletefeedbackSuccess,
  deletefeedbackFailure,
  } = feedbackSlice.actions;

export default  feedbackSlice.reducer