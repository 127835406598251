import moment from 'moment'

export default function CreateDateRow({list}) {
  return (
    <>
    <td className='px-6 py-4 truncate'>
        {moment(list).format('DD-MM-YYYY')}
    </td>
    </>
  )
}
