import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  addreferenceSuccess,
  addreferenceailure,
  deletereferenceFailure,
  deletereferenceSuccess,
  editreferenceFailure,
  editreferenceSuccess,
  getAllreferenceFailure,
  getAllreferenceSuccess,
} from './ReferenceSlice';

const token = localStorage.getItem('token')

function* workergetallreference(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/references?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
  }
))
  yield put(getAllreferenceSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllreferenceFailure(error.response.status))
  }  
}

function* workeraddreference(action)
{
  try
    {
      const data  = {
        contactName : action.payload.contactName,
        contactEmail:  action.payload.contactEmail,
        contactCellphone:  action.payload.contactCellphone,
        contactCompany:  action.payload.contactCompany,
        comment: action.payload.comment,
        isApproved:  action.payload.isApproved,
        applicant: action.payload.applicant
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/references`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetallreference({payload : 1});  
  yield put(addreferenceSuccess(data));
}
  catch(error)
  {
    yield put(addreferenceailure(error.response.status))
  }  
}

function* workereditreference(action)
{
  try
    {
      const data  = {
        id: action.payload.id,
        contactName : action.payload.contactName,
        contactEmail:  action.payload.contactEmail,
        contactCellphone:  action.payload.contactCellphone,
        contactCompany:  action.payload.contactCompany,
        comment: action.payload.comment,
        isApproved:  action.payload.isApproved,
        applicant: action.payload.applicant
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/references/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetallreference({payload : 1});  
  yield put(editreferenceSuccess(data));
}
  catch(error)
  {
    yield put(editreferenceFailure(error.response.status))
  }
}

function* workerdeletereference(action)
{ try
    {
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/references/${action.payload}`,
      method: 'DELETE',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))

yield workergetallreference({payload : 1});
yield put(deletereferenceSuccess());

}
  catch(error)
  {
    yield put(deletereferenceFailure(error))
  }  
}

export function* watcherreference()
{
  yield takeEvery('Getallreference', workergetallreference)
  yield takeEvery('Addreference', workeraddreference)
  yield takeEvery('Deletereference', workerdeletereference)
  yield takeEvery('Editreference', workereditreference) 
}