export const offerSagaActions = {
  getalloffers,
  addoffer,
  editoffer,
  deleteoffer,
  filteroffer,
  getofferbyid
};

function getalloffers(payload)
{
  return {
    type: 'Getalloffer',
    payload
  }
}

function addoffer(payload)
{
  return {
    type: 'Addoffer',
    payload,
  }
}  

function editoffer(payload)
{
  return {
    type: 'Editoffer',
    payload,
  }
}

function deleteoffer(payload)
{
  return {
    type: 'Deleteoffer',
    payload,
  }
}

function filteroffer(payload)
{
  return {
    type: 'filteroffer',
    payload
  }
}

function getofferbyid(payload)
{
  return {
    type: 'getofferbyid',
    payload,
  }
}