export const statusSagaActions = {
    getallstatus,
    addstatus,
    editstatus,
    getactivestatus
  };

function getallstatus(payload)
{
  return {
    type: 'Getallstatus',
    payload
  }
}

function getactivestatus(payload)
{
  return {
    type: 'GetActivestatus',
    payload
  }
} 

function addstatus(payload,color)
{
  return {
    type: 'Addstatus',
    payload,
    color
  }
}  

function editstatus(payload, color)
{
  return {
    type: 'Editstatus',
    payload,
    color
  }
}