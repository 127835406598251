import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function Password({ onClick , onChange, showPassword, passwordError, value, name}) {

  return (
    <div className='relative'>
      <div className='absolute inset-y-0 right-0 flex items-center px-2'>
        <button onClick={onClick}>
          { showPassword ? <FaEyeSlash /> : <FaEye />}
        </button>
      </div>
      <div>
        <input 
          type={showPassword ? 'text' : 'password'} 
          className='block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-teal-500 focus:outline-none focus:ring-0 focus:border-teal-500 peer'
          placeholder='Mot de Passe'
          value={value}
          onChange={onChange}
          error={passwordError}
          name={name}
        />
      </div>
    </div>
  )
}
