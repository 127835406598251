import api from '../../hooks/Api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { 
  ChangePasswordFailure,
  ChangePasswordSuccess,
  GetMeSuccess,
  loginFailure,
  loginSuccess,
  ResetSuccess,
} from './authSlice';

function* workerLoginUser(action)
{
  try
    {
      const data = yield call(()=> api({
        url: `${process.env.REACT_APP_API_URL}/authentication_token`,
        method: 'POST',
        data: action.payload
    }
    ))
      yield put(loginSuccess(data));
    }
    catch(error)
    {
      yield put(loginFailure(error.response.status))
    }
}

function* workerChangePassword(action)
{ 
  try {
    const data = yield call(()=> api({
        url: `${process.env.REACT_APP_API_URL}/api/change_password`,
        method: 'POST',
        data: action.payload
      }
      ))
      yield put(ChangePasswordSuccess(data))
  }
  catch(error)
  {
    yield put(ChangePasswordFailure(error.response.status))
  }    
}

function* workerResetPassword(action)
{
  
  try {
    const data  = { email: action.payload} 
    yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/reset_password`,
      method: 'POST',
      data: data
    }
    ));
    yield put(ResetSuccess())
  } 
  catch(error)
  {}   
}

function* workerGetMe()
{
  try {
     const token = localStorage.getItem('token');
     const response = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/me`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    ));
    yield put(GetMeSuccess(response))
  } 
  catch(error)
  {
  }   
}

export function* watcherLogin()
{
  yield takeEvery('LOGIN', workerLoginUser)
  yield takeEvery('ChangePassword', workerChangePassword)
  yield takeEvery ('RESET', workerResetPassword)
  yield takeEvery('GETME', workerGetMe)
}