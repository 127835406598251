import EditDateRow from './EditDateRow';

export default function StatusRow({list, onCheck, handleshowmodalEdit}) {
  return (
  <>
    <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '>
      <td className='px-6 py-4 font-medium'>
        {list?.status}
      </td>
      <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
      <span style={{backgroundColor: list?.codecouleur ? list.codecouleur : 'black' }}  className={'truncate text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'}>
      </span>
      </td>
      <EditDateRow list={list} onCheck={onCheck} handleshowmodalEdit={handleshowmodalEdit} />
    </tr>
  </>
  )
}
