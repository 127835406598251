export const historySagaActions = {
  getallhistory,
};

function getallhistory(payload)
{
  return {
    type: 'Getallhistory',
    payload
  }
}