import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { watcherLogin } from './auth/authSaga';
import authsReducer from '../redux/auth/authSlice';
import statusReducer from './status/statusSlice';
import { watcherstatus } from './status/statusSaga';
import typecontractReducer from './typecontract/TypeContractSlice';
import { watcherTypeContract } from './typecontract/TypeContractSaga';
import { watcherprofil } from './profil/ProfilSaga';
import profilReducer from './profil/profilSlice';
import { watchersource } from './source/SourceSaga';
import sourceReducer from './source/SourceSlice';
import officeReducer from './office/OfficeSlice';
import { watcheroffice } from './office/OfficeSaga';
import { watcherusers } from './users/UsersSaga';
import usersReducer from './users/UsersSlice';
import { watchercandidat } from './candidat/CandidatSaga';
import candidatReducer from './candidat/CandidatSlice';
import { watchermediaobject } from './mediaobject/mediaobjectSaga';
import mediaobjectReducer from './mediaobject/mediaobjectSlice';
import applicantUserReducer from './ApplicantUser/ApplicantUserSlice';
import { watcherapplicantUser } from './ApplicantUser/ApplicantUserSaga';
import feedbackReducer from './Feedback/FeedbackSlice';
import { watcherfeedback } from './Feedback/FeedbackSaga';
import referenceReducer from './Reference/ReferenceSlice';
import { watcherreference } from './Reference/ReferenceSaga';
import { watcherremuneration } from './Remuneration/RemunerationSaga';
import remunerationReducer from './Remuneration/RemunerationSlice';
import { watcherhistory } from './History/HistorySaga';
import historyReducer from './History/HistorySlice';
import { watcherexpertise } from './Expertise/ExpertiseSaga';
import expertiseReducer from './Expertise/ExpertiseSlice';
import notificationReducer from './Notification/NotificationSlice';
import { watchernotification } from './Notification/NotificationSaga';
import { watcherpowerbi } from './Powerbi/PowerbiSaga';
import powerbiReducer from './Powerbi/PowerbiSlice';
import offerReducer from './Offers/OffersSlice';
import { watcheroffer } from './Offers/OfferSaga';
import applicantofferReducer from './ApplicantOffer/ApplicantOfferSlice';
import { watcherapplicantoffer } from './ApplicantOffer/ApplicantOfferSaga';

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authsReducer,
    status: statusReducer,
    typecontract: typecontractReducer,
    profil: profilReducer,
    source: sourceReducer,
    office: officeReducer,
    users: usersReducer,
    mediaobject: mediaobjectReducer,
    candidat: candidatReducer,
    applicantUser: applicantUserReducer,
    feedback: feedbackReducer,
    reference: referenceReducer,
    remuneration: remunerationReducer,
    history: historyReducer,
    expertise: expertiseReducer,
    notification: notificationReducer,
    powerbi: powerbiReducer,
    offer: offerReducer,
    applicantoffer: applicantofferReducer
  },
  middleware: [sagaMiddleware],
  devTools:true
});

sagaMiddleware.run(watcherLogin);
sagaMiddleware.run(watcherstatus);
sagaMiddleware.run(watcherTypeContract);
sagaMiddleware.run(watcherprofil);
sagaMiddleware.run(watchersource);
sagaMiddleware.run(watcheroffice);
sagaMiddleware.run(watcherusers);
sagaMiddleware.run(watchercandidat);
sagaMiddleware.run(watchermediaobject);
sagaMiddleware.run(watcherapplicantUser);
sagaMiddleware.run(watcherfeedback);
sagaMiddleware.run(watcherreference);
sagaMiddleware.run(watcherremuneration);
sagaMiddleware.run(watcherhistory);
sagaMiddleware.run(watcherexpertise);
sagaMiddleware.run(watchernotification);
sagaMiddleware.run(watcherpowerbi);
sagaMiddleware.run(watcheroffer);
sagaMiddleware.run(watcherapplicantoffer);

export default store
