import { Link } from "react-router-dom";
import CreateDateRow from "./CreateDateRow";
import UpdatedAtRow from "./UpdatedAtRow";
import { FiEdit2, FiTrash } from "react-icons/fi";
import CommentRow from "./CommentRow";
import ApprovedRow from "./ApprovedRow";

function ReferenceRow({
  list,
  handleshowmodalEdit,
  deleteReference
}) {
  return (
    <>
    <tr key={`reference-${list.id}`} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '>
      <td className='px-6 py-4 font-medium truncate'>
        {list.contactName}
      </td>
      <td className='px-6 py-4 font-medium truncate'>
        {list.contactEmail}
      </td>
      <td className='px-6 py-4 font-medium truncate'>
        {list.contactCellphone}
      </td>
      <td className='px-6 py-4 font-medium truncate'>
        {list.contactCompany}
      </td>
      <CommentRow list={list} />
      <ApprovedRow list={list} />
      <CreateDateRow list={list.CreatedAt} />
      <UpdatedAtRow list={list.CreatedAt} />
      <td className='flex justify-center px-6 py-6 m-2 pl-2 gap-2'>
        <Link>
          <FiEdit2 onClick={() => handleshowmodalEdit(list) }  />
        </Link>
        <Link>
          <FiTrash onClick={(e) =>   deleteReference(e , list.id)}  />
        </Link>
      </td>
    </tr>
  </>
  )
}

export default ReferenceRow
