import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  editusersFailure,
  editusersSuccess,
  getActiveusersSuccess,
  getAllusersFailure,
  getAllusersSuccess,
  getactiveusersFailure
} from './UsersSlice';

const token = localStorage.getItem('token')

function* workergetallusers(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/users?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllusersSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllusersFailure(error.response.status))
  }  
}

function* workergetactiveusers(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/users?isActive=true&&pagination=false`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getActiveusersSuccess(res.data));
}
  catch(error)
  {
    yield put(getactiveusersFailure(error.response.status))
  }  
}

function* workereditusers(action)
{
  try
    {
      let data  = {
        id: action.payload.id,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        username: action.payload.username,
        roles: action.payload.roles,
        email: action.payload.email,
        address: action.payload.address,
        cellphone: action.payload.cellphone,
        isActive: action.payload.isActive,
        createdAt: action.payload.createdAt
      } 
      if (action.payload.password){
        data= {
          ...data,
          password: action.payload.password
        }
      }
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/users/${action.payload.id}`,
      method: 'PATCH',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/merge-patch+json'
      },
  }
))
  yield workergetallusers({payload : 1}); 
  yield put(editusersSuccess(data));
}
  catch(error)
  {
    yield put(editusersFailure(error.response.status))
  }
}

function* uploadPicture(file) {
  const formData = new FormData();
  formData.append('file', file);

  const response = yield call(() => api({
    url: `${process.env.REACT_APP_API_URL}/api/media_objects`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }));

  if (false === response.ok) {
    throw new Error('Failed to upload picture');
  }

  return response.data;
}

function* workeruploadimageuser(action)
{
  try {
    let mediaObject = '';
    const picture = yield call(uploadPicture, action.payload.mediaObject);
    mediaObject = `/api/media_objects/${picture.id}`;
    const response = yield call(() => api({
      url: `${process.env.REACT_APP_API_URL}/api/users/${action.payload.id}`,
      method: 'put',
      data: {
        mediaObject,
        createdAt: action.payload.createdAt
      },
      headers: {
        Authorization: `Bearer ${token}`
      },
    }));
    yield workergetallusers({payload : 1}); 
    yield put(editusersSuccess(response));
  }
  catch(error){
  }
}

function* workergetusersfilter(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/users?fullname=${action.payload.fullname}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllusersSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllusersFailure(error.response.status))
  }  
}


export function* watcherusers()
{
  yield takeEvery('Getallusers', workergetallusers)
  yield takeEvery('GetActiveusers', workergetactiveusers)
  yield takeEvery('Editusers', workereditusers) 
  yield takeEvery('UploadImageUser', workeruploadimageuser) 
  yield takeEvery('filtrerUser', workergetusersfilter) 
}