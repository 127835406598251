export const CandidatSagaActions = {
  getallcandidats,
  addcandidat,
  editcandidat,
  deletecandidat,
  filtercandidat,
  acceptrefusecandidat,
  getapplicantbyid,
  getapplicantswithoutpagination,
  applyapplicant,
  filterapplicantOffer
};

function getallcandidats(payload)
{
  return {
    type: 'Getallcandidat',
    payload
  }
}

function filtercandidat(payload)
{
  return {
    type: 'filtercandidat',
    payload
  }
}

function addcandidat(payload)
{
  return {
    type: 'Addcandidat',
    payload,
  }
}  

function editcandidat(payload)
{
  return {
    type: 'Editcandidat',
    payload,
  }
}  

function deletecandidat(payload)
{
  return {
    type: 'Deletecandidat',
    payload,
  }
}

function acceptrefusecandidat(payload)
{
  return {
    type: 'AcceptRefuseCandidat',
    payload,
  }
}

function getapplicantbyid(payload)
{
  return {
    type: 'getapplicantbyid',
    payload,
  }
}

function getapplicantswithoutpagination(payload)
{
  return {
    type: 'Getallcandidatwithoutpagination',
    payload,
  }
}

function applyapplicant(payload)
{
  return {
    type: 'Applyapplicant',
    payload,
  }
}

function filterapplicantOffer(payload)
{
  return {
    type: 'filterapplicantOffer',
    payload,
  }
}