import React, { useEffect, useState , useRef } from 'react';
import NavBar from '../components/template/NavBar';
import SideBar from '../components/template/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { statusSagaActions } from '../redux/status/statusSagaAction';
import AddButton from '../components/bouttons/AddButton';
import Modal from '../components/forms/Modal';
import Pagination from '../components/Pagination';
import Table from '../components/Table';
import Title from '../components/Title';
import EmptyTable from '../components/EmptyTable';
import useClickOutside from '../components/useClickOutside';

function Status() {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [showmodal , setshowmodal] = useState(false);
  const [modif , setModif] = useState(false);
  const [status] = useState('status');
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    totalItems,
    statusList
  } = useSelector(
      (state) => state.status
  );
  const headers = ['Titre' , 'Code Couleur', 'Activé', 'Actions']

  const handleshowmodal = () => {
    setModif(false);
    setSelectedItem(null)
    setshowmodal(!showmodal);
  }

  const handleshowmodalEdit = (item) => {
    setModif(true);
    setSelectedItem(item)
    setshowmodal(!showmodal);
  }

  function onCheck(val) {
    const state = val.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} cette status?`)) {
      const isActive = {...val, isActive : !val.isActive};
      dispatch(statusSagaActions.editstatus({
        id: isActive.id,
        status: isActive.status,
        isActive: isActive.isActive,
        createdAt: isActive.createdAt
      },
      isActive.codecouleur
        ));
    }
  }

  function onPageChange(currentPage) {
    dispatch(statusSagaActions.getallstatus(currentPage.selected + 1));
  }

  useEffect(() => {
    dispatch(statusSagaActions.getallstatus(1));
  }, [dispatch]);

  const handleClickOutside = (ref) => {
      setshowmodal(false);
  };

  useClickOutside({refs:[modalRef], onClickOutside:handleClickOutside});

  return (
    <>
      <NavBar />
      <SideBar />
      <div className='flex flex-col gap-12 p-4 lg:ml-64 mt-14'>
        <Title 
          title={'Liste des statuts'}
          className={'font-bold text-3xl'}
        />
        <AddButton 
          onClick={handleshowmodal}
          Name='Ajouter un statut'
        />
        <div className='min-h-fit'>
          {showmodal && <Modal refer={modalRef} config={status} modif={modif} selectedItem={selectedItem} showmodal={showmodal} setshowmodal={setshowmodal} />}   
          <div className='overflow-x-auto shadow-md sm:rounded-lg'>
            {statusList.length === 0 ?
            <EmptyTable />
            :
            <Table
             List={statusList}
             headers={headers}
             classNameTable={'w-full text-sm text-left text-gray-500 dark:text-gray-400'}
             classNamethead={'text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}
             classNameth={'px-6 py-3'}
             onCheck={onCheck}
             handleshowmodalEdit={handleshowmodalEdit}
             name={status}
            />
            }
          </div>
        </div> 
        <div className='flex justify-center md:justify-center'>
            <Pagination
              onPage={onPageChange}
              totalItems={totalItems}
              number={10}
            />
        </div>
      </div>
  </>
  )
}
export default Status