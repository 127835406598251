import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  success: false,
  error: null,
}

const powerbiSlice = createSlice ({
  name: 'powerbi',
  initialState,
  reducers: {
    getAllpowerbiSuccess(state, action)
    {
      state.success = true;
      state.error = false;
    },
    getAllpowerbiFailure(state, action)
    {
      state.error = true;
      state.success = false;
    },
  }
})

export const {
  getAllpowerbiSuccess,
  getAllpowerbiFailure,
  } = powerbiSlice.actions;

export default  powerbiSlice.reducer