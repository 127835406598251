import Alert from "../Alerts";
import Input from "../Input";
import GoBackButton from "../bouttons/GoBackButton";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function RemunerationForm({
  OnSubmit,
  DataRemuneration,
  handleclosemodal,
  onChange,
  modif,
  RemunerationActualFixError,
  setRemunerationActualFixError,
  CurrentVariableError,
  setCurrentVariableError,
  RemunerationDesiredFixError,
  setremunerationDesiredFixError,
  DesiredVariableError,
  setDesiredVariableError,
  CommentError,
  setCommentError,
}) {

  const handleChangeField = (e) => {
    const { name, value } = e.target;
    if(name === 'remunerationActualFix')
    {
      setRemunerationActualFixError('')
    }
    if(name === 'currentVariable')
    {
      setCurrentVariableError('')
    }
    if(name === 'remunerationDesiredFix')
    {
      setremunerationDesiredFixError('')
    }
    if(name === 'desiredVariable')
    {
      setDesiredVariableError('')
    }
    onChange(name, value)
  };

  const handleCkEditor = (value, name) => 
  {
    if(name === 'comment')
    {
      setCommentError('')
    }
    onChange(name, value)
  }


  return (
    <form onSubmit={OnSubmit}>
      { RemunerationActualFixError && <Alert Message={ RemunerationActualFixError } color={'red'} /> }
      { CurrentVariableError && <Alert Message={ CurrentVariableError } color={'red'} /> }
      { RemunerationDesiredFixError && <Alert Message={ RemunerationDesiredFixError } color={'red'} /> }
      { DesiredVariableError && <Alert Message={ DesiredVariableError } color={'red'} /> }
      { CommentError && <Alert Message={ CommentError } color={'red'} /> }
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-20">
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'remunerationActualFix'}
          classNameLabel={'peer-focus:font-sm absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 truncate'}
          placeholder={'Rémunération actuelle'}
          onChange={handleChangeField}
          value={DataRemuneration.remunerationActualFix}
        />
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'currentVariable'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Variable actuelle'}
          onChange={handleChangeField}
          value={DataRemuneration.currentVariable}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-20">
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'remunerationDesiredFix'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 truncate'}
          placeholder={'Rémunération actuelle desirée'}
          onChange={handleChangeField}
          value={DataRemuneration.remunerationDesiredFix}
        />
        <Input
          type={'text'}
          className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
          name = {'desiredVariable'}
          classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
          placeholder={'Variable désirée'}
          onChange={handleChangeField}
          value={DataRemuneration.desiredVariable}
        />
      </div>
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Commentaire</label>
        <CKEditor
          editor={ ClassicEditor }
          data={DataRemuneration.comment}
          onChange={(event, editor) => handleCkEditor(editor.getData(), 'comment')}
        />    
      </div>
      <div className='flex items-center justify-between text-primary gap-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div>   
      </div> 
  </form>
  )
}

export default RemunerationForm