export default function ApprovedRow({list}) {
  return (
    <td className='px-6 py-4 font-medium'>
      {list.isApproved ? (
        <span className={`bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 whitespace-nowrap`}>
          approuvé
        </span>
      ) : (
        <span className={`bg-red-100 textred-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300 whitespace-nowrap`}>
          non approuvé
        </span>
      )}
    </td>
  )
}
