import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  usersList: [],
  activeusersList: [],
  success: false,
  error: null,
}

const usersSlice = createSlice ({
  name: 'users',
  initialState,
  reducers: {
    getAllusersSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.usersList = action.payload['hydra:member'];  
      state.success = true;
    },
    getActiveusersSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.activeusersList = action.payload['hydra:member'];
    },
    getactiveusersFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    getAllusersFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
    editusersSuccess(state, action)
    {
      state.success = true;
    },
    editusersFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    }
  }
})

export const {
  getAllusersSuccess,
  getAllusersFailure,
  editusersSuccess,
  editusersFailure,
  getActiveusersSuccess,
  getactiveusersFailure
  } = usersSlice.actions;

export default  usersSlice.reducer