import { useState } from "react";

const OfferHook = ({selectedItem}) => {
  const setDefaultValue = (value, defaultValue = '') => value || defaultValue;
  const setDefaultValueDate = (value, defaultValue = null) => value || defaultValue;

  const [Data, setData] = useState({
    id:  selectedItem ? selectedItem.id : null,
    titleOffer: setDefaultValue(selectedItem?.titleOffer),
    CompanyDescription: setDefaultValue(selectedItem?.CompanyDescription),
    offerDescription: setDefaultValue(selectedItem?.offerDescription),
    experience: setDefaultValue(selectedItem?.experience),
    languages: setDefaultValue(selectedItem?.languages, []), 
    levelStudy: setDefaultValue(selectedItem?.levelStudy),
    skillsRequired: setDefaultValue(selectedItem?.skillsRequired, []),
    remuneration: setDefaultValue(selectedItem?.remuneration),
    offerDeadline: setDefaultValueDate(selectedItem?.offerDeadline, null),
    desiredStartDate: setDefaultValueDate(selectedItem?.desiredStartDate, null),
    mission: setDefaultValue(selectedItem?.mission),
    schedule: setDefaultValue(selectedItem?.schedule),
    location: setDefaultValue(selectedItem?.location),
    typeContract: setDefaultValue(selectedItem?.typeContract),
    createdAt: setDefaultValue(selectedItem?.createdAt),
    updatedAt: setDefaultValue(selectedItem?.updatedAt),
  });

  return [
    Data,
    setData,
  ];
}

export default OfferHook