import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  totalItems: 0,
  offerList: [],
  success: false,
  error: null,
  offer: null
}

const offerSlice = createSlice ({
  name: 'offer',
  initialState,
  reducers: {
    getAllofferSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.offerList = action.payload['hydra:member'];  
      state.success = true;
    },
    getAllofferFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        state.error = 'action unauthorized ! ';
      }
      else
      {
        state.error = action.payload;
      }
    },
    addofferSuccess(state)
    {
      state.success = true;
      state.error = false;
    },
    addofferFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        state.error = 'Action non autorisés !';
      }
      else if(action.payload.response.status === 500)
      {
        state.error = 'Cet offre existe déja !';
      }
      else
      {
        state.error = 'Something else happened !';
      }
    },
    editofferSuccess(state)
    {
      state.success = true;
      state.error = false;
    },
    editofferFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        state.error = 'Action non autorisés !';
      }
      else
      {
        state.error = 'Something else happened !';
      }
    },
    deleteofferSuccess(state, action)
    {
      state.success = true;
      state.offerList = state.offerList.filter((offer) => offer.id !== action.payload);
    },
    deleteofferFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        state.error = 'Action non autorisés !';
      }
      else
      {
        state.error = 'Something else happened !';
      }
    },
    getofferbyidSuccess(state, action)
    {
      state.offer = action.payload
      state.success = true;
      state.error = false;
    },
    getofferbyidFailure(state, action)
    {
      state.success = false;
      if(action.payload === 404)
      {
        state.error = 'applicantion not found';
      }
      else if (action.payload === 401)
      {
        state.error = 'unauthorized !';
      }
      else
      {
        state.error = 'something else happened !';
      }
    }
  }
})

export const {
  getAllofferSuccess,
  getAllofferFailure,
  addofferSuccess,
  addofferFailure,
  editofferSuccess,
  editofferFailure,
  deleteofferSuccess,
  deleteofferFailure,
  getofferbyidSuccess,
  getofferbyidFailure
} = offerSlice.actions;

export default  offerSlice.reducer