import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  getAllhistoryFailure,
  getAllhistorySuccess,
} from './HistorySlice';

const token = localStorage.getItem('token')

function* workergetallhistory(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/histories?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
  }
))
  yield put(getAllhistorySuccess(res.data));
}
  catch(error)
  {
    yield put(getAllhistoryFailure(error.response.status))
  }  
}

export function* watcherhistory()
{
  yield takeEvery('Getallhistory', workergetallhistory)
}