import { useDispatch, useSelector } from "react-redux";
import Input from "../Input";
import GoBackButton from "../bouttons/GoBackButton";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { useEffect } from "react";
import { statusSagaActions } from "../../redux/status/statusSagaAction";
import { profilSagaActions } from "../../redux/profil/ProfilSagaActions";
import { officeSagaActions } from "../../redux/office/OfficeSagaActions";
import { typecontractSagaActions } from "../../redux/typecontract/TypecontractSagaAction";
import { sourceSagaActions } from "../../redux/source/SourceSagaActions";
import { mediaobjectSagaActions } from "../../redux/mediaobject/mediaobjectsagaActions";
import { usersSagaActions } from "../../redux/users/UsersSagaActions";
import AlertApplicant from "../AlertApplicant";

export default function CandidatForm({
  onSubmit,
  handleclosemodal,
  modif,
  credentials,
  onChange,
  FirstNameError,
  setFirstnameApplicantError,
  LastNameError,
  setLastnameApplicantError,
  EmailApplicantError,
  setEmailApplicantError,
  CellphoneApplicantError,
  setCellphoneApplicantError,
  PosteApplicantError,
  setPosteApplicantError,
  StatusApplicantError,
  setStatusApplicantError,
  OfficeApplicantError,
  setOfficeApplicantError,
  ProfilApplicantError,
  setProfilApplicantError,
  TypeContractApplicantError,
  setTypeContractApplicantError,
  SourceApplicantError,
  setSourceApplicantError,
  MediaObjectApplicantError,
  setMediaObjectApplicantError,
}) {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();

  const {
    activeStatusList
  } = useSelector(
      (state) => state.status
  );

  const {
    activeprofilList
  } = useSelector(
      (state) => state.profil
  );

  const {
    activeofficeList
  } = useSelector(
      (state) => state.office
  );

  const {
    activeTypeContractList
  } = useSelector(
      (state) => state.typecontract
  );

  const {
    activesourceList
  } = useSelector(
      (state) => state.source
  );

  const {
    activeusersList
  } = useSelector(
      (state) => state.users
  )
  
  useEffect(() => {
    dispatch(statusSagaActions.getactivestatus());
    dispatch(profilSagaActions.getactiveprofil());
    dispatch(officeSagaActions.getactiveoffice());
    dispatch(typecontractSagaActions.getactivetypecontract());
    dispatch(mediaobjectSagaActions.getallmediaobject());
    dispatch(sourceSagaActions.getactivesource());
    dispatch(usersSagaActions.getactiveusers());
  }, [dispatch]);

  const optionsStatus = activeStatusList.map(status => ({
    value: status['@id'],
    label: status.status
  }));

  const optionsProfil = activeprofilList.map(profil => ({
    value: profil['@id'],
    label: profil.profil
  }));

  const optionsOffice = activeofficeList.map(office => ({
    value: office['@id'],
    label: office.office
  }));

  const optionsTypeContract = activeTypeContractList.map(typecontract => ({
    value: typecontract['@id'],
    label: typecontract.typeContract
  }));

  const optionsSource = activesourceList.map(source => ({
    value: source['@id'],
    label: source.source
  }));

  const optionsusersHr = activeusersList
  .filter(user => user.roles.includes('ROLE_HR'))
  .map(user =>  ({
    value: JSON.stringify({
      user: user['@id'],
      source: 'Add_applicant'
    }),
    label: user.firstname + ' ' + user.lastname,
  }))

  const optionsusersManager = activeusersList
  .filter(user => user.roles.includes('ROLE_MANAGER'))
  .map(user =>  ({
    value: JSON.stringify({
      user: user['@id'],
      source: 'Add_applicant'
    }),
    label: user.firstname + ' ' + user.lastname,
  }))

  const optionsusersRefferent = activeusersList
  .filter(user => user.roles.includes('ROLE_REFFERANT'))
  .map(user =>  ({
    value: JSON.stringify({
      user: user['@id'],
      source: 'Add_applicant'
    }),
    label: user.firstname + ' ' + user.lastname,
  }))

  const handleSelect = (name, select) => {
    if(name === 'status')
    {
      setStatusApplicantError('')
    }
    if(name === 'profil')
    {
      setProfilApplicantError('')
    }
    if(name === 'office')
    {
      setOfficeApplicantError('')
    }
    if(name === 'typecontract')
    {
      setTypeContractApplicantError('')
    }
    if(name === 'source')
    {
      setSourceApplicantError('')
    }
    onChange(name, select.value)
  };

  const handleSelectMulti = (name, select) => {
    onChange(name, select.map((e) => e.value))
  };

  const handleChangeField = (e) => {
    const { name, value, files } = e.target;
    if (name === "mediaObject") {
      setMediaObjectApplicantError('')
      onChange(name, files[0])
    }
    else
    {
      if(name==='firstname')
      {
       setFirstnameApplicantError('')
      }
      if(name==='lastname')
      {
       setLastnameApplicantError('')
      }
      if(name==='email')
      {
       setEmailApplicantError('')
      }
      if(name==='cellphone')
      {
       setCellphoneApplicantError('')
      }
      if(name==='poste')
      {
       setPosteApplicantError('')
      }
      onChange(name, value)
    }
  };

  let hr =  credentials.applicantUsers
  .filter(applicantUsers => applicantUsers && applicantUsers.source && applicantUsers.source.includes('Add_applicant'))
  .map(applicantuser => applicantuser.user)
  .filter(user => user && user.roles && user.roles.includes('ROLE_HR'));

  let manager = credentials.applicantUsers
  .filter(applicantUsers => applicantUsers && applicantUsers.source && applicantUsers.source.includes('Add_applicant'))
  .map(applicantuser => applicantuser.user)
  .filter(user => user && user.roles && user.roles.includes('ROLE_MANAGER'));

  let refferent = credentials.applicantUsers
  .filter(applicantUsers => applicantUsers && applicantUsers.source && applicantUsers.source.includes('Add_applicant'))
  .map(applicantuser => applicantuser.user)
  .filter(user => user && user.roles && user.roles.includes('ROLE_REFFERANT'));

  return (
    <>
    <form encType="multipart/form-data" onSubmit={onSubmit} className='h-[90%]'> 
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <Input
            type={'text'}
            className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
            name = {'firstname'}
            classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
            placeholder={'Nom'}
            onChange={handleChangeField}
            value={credentials.firstname}
          />
          { FirstNameError && <AlertApplicant Message={ FirstNameError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>
        <div className="flex flex-col">
          <Input
            type={'text'}
            className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
            name = {'lastname'}
            classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
            placeholder={'Prénom'}
            onChange={handleChangeField}
            value={credentials.lastname}
          />
          { LastNameError && <AlertApplicant Message={ LastNameError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <Input
            type={'text'}
            className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
            name = {'cellphone'}
            classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
            placeholder={'Téléphone'}
            onChange={handleChangeField}
            value={credentials.cellphone}
          />
          { CellphoneApplicantError && <AlertApplicant Message={ CellphoneApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>
        <div className="flex flex-col">
          <Input
            type={'text'}
            className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
            name = {'poste'}
            classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
            placeholder={'Poste'}
            onChange={handleChangeField}
            value={credentials.poste}
          />
          { PosteApplicantError && <AlertApplicant Message={ PosteApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>
      </div>  
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <Input
              type={'email'}
              className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
              name = {'email'}
              classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
              placeholder={'Email'}
              onChange={handleChangeField}
              value={credentials.email}
            />
          { EmailApplicantError && <AlertApplicant Message={ EmailApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>
          <Input
            type={'text'}
            className={'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'}
            name = {'linkedin'}
            classNameLabel={'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'}
            placeholder={'LinkedIn'}
            onChange={handleChangeField}
            value={credentials.linkedin}
          />
      </div>  
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Statut</label>
          <Select
            options={optionsStatus}
            components={animatedComponents}
            className="basic-multi-select" 
            onChange={(e) => handleSelect('status', e)}
            defaultValue={modif ? 
              { label: credentials.status.status,
                value: credentials.status['@id'] }
              : 
              { label: activeStatusList.filter((status) => status.status === 'Présélection').map((status) => status.status),
                value : activeStatusList.filter(status => status.status === 'Présélection').map(status => status['@id'])[0]
              } 
            }
          />
          { StatusApplicantError && <AlertApplicant Message={ StatusApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>
        <div className="flex flex-col">
          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Profil</label>
          <Select
            options={optionsProfil}
            components={animatedComponents}
            className="basic-multi-select" 
            onChange={(e) => handleSelect('profil', e)}
            defaultValue={{ label: credentials.profil.profil, value: credentials.profil['@id'] }}
          />  
          { ProfilApplicantError && <AlertApplicant Message={ ProfilApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>
      </div>
      <br />
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Office</label>
          <Select
            options={optionsOffice}
            components={animatedComponents}
            className="basic-multi-select"
            onChange={(e) => handleSelect('office', e)}
            defaultValue={{ label: credentials.office.office, value: credentials.office['@id'] }}
          />
          { OfficeApplicantError && <AlertApplicant Message={ OfficeApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div> 
        <div className="flex flex-col">
          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Type de contrat</label> 
          <Select
            options={optionsTypeContract}
            components={animatedComponents}
            className="basic-multi-select" 
            onChange={(e) => handleSelect('typecontract', e)} 
            defaultValue={{ label: credentials.typecontract.typeContract, value: credentials.typecontract['@id'] }}
          /> 
          { TypeContractApplicantError && <AlertApplicant Message={ TypeContractApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>  
      </div>
      <br />
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Source</label>
          <Select
            options={optionsSource}
            components={animatedComponents}
            className="basic-multi-select" 
            onChange={(e) => handleSelect('source', e)} 
            defaultValue={{ label: credentials.source.source, value: credentials.source['@id'] }}
          />
          { SourceApplicantError && <AlertApplicant Message={ SourceApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div> 
        <div className="flex flex-col">
          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Curriculum vitae</label> 
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            type='file' 
            name={'mediaObject'}
            accept='pdf/*'
            onChange={handleChangeField}
          />
          { MediaObjectApplicantError && <AlertApplicant Message={ MediaObjectApplicantError } color={'red'} className={'flex items-center text-xs text-red-800'} /> }
        </div>  
      </div>
      <br />
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Chargée de recrutement</label>
          <Select
            options={optionsusersHr}
            components={animatedComponents}
            className="basic-multi-select"
            isMulti
            onChange={(e) => handleSelectMulti('Rh', e)} 
            defaultValue={hr.map(hr => ({
              value: JSON.stringify({
                user: hr['@id'],
                source: 'Add_applicant'
              }),
              label: hr.fullName
            }))}
          />
        </div> 
        <div>
          <label className="block  text-sm font-medium text-gray-900 dark:text-white">Responsable Hiéarchique</label> 
          <Select
            options={optionsusersManager}
            components={animatedComponents}
            className="basic-multi-select" 
            isMulti
            onChange={(e) => handleSelectMulti('Directeur', e)}
            defaultValue={manager.map(manager => ({
              value: JSON.stringify({
                user: manager['@id'],
                source: 'Add_applicant'
              }),
              label: manager.fullName
            }))}
          />    
        </div>  
      </div>
      { modif && 
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block  text-sm font-medium text-gray-900 dark:text-white">Référent</label> 
            <Select
              options={optionsusersRefferent}
              components={animatedComponents}
              className="basic-multi-select" 
              isMulti
              onChange={(e) => handleSelectMulti('Refferant', e)}
              defaultValue={refferent.map(refferent => ({
                value: JSON.stringify({
                  user: refferent['@id'],
                  source: 'Add_applicant'
                }),
                label: refferent.fullName
              }))}
            />    
          </div>  
        </div>
      }
      <div className='flex items-center justify-between text-primary gap-2'> 
        <div>
            <button className='relative w-40 my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>{modif ? 'Modifier' : 'Ajouter'}</button>
        </div>
        <div> 
          <GoBackButton
            className={'relative w-40 py-2 bg-gray-500 shadow-lg shadow-gray-500/50 hover:shadow-gray-500/40 text-white font-semibold rounded'}
            handleclosemodal={handleclosemodal}
            title={'Annuler'}
          />
        </div>   
      </div>
    </form>
    </>
  )
}
