import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../hooks/Api";
import { 
  getAllapplicantofferFailure,
  getAllapplicantofferSuccess
} from "./ApplicantOfferSlice";

const token = localStorage.getItem('token')

function* workergetapplicantofferfilter(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/applicant_offers?poste=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllapplicantofferSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllapplicantofferFailure(error.response.status))
  }  
}

export function* watcherapplicantoffer()
{
  yield takeEvery('getapplicantofferfilter', workergetapplicantofferfilter)
}