import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  deletenotificationFailure,
  deletenotificationSuccess,
  editnotificationFailure,
  editnotificationSuccess,
  getAllnotificationFailure,
  getAllnotificationSuccess,
} from './NotificationSlice';

const token =  localStorage.getItem('token');

function* workergetallnotification(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/notifications?_page=${action.payload}`,
      method: 'GET',
      data: action.payload,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
  }
))
  yield put(getAllnotificationSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllnotificationFailure(error.response.status))
  }  
}

function* workergetallnotificationswithoutpagination(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/applicants`,
      method: 'GET',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
  }
))
  yield put(getAllnotificationSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllnotificationFailure(error.response.status))
  }  
}

function* workerdeletenotification(action)
{ try
    {
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/notifications/${action.payload}`,
      method: 'DELETE',
      data: action.payload,
      headers: {
        Authorization: `Bearer ${token}`
      }
  }
))

yield workergetallnotification({payload : 1});
yield put(deletenotificationSuccess());

}
  catch(error)
  {
    yield put(deletenotificationFailure(error))
  }  
}

function* workereditnotification(action)
{
  try
    {
      const data  = {
        id: action.payload.id,
        isViewed: action.payload.isViewed
      } 
      yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/notifications/${action.payload.id}`,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      },
  }
))
  yield workergetallnotification({payload : 1});  
  yield put(editnotificationSuccess(data));
}
  catch(error)
  {
    yield put(editnotificationFailure(error.response.status))
  }
}

export function* watchernotification()
{
  yield takeEvery('Getallnotification', workergetallnotification)
  yield takeEvery('Deletenotification', workerdeletenotification)
  yield takeEvery('Editnotification', workereditnotification)
  yield takeEvery('getallnotificationswithoutpagination', workergetallnotificationswithoutpagination)
}