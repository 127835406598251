export const ApplicantUserSagaActions = {
  addApplicantUser,
  sendemailApplicantUser
};

function addApplicantUser(payload)
{
  return {
    type: 'addApplicantUsers',
    payload,
  }
}

function sendemailApplicantUser(payload)
{
  return {
    type: 'sendemailApplicantUser',
    payload,
  }
}  