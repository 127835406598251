import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const initialState = {
  totalItems: 0,
  applicantofferList: [],
  success: false,
  error: null,
  applicantoffer: null
}

const applicantofferSlice = createSlice ({
  name: 'applicantoffer',
  initialState,
  reducers: {
    getAllapplicantofferSuccess(state, action)
    {
      state.totalItems = action.payload['hydra:totalItems'];
      state.applicantofferList = action.payload['hydra:member'];  
      state.success = true;
    },
    getAllapplicantofferFailure(state, action)
    {
      state.success = false;
      if(action.payload===401)
      {
        toast.error('action unauthorized', {theme: 'colored'});
      }
      else
      {
        toast.error('something else happened !',{theme: 'colored'})  
      }
    },
  }
})

export const {
  getAllapplicantofferSuccess,
  getAllapplicantofferFailure,
  } = applicantofferSlice.actions;

export default  applicantofferSlice.reducer