import { BiInfoCircle } from 'react-icons/bi';

export default function Alert({ Message , color }) {
  return (
    <div className={`flex items-center p-4 mb-4 text-sm text-${color}-800 border border-${color}-300 rounded-lg bg-${color}-50 dark:bg-gray-800 dark:text-${color}-400 dark:border-${color}-800`} role='alert'>
      <BiInfoCircle className='mr-2' />
        <div>
          <span className='font-medium'>{ Message }</span>
        </div>
    </div>
  )
}
