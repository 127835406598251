import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../hooks/Api';
import { 
  getAllexpertiseFailure,
  getAllexpertiseSuccess,
} from './ExpertiseSlice';

const token =  localStorage.getItem('token');

function* workergetallexpertise(action)
{
  try
    {
      const res = yield call(()=> api({
      url: `${process.env.REACT_APP_API_URL}/api/expertises`,
      method: 'GET',
      data: action.payload,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
  }
))
  yield put(getAllexpertiseSuccess(res.data));
}
  catch(error)
  {
    yield put(getAllexpertiseFailure(error.response.status))
  }  
}

export function* watcherexpertise()
{
  yield takeEvery('Getallexpertise', workergetallexpertise)
}