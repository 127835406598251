import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SignInLeftSection from '../components/sign-in/SignInLeftSection';
import { ChangePasswordSagaActions } from '../redux/auth/authSagaActions';
import { useNavigate, useParams } from 'react-router';
import Password from '../components/sign-in/Password';
import Alert from '../components/Alerts';

export default  function ChangePassword() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const {token} = useParams();

  function handleTogglePassword(e) {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  function getValue(e)
  {
    setPassword(e.currentTarget.value);
    setPasswordError('')
  };

  function ChangePassword(e) {
    e.preventDefault();
    if ((password.length === 0)) {
      setPasswordError('Champ password est obligatoire!')
    }    
    else
    {
      dispatch(ChangePasswordSagaActions.ChangePassword({token, password}))
      setSuccessMessage('Mot de Passe Modifié avec success')
      setTimeout(() => {
        navigate('/');
      }, 1000);
    }
  }

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 h-screen w-full'>
    <SignInLeftSection className='bg-teal-500 flex justify-center items-center' />
      <div className='bg-white flex flex-col justify-center'>
        <h2 className='text-4xl text-dark font-medium text-center'>Changer Mot de Passe</h2>
        <br></br>    
        <form className='max-w-[400px] w-full mx-auto p-8 px-8 rounded-lg' onSubmit={ChangePassword}>
          { SuccessMessage ?
            <Alert Message={SuccessMessage} color={'green'} />
            :null
          }
          { passwordError ?
            <Alert Message={passwordError} color={'red'} />
            :null
          }
          <Password
             onClick={handleTogglePassword}
             onChange={getValue}
             showPassword={showPassword}
             passwordError={passwordError}
          />
          <button className='w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded'>Changer Mot De Passe</button>
      </form>
    </div>
  </div>   
        
  )
}


