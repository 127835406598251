function CommentRow({list}) {

  const createMarkup = (data) => {
    return { __html: data };
  }

  return (
    <td 
      className='px-6 py-4 font-medium'
      dangerouslySetInnerHTML={createMarkup(list.comment)}
    >
    </td>
  )
}

export default CommentRow